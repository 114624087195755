import React from "react";
import axios from "axios";
import UserService from "../../../services/UserService";
import { useState, useEffect, useContext, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../context/createcontext";
import Header from './../Header';
import Sidebar from './../Sidebar';
import Spinner from 'react-bootstrap/Spinner';
import { formatThousands } from "../utils/Utils";
import FilterButton from "../utils/DropdownFilter";
import moment from "moment";
import ForestActionsModal from "./ForestActionsModal";
import ForestTable from "./ForestTable";

export default function AllForest () {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");
    const [bank, setBank] = useState([]);
    const [bankFilter, setBankFilter] = useState([]);
    const [usuariosForestales, setUsuariosForestales] = useState();
    const [updateForest, setUpdateForest] = useState({
      user: null,
      monte: null,
      action: null
    });

    const action = (props) => {
      setUpdateForest(props);
    }

    //Traducciones para los estados de los montes
    const statusName = (props) => {
      switch (props) {
        case 'CREATED':
          return t("forestList.sCreated");
        case 'VALIDATED':
          return t("forestList.sValidated");
        case 'VALIDATEDANDCERTIFICATED':
          return t("forestList.sValidatedAndCertificated");
        case 'ACTIVE':
          return t("forestList.sActive");
        case 'DISABLED':
          return t("forestList.sDisabled");
      }
    };


  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);
    const sortedItems = useMemo(() => {
      let sortableItems = [...items];

      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          let valueA = a[sortConfig.key];
          let valueB = b[sortConfig.key];

          if (sortConfig.key.includes(".")) {
            let params = sortConfig.key.split(".");
            valueA = a[params[0]]?.[params[1]];
            valueB = b[params[0]]?.[params[1]];
          }

          if (valueA === null || valueA === undefined || valueA === "") {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }

          if (valueB === null || valueB === undefined || valueB === "") {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }

          if (!isNaN(valueA) || moment(valueA).isValid()) {
            if (valueA < valueB) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (valueA > valueB) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          } else {
            if (
              valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (
              valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }

          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(bankFilter);

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };



  const stateUniq = [];
  bank.map((v) => {
    if (stateUniq.indexOf(v.state) === -1) {
      stateUniq.push(v.state)
    }
  });

  const stateList = [];
  stateUniq.map((v) => {
    stateList.push({
      category: v,
      check: false,
      status: statusName(v)
    });
  })


  const applyCategoryFilter = (data) => {
    setFiltros((prevFiltros) => ({
      ...prevFiltros,
      status: data,
    }));
  };



    useEffect(() => {
    const fetchBankData = async () => {
        try {
        setBank(bank);
        const response = await axios.get(`${process.env.REACT_APP_ADMIN_FOREST}`, {
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${UserService.getToken()}`
            }
        });
        setBank(response.data.sort((x, y) => y.creationDate.localeCompare(x.creationDate)));
        setBankFilter(response.data.sort((x, y) => y.creationDate.localeCompare(x.creationDate)));
        } catch (exception) {
        console.error(exception);
        }
    };
    fetchBankData();
    }, []);

    const [filtros, setFiltros] = useState({
      status: [],
      province: "",
      name: "",
      owner: ""
    });

    useEffect(()=>{
      const handleInputChange = () => {
      let filteredData = bank;

      if(filtros.status){
        filteredData = OnFilterStatus(filtros.status, filteredData);
      }

      if (filtros.province) {
        filteredData = OnFilterProvincia(filtros.province, filteredData);
      }

      if (filtros.name) {
        filteredData = OnFilterNombreMonte(filtros.name, filteredData);
      }

      if (filtros.owner) {
        filteredData = OnFilterOwner(filtros.owner, filteredData);
      }

      setBankFilter(filteredData)
    }
    handleInputChange();

    },[filtros]);

    const OnFilterProvincia = (filter, filteredData) => {
      return filteredData.filter((v) =>
        v.province!=null &&
        v.province.toLowerCase().includes(filter.toLowerCase())
      );
    };

    const OnFilterNombreMonte = (filter, filteredData) => {
      return filteredData.filter((v) =>
        v.name.toLowerCase().includes(filter.toLowerCase())
      );
    };

    const OnFilterOwner = (filter, filteredData) => {
      return filteredData.filter((v) =>
        v.owner!=null &&
        v.owner.toLowerCase().includes(filter.toLowerCase())
      );
    };

    const OnFilterStatus = (data, filteredData) => {
      let states = data.filter((c) => c.check).map((c) => c.category);

      if (states.length > 0) {
        return filteredData.filter((c) => states.includes(c.state));
      }

      return filteredData;
    };

    const [totalHectareas, setTotalHectareas] = useState({validadas: 0, activas: 0})
    useEffect(() => {
      if (bank.length > 0) {
        let totalHaValidated = formatThousands(bank.filter(x => x.state === "VALIDATED" || x.state === "VALIDATEDANDCERTIFICATED").reduce((sum, li) => sum + li.size, 0));
        let totalHaActive = formatThousands(bank.filter(x => x.state === "ACTIVE").reduce((sum, li) => sum + li.size, 0));
        setTotalHectareas({ validadas: totalHaValidated, activas: totalHaActive});
      }
    }, [bank])


    useEffect(() => {
      const fetchAllUserData = async () => {
        try {
          await axios.get(`${process.env.REACT_APP_ADMIN_USERS}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*',
              'Authorization': `Bearer ${UserService.getToken()}`
            }
          }).then(response => {
            let data = response.data.filter(x => x.userType != undefined);
            setUsuariosForestales(data.filter(v => v.userType == "Propietario Forestal" ))
          });
        } catch (exception) {
          console.error(exception)
        }
      };
      fetchAllUserData();
    }, []);


    const updateTable = (props) => {
      switch (updateForest.action) {

        case "edit":
          const updatedForestArray = bank.map((item) => {
            if (item.id === props.id) {
              return props;
            }
            return item;
          });
          setBank(updatedForestArray);

          const updatedItems = items.map((item) => {
            if (item.id === props.id) {
              return props;
            }
            return item;
          });
          setBankFilter(updatedItems);

        break;

        case "delete":
          let montesNew = bank.filter((x) => x.id !== props);
          let itemsNew = items.filter((x) => x.id !== props);
          setBank(montesNew);
          setBankFilter(itemsNew);

      }
    }





    return(
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>

          <ForestActionsModal props={updateForest} updateTable={updateTable}/>

          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold mb-2">Montes </h1>

            <div className="sm:flex sm:justify-between sm:items-center bg-white mb-4">

              {/* Left: Title */}

              <div>
                {/* Filtros */}
                <div className="p-3">
                  <div className="mb-2 font-bold">
                    <h2 className="font-bold">Filtros</h2>
                  </div>
                  <div className="grid gap-4 md:grid-cols-3">
                    <div className="">
                      <label className="block text-xs font-semibold mb-1 text-airco2-general" htmlFor="default">Nombre provincia</label>
                      <input value={filtros.province}
                        className="w-full text-xs border-gray-200 hover:border-gray-300"
                        onChange={(e) =>
                          setFiltros((prevFiltros) => ({
                            ...prevFiltros,
                            province: e.target.value,
                          }))
                        }
                        placeholder="Busca por provincia"
                        type="text"
                      />
                    </div>
                    <div>
                      <label className="block text-xs font-semibold mb-1 text-airco2-general" htmlFor="default">Nombre del monte</label>
                      <input value={filtros.name}
                        className="w-full text-xs border-gray-200 hover:border-gray-300"
                        onChange={(e) =>
                          setFiltros((prevFiltros) => ({
                            ...prevFiltros,
                            name: e.target.value,
                          }))
                        }
                        placeholder="Busca por nombre del monte"
                        type="text"
                      />
                    </div>
                    <div>
                      <label className="block text-xs font-semibold mb-1 text-airco2-general" htmlFor="default">Propietario</label>
                      <input value={filtros.owner}
                        className="w-full text-xs border-gray-200 hover:border-gray-300"
                        onChange={(e) =>
                          setFiltros((prevFiltros) => ({
                            ...prevFiltros,
                            owner: e.target.value,
                          }))
                        }
                        placeholder="Busca por propietario"
                        type="text"
                      />
                    </div>
                    <div>
                      <FilterButton align="right"
                        categoriesUniq={stateUniq}
                        stateCheck={stateList}
                        applyCategoryFilter={applyCategoryFilter}
                        nombreFiltro="Estado"
                        className="text-xs"
                      />
                    </div>
                  </div>
                </div>
              </div>


              {/* Right: TOTAL HECTAREAS */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 pr-4">
                <div className="mb-4 flex justify-end">
                  <div className="text-center text-sm">
                    <span>TOTAL HECTÁREAS:</span><br />
                    <span>
                      Validadas <b>{totalHectareas.validadas != 0 ? totalHectareas.validadas : <Spinner animation="border" variant="dark" size="sm" />}</b> |
                      Activas <b>{totalHectareas.activas != 0 ? totalHectareas.activas : <Spinner animation="border" variant="dark" size="sm" /> }</b>
                    </span>
                  </div>
                </div>
              </div>

            </div>
            <div className="mt">
              <div className="panel panel-default">
              {bankFilter.length == 0 ? <span className="flex items-cemter justify-center">Cargando montes&nbsp;<Spinner animation="border" variant="dark" /></span>:
                <div className="overflow-x-auto" style={{ height: '800px', overflowY: 'scroll' }}>
                  <table className="simple-table-sm">
                      <thead className="text-center text-white sec-green-background sticky top-0 z-20">
                          <tr>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                              <button
                                type="button"
                                onClick={() => requestSort("name")}
                                className={`${getClassNamesFor("name")} text-underline`}
                              >Nombre</button>
                            </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                              <button
                                type="button"
                                onClick={() => requestSort("owner")}
                                className={`${getClassNamesFor("owner")} text-underline`}
                              >Propietario</button>
                            </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                              <button
                                type="button"
                                onClick={() => requestSort("state")}
                                className={`${getClassNamesFor("state")} text-underline`}
                              >Estado</button>
                            </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                              <button
                                type="button"
                                onClick={() => requestSort("activationDate")}
                                className={`${getClassNamesFor("activationDate")} text-underline`}
                              >Fecha Activación</button>
                            </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                              <button
                                type="button"
                                onClick={() => requestSort("province")}
                                className={`${getClassNamesFor("province")} text-underline`}
                              >Localización</button>
                            </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                              <button
                                type="button"
                                onClick={() => requestSort("size")}
                                className={`${getClassNamesFor("size")} text-underline`}
                              >Tamaño (ha)</button>
                            </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                            <button
                                type="button"
                                onClick={() => requestSort("cadastre")}
                                className={`${getClassNamesFor("cadastre")} text-underline`}
                              >Ref. Catastral</button>

                            </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                            <button
                                type="button"
                                onClick={() => requestSort("forestType")}
                                className={`${getClassNamesFor("forestType")} text-underline`}
                              >Especie pred.</button>
                            </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >
                            <button
                                type="button"
                                onClick={() => requestSort("anualCO2")}
                                className={`${getClassNamesFor("anualCO2")} text-underline`}
                              >CO₂ (t)</button>
                            </th>
                            <th className="pl-2  first:pl-5 last:pr-5 pl-3 py-3 text-center" >Acciones

                            </th>
                          </tr>
                        </thead>
                        <ForestTable items={items} forestUsers={usuariosForestales} action={action}/>
                    </table>
                </div>}
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
    )
}