import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import UserService from '../../../services/UserService';
import { formatThousands } from '../utils/Utils';

export default function CalculationsList({ userId, handleSetSelectedCalculation, nextStep, t, calculationsCache, selectedCalculation, certificationType }) {

    const [calculosUsuario, setCalculosUsuario] = useState({
        data: calculationsCache.current || [],
        isFetching: calculationsCache.current ? false : true,
    });

    const [spinner, setSpinner] = useState(false);
    useEffect(() => {
        if (calculationsCache.current) return;

        const fetchCalculoUsuario = async () => {
            setCalculosUsuario((prev) => ({ ...prev, isFetching: true }));
            try {
                const response = await axios.get(`${process.env.REACT_APP_CH_CALCULO}?userId=${userId}&calculationStatus=FINALIZED`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                calculationsCache.current = response.data;
                setCalculosUsuario({ data: response.data, isFetching: false });

            } catch (exception) {
                console.error(exception);
                setCalculosUsuario({ data: [], isFetching: false });
            }
        };
        fetchCalculoUsuario();
    }, [])

    const handleSelectCalculation = () => {
        setSpinner(true);
        nextStep()
            .then(() => {
                setSpinner(false);
            })
            .catch((error) => {
                console.error(error);
                setSpinner(false);
            });
    };

    return (<>
        {calculosUsuario.isFetching &&
            <div className='flex flex-col justify-center items-center'>
                <Spinner animation="border" variant="dark" />
                <p className="mt-3">Obteniendo listado de cálculos...</p>
            </div>
        }

        {!calculosUsuario.isFetching && <>
            <h2 className='text-md mt-2 mb-2 text-airco2-general-grey'>Selecciona el cáculo a registrar:</h2>
            <div className='row'>
                <div className="container-simple-table width-66percent col-12 md:col6">
                    <table className="simple-table">
                        <thead className='text-center  text-xl sec-green-background text-white'>
                            <tr className='py-4'>
                                <th>{t("footPrint.organizacion")}</th>
                                <th>{t("footPrint.year")}</th>
                                <th>Tipo de cálculo</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {calculosUsuario.data.data != undefined && calculosUsuario.data.data.map((calculo) => {
                                if (calculo?.certificationTypes && !calculo?.certificationTypes.includes(certificationType)) {
                                    return (
                                        <tr className='cursor-pointer' key={calculo.id} onClick={() => handleSetSelectedCalculation(calculo)}
                                            style={{ border: selectedCalculation?.id == calculo.id ? '2px solid var(--airco2-orange-color)' : 'none' }}>
                                            <td>{calculo.organization.name}</td>
                                            <td>{calculo.year}</td>
                                            <td>{calculo.questionnaire.name}</td>
                                        </tr>
                                    )
                                }
                            })
                            }
                        </tbody>
                    </table>
                </div>
                <div className='width-33percent col-12 md:col6 mt-4 md:mt-0 flex justify-center items-center'>
                    {selectedCalculation != undefined &&
                        <div className={`box-calculation p-3 relative`} >
                            <div>
                                <img src={selectedCalculation.organization.urlLogo} style={{
                                    position: 'absolute',
                                    opacity: '0.05',
                                    width: '75%',
                                    height: 'auto',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }}
                                />
                                <div className='name-cif mt-2 mb-2' style={{ minHeight: '50px' }}>
                                    <p className='block text-xl font-bold text-airco2-principal '>{selectedCalculation.organization.name}  </p>
                                    <p className='block mt-1 text-lg font-medium text-airco2-principal'>{selectedCalculation.year}  </p>
                                </div>
                                <div className='type-cnae mt-2 mb-2' style={{ minHeight: '40px' }}>
                                    <p className='block mt-1 text-xs font-medium text-airco2-general-grey'>Cálculo {selectedCalculation.questionnaire.name}  </p>
                                </div>
                                <div className='sede&Actions flex mt-2 items-center justify-between'>
                                    <span className='block text-md font-medium text-airco2-general-grey'><span>{formatThousands(selectedCalculation.totalKgCO2e)}</span> kg CO2e </span>
                                    <span className='actions flex gap-3' />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className='flex justify-end mt-4 mr-4'>
                <button className='btn-sm btn-green ' onClick={() => handleSelectCalculation()}
                    disabled={selectedCalculation == undefined || spinner}>
                    {!spinner ? "Continuar" : <Spinner animation="border" variant="dark" />}
                </button>
            </div>
        </>}

    </>
    )
}