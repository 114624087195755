import axios from 'axios';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useTranslation } from 'react-i18next';
import { useRequest } from '../../../../context/RequestContext';
import UserService from '../../../../services/UserService';

export default function UploadISOInformation({ certificationDetailData, setCertificationDetail, setCertificationStep }) {
    const [t, i18n] = useTranslation("global");
    const { loading, error, responseData, makeRequest } = useRequest();

    const [selectedISOCategory, setSelectedISOCategory] = useState();
    const [isoUserExplanation, setIsoUserExplanation] = useState();
    const [isoData, setIsoData] = useState();
    const [showSpinnerQuestion, setShowSpinnerQuestion] = useState(false);
    const [showSpinnerDocumentation, setShowSpinnerDocumentation] = useState(false);
    const [totals, setTotals] = useState({ answered: 0, total: 0 });

    useEffect(() => {

        const groupedData = certificationDetailData?.certificationISODocumentationDtos.reduce((acc, item) => {
            let group = acc.find(g => g.id === item.groupId);

            if (!group) {
                group = {
                    name: item.group,
                    id: item.groupId,
                    categoryList: []
                };
                acc.push(group);
            }

            group.categoryList.push({
                name: item.category,
                id: item.categoryId,
                hasAnswers: item.hasAnswers,
                explanation: item.explanation,
                defaultExplanation: item.defaultExplanation,
                idDocumentation: item.id
            });

            return acc;
        }, []);


        if (certificationDetailData.urlCertificationDoc) {
            setCertificationStep(4);
        }

        updateTotals(groupedData);
        setIsoData(groupedData);
        setSelectedISOCategory(groupedData[0].categoryList[0])
        setIsoUserExplanation(groupedData[0].categoryList[0]?.explanation !== undefined ? groupedData[0].categoryList[0]?.explanation : groupedData[0].categoryList[0]?.defaultExplanation)
    }, [certificationDetailData]);

    const updateTotals = (groupedData) => {
        // Contar categorías totales y cuántas tienen hasAnswers en true
        const totalCategories = groupedData.reduce((sum, group) => sum + group.categoryList.length, 0);
        const answeredCategories = groupedData.reduce((sum, group) => sum + group.categoryList.filter(cat => cat.explanation).length, 0);
        setTotals({ answered: answeredCategories, total: totalCategories });
        if (totalCategories === answeredCategories && certificationDetailData.certificationMitecoStatus !== "COMPLETED") {
            fetchUpdateDocumentation({
                id: certificationDetailData.id,
                certificationMitecoStatus: 'COMPLETED',
            });
            setCertificationStep(3);
        }
    }

    const saveIsoSignificanceExplanation = async () => {
        setShowSpinnerQuestion(true);
        try {
            let endpoint = `${process.env.REACT_APP_CH_CERTIFICATION}/iso/${certificationDetailData.id}`;
            let data = {
                id: selectedISOCategory.idDocumentation,
                certificationMitecoId: selectedISOCategory.id,
                answer: isoUserExplanation,
            };
            const response = await axios.post(endpoint, data, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });

            setIsoData(prevData => {
                const updatedData = prevData.map(group =>
                    group.id === response.data.data.groupId
                        ? {
                            ...group,
                            categoryList: group.categoryList.map(category =>
                                category.id === response.data.data.categoryId
                                    ? {
                                        name: response.data.data.category,
                                        id: response.data.data.categoryId,
                                        hasAnswers: response.data.data.hasAnswers,
                                        explanation: response.data.data.explanation,
                                        defaultExplanation: response.data.data.defaultExplanation,
                                        idDocumentation: response.data.data.idDocumentation,
                                    }
                                    : category
                            ),
                        }
                        : group
                );

                updateTotals(updatedData);
                return updatedData;
            });

            setSelectedISOCategory({
                name: response.data.data.category,
                id: response.data.data.categoryId,
                hasAnswers: response.data.data.hasAnswers,
                explanation: response.data.data.explanation,
                defaultExplanation: response.data.data.defaultExplanation,
                idDocumentation: response.data.data.idDocumentation,
            })

        } catch (exception) {
            console.error(exception);
        }
        setShowSpinnerQuestion(false);
    }

    const fetchUpdateDocumentation = async (data) => {
        try {
            const url = `${process.env.REACT_APP_CH_CERTIFICATION}?notifyUser=false`;

            const response = await axios.patch(url, data, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });

            if (response.status === 200) {
                const updatedObject = {
                    ...response.data.data,
                    certificationISODocumentationDtos: certificationDetailData.certificationISODocumentationDtos
                };
                setCertificationDetail({ data: { data: updatedObject }, isFetching: false });
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleGenerateReport = async () => {
        try {
            setShowSpinnerDocumentation(true);
            let toastMsgs = { upload: t("certificaciones.iso.uploadGenerateDocument"), success: t("certificaciones.iso.successGenerateDocument"), error: t("certificaciones.iso.errorGenerateDocument") }

            let url = `${process.env.REACT_APP_CH_CALCULATION}/${certificationDetailData.calculationId}/report/pdf?withReducctionCommitments=true&reportTableType=ISO`;

            const response = await makeRequest(url, "GET", undefined, toastMsgs);

            if (response) {
                if (response.data != null) {
                    window.open(response.data.urlReportISO, '_blank');
                    await fetchUpdateDocumentation({
                        id: certificationDetailData.id,
                        urlCertificationDoc: response.data.urlReportISO,
                    });
                    setCertificationStep(4);
                }
            }
        } catch (error) {
            console.error('Error al obtener el reporte:', error);
        } finally {
            setShowSpinnerDocumentation(false);
        }
    };


    const getHasAnswersSvg = (hasAnswers, hasExplanation) => {
        if (hasAnswers) {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke={hasExplanation ? "var(--airco2-green-color)" : "var(--airco2-orange-color)"} strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="1.5">
                <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
                <path d="M9 15l2 2l4 -4"></path>
            </svg>;
        } else {
            return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke={hasExplanation ? "var(--airco2-green-color)" : "var(--airco2-orange-color)"} strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="1.5">
                <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2"></path>
                <path d="M10 12l4 5"></path>
                <path d="M10 17l4 -5"></path>
            </svg>;
        }
    }

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        if (selectedISOCategory) {
            const blocksFromHTML = htmlToDraft(selectedISOCategory.explanation || selectedISOCategory.defaultExplanation || "");
            const contentState = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            setEditorState(EditorState.createWithContent(contentState));
        }
    }, [selectedISOCategory]);

    const handleEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
        setIsoUserExplanation(removeEmojis(draftToHtml(convertToRaw(newEditorState.getCurrentContent()))));
    };


    function removeEmojis(text) {
        return text.replace(/[\uD83C-\uDBFF\uDC00-\uDFFF]+/g, ''); // Esto elimina todos los emojis
    }


    return (<>
        <div className='bg-white rounded-lg mt-4 py-4 px-4' style={{ minHeight: '400px' }} >
            <div className='p-2 md:p-4 isoDocumentationWrapper '>
                <div className=" p-2 md:p-4 h-full overflow-y-auto">
                    <div className='text-lg font-semibold mb-4 '>{t("certificaciones.iso.categories")}</div>
                    {isoData != undefined &&
                        isoData.map((group) => {
                            return <div key={group.id}>
                                {group.name}
                                {group.categoryList.map((category) => {
                                    return <div className={`cursor-pointer bg-grey-200 rounded-lg text-airco2-general-grey ${selectedISOCategory.id == category.id && 'shadow font-semibold'}`} key={category.id}
                                        onClick={() => { setSelectedISOCategory(category); setIsoUserExplanation(category?.explanation !== undefined ? category?.explanation : category?.defaultExplanation) }}
                                        style={{ border: selectedISOCategory.id == category.id ? '1px solid var(--airco2-grey-color)' : 'none' }}>
                                        <div className='text-left' style={{ padding: '1rem 0.75rem' }}>
                                            <div className='flex items-center gap-2'>
                                                <div>{getHasAnswersSvg(category.hasAnswers, category.explanation)}</div>
                                                <div>{category.name}</div>
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </div>
                        })
                    }
                </div>

                <div className="">
                    <div className='w-full rounded sec-neutro-background mb-4 p-2 md:p-4 flex flex-wrap space-x-4 justify-center'>
                        {certificationDetailData?.certificationMitecoStatus !== "COMPLETED" && totals &&
                            <div className='flex justify-between w-full px-4'>
                                <div>{t("certificaciones.iso.addSignificance")} {totals.answered} {t("certificaciones.iso.categoriesOf")} {totals.total}.</div>
                                <div>{t("certificaciones.iso.left")} {totals.total - totals.answered} {t("certificaciones.iso.byAnswer")} </div>
                            </div>
                        }
                        {certificationDetailData?.certificationMitecoStatus === "COMPLETED" &&
                            <div className='flex justify-between items-center w-full px-4'>
                                <div>{t("certificaciones.iso.addSignificance")} {t("certificaciones.iso.addSignificanceAll")}</div>
                                <div>
                                    <button className="btn-sm btn-green px-4 py-2" disabled={showSpinnerDocumentation}
                                        onClick={() => { certificationDetailData.urlCertificationDoc ? window.open(certificationDetailData.urlCertificationDoc, '_blank') : handleGenerateReport() }}>
                                        {showSpinnerDocumentation ? <Spinner animation="border" variant="dark" size="sm" /> : t("certificaciones.iso.downloadReport")}
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='w-full rounded sec-neutro-background p-4 text-airco2-general-grey' style={{ minHeight: "calc(100vh - 257.35px)" }}>
                        <div className='h-full '>
                            {!selectedISOCategory &&
                                <div className='text-xl font-bold flex justify-between'>
                                    <Spinner animation="border" variant="dark" />
                                </div>
                            }
                            {selectedISOCategory &&
                                <div>
                                    <div className='text-xl font-bold flex items-center gap-2'>
                                        <div>{getHasAnswersSvg(selectedISOCategory?.hasAnswers, selectedISOCategory?.explanation)}</div>
                                        <div> {selectedISOCategory?.name}</div>
                                    </div>
                                    <form onSubmit={(event) => {
                                        event.preventDefault();
                                        saveIsoSignificanceExplanation()
                                    }}>
                                        <div className='mt-3'>
                                            <div className='mb-2'>
                                                <p>
                                                    {t("certificaciones.iso.why")}
                                                    <span className="font-bold">{selectedISOCategory?.hasAnswers ? t("certificaciones.iso.yes") : t("certificaciones.iso.no")}</span>
                                                    {t("certificaciones.iso.answered")}:
                                                </p>
                                            </div>
                                            <div className="mb-2">
                                                <div className="bg-white border">
                                                    <Editor
                                                        editorState={editorState}
                                                        onEditorStateChange={handleEditorStateChange} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-end">
                                            <button className="btn-sm btn-green px-4 py-2" type='submit' disabled={showSpinnerQuestion}>
                                                {showSpinnerQuestion ? <Spinner animation="border" variant="dark" /> : t("certificaciones.iso.save")}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div >
        </div >

    </>)
}