import { useState } from "react";

import axios from "axios";
import { Modal, ModalBody, ModalHeader, ModalTitle } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import UserService from "../../../../services/UserService";
import FootprintCalculationList from "./FootprintCalculationList";
import Sidebar from "../../Sidebar";
import Header from "../../Header";

function FootprintCalculationAdmin() {
  const [displayModal, setDisplayModal] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const navigate = useNavigate();
  const [recalculateModal, setRecalculateModal] = useState(false);
  const [checkRecalculate, setCheckRecalculate] = useState(false);
  const [yearRecalculate, setYearRecalculate] = useState(null);
  const [recalculateResult, setRecalculateResult] = useState(undefined);
  const [recalculating, setRecalculating] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const fetchAllUsers = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_ADMIN_USERS}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${UserService.getToken()}`,
          },
        })
        .then((response) => {
          setUsuarios(response.data);
        });
    } catch (exception) {
      console.error(exception);
    }
  };

  const showNewCalculationModal = () => {
    fetchAllUsers();
    setDisplayModal(true);
  };

  const handleNewCalculation = (props) => {
    setSelectedOption();
    navigate("/footprint/calculationform", { state: { idUser: props } });
  };

  const handleSelectChange = (option) => {
    setSelectedOption(option);
  };

  const yearActual = new Date().getFullYear();
  const years = [];

  for (let year = process.env.REACT_APP_CH_YEAR; year < yearActual; year++) {
    years.push(year);
  }

  const handleRecalculate = async () => {
    setRecalculating(true);
    await axios
      .get(`${process.env.REACT_APP_CH_RECALCULATE}?year=${yearRecalculate}`, {
        headers: {
          // "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${UserService.getToken()}`,
        },
      })
      .then((response) => {
        setRecalculateModal(true);
        setRecalculateResult(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
    setRecalculating(false);
  };

  const usuariosEmpresa = usuarios.filter(
    (usuario) => usuario.userType === "Representante empresarial"
  );
  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-2">
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">CarbonManager - Cálculos</h1>
            </div>

            <div className=" rounded-sm mb-8">
              <div className="flex flex-col xxl:flex-row xxl:-mr-px">
                <main>
                  <div className="pr-4 sm:pr-6 lg:pr-8  w-full max-w-9xl mx-auto sec-neutro-background">
                    {/* Page header */}
                    <div className="flex justify-end space-x-2 text-sm">
                      <button
                        className="text-right btn-sm btn-banner-o"
                        onClick={() => showNewCalculationModal()}
                      >
                        Nuevo cálculo
                      </button>
                      <button
                        className="text-right btn-sm btn-banner-o"
                        onClick={() => setRecalculateModal(true)}
                      >
                        Recalcular
                      </button>
                    </div>
                  </div>

                  {/* Content */}
                  <div className="rounded-sm mb-8">
                    <div className="flex flex-col xxl:flex-row xxl:-mr-px p-4">
                      {/* Group 1 */}
                      <div>
                        <Modal show={displayModal}>
                          <ModalHeader className="sec-green-background">
                            <ModalTitle>Crear nuevo cálculo</ModalTitle>
                          </ModalHeader>
                          <ModalBody>
                            <div>
                              <label>Usuarios</label>
                              <Select
                                name={`usuariosa`}
                                className="custom-width-md"
                                options={usuariosEmpresa.map((user) => ({
                                  value: user.id,
                                  label: user.email,
                                }))}
                                placeholder={`Selecciona`}
                                onChange={handleSelectChange}
                                required
                              ></Select>
                            </div>
                          </ModalBody>
                          <div className="flex justify-center space-x-4 m-2">
                            <button
                              className="btn-sm btn-green"
                              onClick={() => {
                                setDisplayModal(false), setSelectedOption();
                              }}
                            >
                              Cancelar
                            </button>
                            <button
                              className="btn-sm btn-green"
                              onClick={() => handleNewCalculation(selectedOption)}
                            >
                              Aceptar
                            </button>
                          </div>
                        </Modal>
                        <Modal show={recalculateModal}>
                          <ModalHeader className="sec-green-background">
                            <ModalTitle>Recalcular formularios</ModalTitle>
                          </ModalHeader>

                          {recalculateResult != undefined && (
                            <>
                              <ModalBody>
                                Se han actualizado {recalculateResult.length} cálculos.
                                <table className="table-bordered table-striped  text-md  table-auto w-full divide-y divide-gray-200">
                                  <thead>
                                    <tr>
                                      <th>Id</th>
                                      <th>Errores</th>
                                      <th>Resputestas actualizados</th>
                                      <th>Respuetas totales</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {recalculateResult.map((x) => {
                                      return (
                                        <tr>
                                          <td>{x.calculationId}</td>
                                          <td>{x.hasErrors ? "Con errores" : ""}</td>
                                          <td>{x.updatedAnswers}</td>
                                          <td>{x.totalAnswers}</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </ModalBody>
                            </>
                          )}

                          {recalculateResult == undefined && (
                            <>
                              <ModalBody>
                                <p>
                                  Todos los cálculos{" "}
                                  <span className="font-semibold">no finalizados</span> del
                                  año que selecciones se volverán a calcular.
                                </p>
                                <div className="flex flex-col mt-2">
                                  <label className="ml-2">
                                    Selecciona el año del que qiueres recalcular los
                                    formularios
                                  </label>
                                  <select
                                    required
                                    defaultValue={""}
                                    list="yearRecalcular"
                                    className=""
                                    id="añoRecalcular"
                                    name="yearCalc"
                                    onChange={(e) => setYearRecalculate(e.target.value)}
                                  >
                                    <option
                                      className="italic"
                                      disabled="disabled"
                                      value={""}
                                    >
                                      Elige un año
                                    </option>
                                    {years?.map((x, y) => (
                                      <option key={y} value={x}>
                                        {x}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="flex flex-row mt-4">
                                  <input
                                    className="mt-1 mr-1"
                                    type="checkbox"
                                    checked={checkRecalculate}
                                    onChange={(e) => setCheckRecalculate(e.target.checked)}
                                  />
                                  <span className="text-sm">
                                    Estoy seguro de que quiero recalcular todos los cálculos
                                    correspondientes al año elegido.
                                  </span>
                                </div>
                              </ModalBody>
                            </>
                          )}

                          <div className="flex justify-center space-x-4 m-2">
                            <button
                              className="btn-sm btn-green"
                              onClick={() => {
                                setRecalculateModal(false),
                                  setCheckRecalculate(false),
                                  setYearRecalculate(null),
                                  setRecalculateResult(undefined);
                              }}
                            >
                              Salir
                            </button>

                            {recalculateResult == undefined && (
                              <button
                                className="btn-sm btn-green"
                                disabled={
                                  !checkRecalculate ||
                                  yearRecalculate == null ||
                                  recalculating
                                }
                                onClick={() => handleRecalculate()}
                              >
                                {recalculating && (
                                  <Spinner animation="border" variant="dark" />
                                )}
                                {!recalculating && "Recalcular"}
                              </button>
                            )}
                          </div>
                        </Modal>
                        <FootprintCalculationList></FootprintCalculationList>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
export default FootprintCalculationAdmin;
