import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import UserService from "../../../../services/UserService";
import Header from "../../Header";
import Sidebar from "../../Sidebar";
import EvolutionChart from "./EvolutionChart";
import EvolutionTable from "./EvolutionTable";
import { Link } from "react-router-dom";


export default function EvolutionPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [organizations, setOrganizations] = useState({ data: undefined, isFetching: false });
    const [selectedOrganizacionId, setSelectedOrganizationId] = useState(undefined);
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        const fetchOrganizations = async () => {
            const url = `${process.env.REACT_APP_CH_ORGANIZATION}/calculations`;

            try {
                const response = await axios.get(`${url}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${UserService.getToken()}`,
                    },
                });
                if (response.data.data.length === 1) {
                    setSelectedOrganizationId(response.data.data[0].id);
                }
                let organizations = response?.data?.data
                if (organizations.length > 1) {
                    organizations.push({ id: 'all', name: t("evolution.allOrganizations") })
                }

                setOrganizations({ data: organizations, isFetching: true })
                setSelectedOrganizationId(organizations[0]?.id);
            } catch (exception) {
                setOrganizations({ data: undefined, isFetching: true })
                console.error(exception);
            }
        };

        fetchOrganizations();
    }, []);

    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">

            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto ">
                        <div className="mb-8">
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("evolution.title")}</h1>
                        </div>
                        <div className='container'>

                            {!organizations?.isFetching && <div className='text-center flex flex-col items-center'>
                                <Spinner animation="border" variant="dark"></Spinner>
                                {t("evolution.charging")}
                            </div>}

                            {organizations?.isFetching && organizations?.data.length === 0 &&
                                <div className='text-center flex flex-col items-center pt-4'>
                                    <div className="mb-3">
                                        <p className="h3">{t("evolution.nodata1")}</p>
                                        <p className="">{t("evolution.nodata2")}</p>
                                        <p className="">{t("evolution.nodata3")}</p>
                                    </div>
                                    <Link className="btn-sm btn-green mt-2" to="/footprint/myfootprint">{t("evolution.goCalculations")}</Link>

                                </div>
                            }

                            {organizations?.isFetching && organizations?.data && organizations?.data.length > 0 &&
                                <>
                                    {organizations.data.length > 1 && <div className="mb-4">
                                        <ul className="flex flex-wrap items-center justify-center -m-1">
                                            {organizations.data.length > 1 && organizations.data.map(org => <li className="m-1" key={`org-${org.id}`}>
                                                <button onClick={() => setSelectedOrganizationId(org.id)} className={`${selectedOrganizacionId === org.id ? 'sec-green-background text-white' : ' text-gray-500'}
    inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                                    {org.name}
                                                </button>
                                            </li>)}
                                        </ul>
                                    </div>}

                                    <div className="evolution-years-block">
                                        <div className=" evolution-info-card flex ">
                                            <div className="bg-white rounded-sm border border-gray-200 p-4 flex flex-col justify-content-center" >
                                                <p className="pb-3"> {t("evolution.info1a")} </p>
                                                <p className="pb-3"> {t("evolution.info1b")} </p>
                                                <h3 className="pt-3 pb-2"> {t("evolution.infoTitle")}</h3>
                                                <ul>
                                                    <li className="pb-3 pl-2">
                                                        <strong>{t("evolution.infoAlcance")}:</strong> {t("evolution.infoAlcanceExp")}
                                                    </li>
                                                    <li className="pb-3 pl-2">
                                                        <strong>{t("evolution.infoActividad")}:</strong> {t("evolution.infoActividadExp")}
                                                    </li>
                                                </ul>
                                                <p className="pt-3 text-center">
                                                    {t("evolution.infoDespedida")}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="w-full flex items-stretch">
                                            <div className="w-full flex">
                                                <div className="w-full bg-white rounded-sm border border-gray-200 p-4 flex flex-col justify-content-center" >
                                                    {selectedOrganizacionId &&
                                                        <EvolutionChart
                                                            selectedOrganizacionId={selectedOrganizacionId} />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3">

                                        {selectedOrganizacionId &&
                                            <EvolutionTable
                                                selectedOrganizacionId={selectedOrganizacionId} />}
                                    </div>

                                </>}
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )

}