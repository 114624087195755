import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Footer from '../../../partials/Footer.js';
import Header from '../../../partials/Header.js';
import Transition from '../../../utils/Transition.js';
import BloqueNews from '../carbonManager/components/BloqueNews.jsx';
import AgendarForm from './AgendarForm.jsx';

export default function AgendarReunionConoceTuHuella() {
    const [t, i18n] = useTranslation("global");

    const [tab, setTab] = useState(1);
    const tabs = useRef(null);

    const dataInfo = [{ id: 1, title: "Mide de Forma Automática", text: "Sabemos que tu tiempo es valioso. Por eso airCO2 te ahorra hasta un 60% frente a otras soluciones.", icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" style="user-select: none; width: 100%; height: 100%; display: inline-block; fill: var(--airco2-green-color); color: var(--airco2-green-color); flex-shrink: 0;"><g color="var(--airco2-green-color)" weight="regular"><path d="M176,232a8,8,0,0,1-8,8H88a8,8,0,0,1,0-16h80A8,8,0,0,1,176,232Zm40-128a87.55,87.55,0,0,1-33.64,69.21A16.24,16.24,0,0,0,176,186v6a16,16,0,0,1-16,16H96a16,16,0,0,1-16-16v-6a16,16,0,0,0-6.23-12.66A87.59,87.59,0,0,1,40,104.5C39.74,56.83,78.26,17.15,125.88,16A88,88,0,0,1,216,104Zm-16,0a72,72,0,0,0-73.74-72c-39,.92-70.47,33.39-70.26,72.39a71.64,71.64,0,0,0,27.64,56.3h0A32,32,0,0,1,96,186v6h24V147.31L90.34,117.66a8,8,0,0,1,11.32-11.32L128,132.69l26.34-26.35a8,8,0,0,1,11.32,11.32L136,147.31V192h24v-6a32.12,32.12,0,0,1,12.47-25.35A71.65,71.65,0,0,0,200,104Z"></path></g></svg>`, img: "https://framerusercontent.com/images/z8M12tRnCRmVXiVa9BKjEaMHTM.webp" },
    { id: 2, title: "Genera Reportes en Tiempo Real", text: "Informes personalizados en PDF, listos para auditorías y para comunicar tu impacto ambiental con transparencia.", icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" style="user-select: none; width: 100%; height: 100%; display: inline-block; fill: var(--airco2-green-color); color: var(--airco2-green-color); flex-shrink: 0;"><g color="var(--airco2-green-color)" weight="regular"><path d="M200,152a31.84,31.84,0,0,0-19.53,6.68l-23.11-18A31.65,31.65,0,0,0,160,128c0-.74,0-1.48-.08-2.21l13.23-4.41A32,32,0,1,0,168,104c0,.74,0,1.48.08,2.21l-13.23,4.41A32,32,0,0,0,128,96a32.59,32.59,0,0,0-5.27.44L115.89,81A32,32,0,1,0,96,88a32.59,32.59,0,0,0,5.27-.44l6.84,15.4a31.92,31.92,0,0,0-8.57,39.64L73.83,165.44a32.06,32.06,0,1,0,10.63,12l25.71-22.84a31.91,31.91,0,0,0,37.36-1.24l23.11,18A31.65,31.65,0,0,0,168,184a32,32,0,1,0,32-32Zm0-64a16,16,0,1,1-16,16A16,16,0,0,1,200,88ZM80,56A16,16,0,1,1,96,72,16,16,0,0,1,80,56ZM56,208a16,16,0,1,1,16-16A16,16,0,0,1,56,208Zm56-80a16,16,0,1,1,16,16A16,16,0,0,1,112,128Zm88,72a16,16,0,1,1,16-16A16,16,0,0,1,200,200Z"></path></g></svg>`, img: "https://framerusercontent.com/images/ZYCCNv3oZ4ItTj0SMl4AX2Wi4v4.png" },
    { id: 3, title: "Actúa con Planes Personalizados", text: "Estrategias a tu medida para reducir emisiones y ayudarte a cumplir tus objetivos.", icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" style="user-select: none; width: 100%; height: 100%; display: inline-block; fill: var(--airco2-green-color); color: var(--airco2-green-color); flex-shrink: 0;"><g color="var(--airco2-green-color)" weight="regular"><path d="M232.4,114.49,88.32,26.35a16,16,0,0,0-16.2-.3A15.86,15.86,0,0,0,64,39.87V216.13A15.94,15.94,0,0,0,80,232a16.07,16.07,0,0,0,8.36-2.35L232.4,141.51a15.81,15.81,0,0,0,0-27ZM80,215.94V40l143.83,88Z"></path></g></svg>`, img: "https://framerusercontent.com/images/VKeoEy6ZfxwegsX9KotdC5q8.webp" },
    { id: 4, title: "Certifica Bajo Estándares Internacionales", text: "Cumple con normativas internacionales y garantiza la credibilidad de tus resultados.", icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" style="user-select: none; width: 100%; height: 100%; display: inline-block; fill: var(--airco2-green-color); color: var(--airco2-green-color); flex-shrink: 0;"><g  weight="regular"><path d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM224,48V208a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V48A16,16,0,0,1,48,32H208A16,16,0,0,1,224,48ZM208,208V48H48V208H208Z"></path></g></svg>`, img: "https://framerusercontent.com/images/l74LzR8CTjjeISSwd7FXuBk2zP4.png" },
    ]

    const dataInfo2 = [{ id: 1, title: "Olvídate del trabajo manual y descentralizado", text: "Recopilar los datos es complicado. Por eso ponemos a tu alcance herramientas que lo hacen fácil.", icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" color="rgb(255, 255, 255)" style="user-select: none; width: 75px; height: 100%; display: inline-block; fill: rgb(255, 255, 255); color: rgb(255, 255, 255); flex-shrink: 0;"><g color="rgb(255, 255, 255)" weight="regular"><path d="M94,24C71.63,24,56,43.74,56,72s15.63,48,38,48,38-19.74,38-48S116.37,24,94,24Zm0,80c-17.37,0-22-20.11-22-32s4.63-32,22-32,22,20.11,22,32S111.37,104,94,104Zm72,32c-22.37,0-38,19.74-38,48s15.63,48,38,48,38-19.74,38-48S188.37,136,166,136Zm0,80c-17.37,0-22-20.11-22-32s4.63-32,22-32,22,20.11,22,32S183.37,216,166,216ZM145,49.22a8,8,0,0,1,3.11-10.88l24-13.33A8,8,0,0,1,184,32v80a8,8,0,0,1-16,0V45.6l-12.12,6.73A8,8,0,0,1,145,49.22ZM104,144v80a8,8,0,0,1-16,0V157.6l-12.12,6.73a8,8,0,0,1-7.76-14l24-13.33A8,8,0,0,1,104,144Z"></path></g></svg>` },
    { id: 2, title: "Obtén resultados accionables en tiempo real", text: "Análisis y recomendaciones en tiempo real para que siempre tomes las mejores decisiones.", icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" color="rgb(255, 255, 255)" style="user-select: none; width: 75px; height: 100%; display: inline-block; fill: rgb(255, 255, 255); color: rgb(255, 255, 255); flex-shrink: 0;"><g  weight="regular"><path d="M221.87,83.16A104.1,104.1,0,1,1,195.67,49l22.67-22.68a8,8,0,0,1,11.32,11.32l-96,96a8,8,0,0,1-11.32-11.32l27.72-27.72a40,40,0,1,0,17.87,31.09,8,8,0,1,1,16-.9,56,56,0,1,1-22.38-41.65L184.3,60.39a87.88,87.88,0,1,0,23.13,29.67,8,8,0,0,1,14.44-6.9Z"></path></g></svg>` },
    { id: 3, title: "Expertos a tu disposición en todo momento", text: "Creemos en la cercanía, por ello contarás con un equipo de especialistas a tu disposición.", icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false" color="rgb(255, 255, 255)" style="user-select: none; width: 75px; height: 100%; display: inline-block; fill: rgb(255, 255, 255); color: rgb(255, 255, 255); flex-shrink: 0;"><g  weight="regular"><path d="M160,40a32,32,0,1,0-32,32A32,32,0,0,0,160,40ZM128,56a16,16,0,1,1,16-16A16,16,0,0,1,128,56ZM231.5,87.71A19.62,19.62,0,0,0,212,72H44a20,20,0,0,0-8.38,38.16l.13,0,50.75,22.35-21,79.72A20,20,0,0,0,102,228.8l26-44.87,26,44.87a20,20,0,0,0,36.4-16.52l-21-79.72,50.75-22.35.13,0A19.64,19.64,0,0,0,231.5,87.71Zm-17.8,7.9-56.93,25.06a8,8,0,0,0-4.51,9.36L175.13,217a7,7,0,0,0,.49,1.35,4,4,0,0,1-5,5.45,4,4,0,0,1-2.25-2.07,6.31,6.31,0,0,0-.34-.63L134.92,164a8,8,0,0,0-13.84,0L88,221.05a6.31,6.31,0,0,0-.34.63,4,4,0,0,1-2.25,2.07,4,4,0,0,1-5-5.45,7,7,0,0,0,.49-1.35L103.74,130a8,8,0,0,0-4.51-9.36L42.3,95.61A4,4,0,0,1,44,88H212a4,4,0,0,1,1.73,7.61Z"></path></g></svg>` }]


    return (<div>
        <Header></Header>

        <section className="relative">
            <div className="absolute inset-0 rounded-bl-[100px] bg-gray-50 pointer-events-none -z-10" aria-hidden="true" />
            <div className="flex items-center h-full header-glossary-top overflow-hidden">

                <div className="relative max-w-6xl mx-auto px-4 sm:px-6 text-center md:text-left flex flex-col items-center">
                    <div className="max-w-6xl mx-auto py-4 sm:py-6 pt-5 pb-5 mt-5 flex flex-col md:flex-row items-center">
                        <div className="md:w-1/2 mb-8 md:mb-0">
                            <h1 className="h1 md:text-5xl font-bold mb-4">
                                Calcula, reduce y gestiona tus emisiones con tecnología inteligente
                            </h1>

                        </div>
                        <div className="md:w-1/2 pl-2 text-right">
                            <div className="flex justify-end items-center">
                                <img
                                    src="https://framerusercontent.com/images/z8M12tRnCRmVXiVa9BKjEaMHTM.webp"
                                    className="rounded-lg shadow-lg w-full h-auto"
                                    style={{ width: '500px' }}
                                />
                            </div>
                            <div className="block md:hidden mt-4">
                                <a href="#form" className="btn sec-green-background text-white whitespace-nowrap w-full">
                                    Agendar consultoría gratuita
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <div className="max-w-6xl mx-auto px-4 sm:px-6 m-4" data-aos="fade-down" data-aos-delay="200"
            style={{ clipPath: "inset(0)" }}>
            <div className="flex flex-col md:flex-row gap-8">

                <div className="width-60percent resources-style">


                    {/* Section content */}
                    {/* <div className="md:grid">
                        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6 md:mt-4" data-aos="fade-right" >
                            <div className="mb-4 md:mb-0 sec-blue-text" >
                                {dataInfo.map(info => <button arial-label="tbn-nextimg"
                                    className={`flex items-center text-lg px-2 py-4 rounded-lg border  transition duration-300 ease-in-out mb-3 ${tab !== info.id ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                                    onClick={(e) => { e.preventDefault(); setTab(info.id); }}>
                                    <div>
                                        <div className="font-bold leading-snug tracking-tight mb-1">
                                            <h3>{info.title}</h3></div>
                                        <div>{info.text}</div>
                                    </div>
                                    <div className="flex justify-center items-center w-8 h-8 bg-white rounded-full shadow shrink-0 ml-3">
                                        <div dangerouslySetInnerHTML={{ __html: info.icon }} />
                                    </div>
                                </button>)}
                            </div>
                        </div>

                        <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1 mt-2" data-aos="zoom-y-out" ref={tabs}>
                            <div className="relative flex flex-col text-center hidden md:block lg:text-right">
                                <Transition
                                    show={true}
                                    appear={true}
                                    className="w-full"
                                    enter="transition ease-in-out duration-700 transform order-first"
                                    enterStart="opacity-0 translate-y-16"
                                    enterEnd="opacity-100 translate-y-0"
                                    leave="transition ease-in-out duration-300 transform absolute"
                                    leaveStart="opacity-100 translate-y-0"
                                    leaveEnd="opacity-0 -translate-y-16">
                                    <div className="relative inline-flex flex-col">
                                        <img className="md:max-w-none mx-auto rounded w-full" src={dataInfo.find(info => info.id === tab).img} alt="Features bg" />
                                    </div>
                                </Transition>
                            </div>
                        </div>
                    </div> */}

                    <div className="justify-between  mb-5 flex flex-col md:flex-row gap-2"
                        data-aos-id-blocks>
                        {dataInfo2.map(info => <div
                            className={` flex flex-col text-white pt-3 sec-dark-green-background rounded-lg`} data-aos="fade-up" data-aos-anchor="[data-aos-id-blocks]">
                            <div className='m-2'>
                                <div dangerouslySetInnerHTML={{ __html: info.icon }} className="flex justify-center items-center" />
                                <h3 className=" mb-2 text-center text16">{info.title}</h3>
                                <p className=" text-center text12">
                                    <span className='font-bold'>
                                        {info.text}
                                    </span>
                                </p>
                            </div>
                        </div>)}
                    </div>
                    <div>
                        <h2><strong>¿Por qué medir y reducir la huella de carbono de tu empresa? </strong></h2>
                        <p>
                            La sostenibilidad ya no es solo una ventaja competitiva, es un requisito.
                            Las regulaciones son cada vez más exigentes, los clientes y socios demandan transparencia,
                            y gestionar las emisiones con métodos manuales es ineficiente y propenso a errores.
                        </p>

                        <h2 className="pt-4"><strong>¿Cómo te ayudamos? </strong></h2>
                        <p>
                            Con <strong>airCO2</strong>, automatizamos la gestión de tu huella de carbono para que ahorres tiempo y tomes mejores decisiones:
                        </p>

                        <ul>
                            <li>
                                <p>
                                    <strong>Automatización:</strong>
                                    Olvídate de recopilar datos manualmente. Nuestra tecnología extrae, analiza y calcula
                                    tus emisiones, reduciendo hasta un 60% el tiempo dedicado.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Reportes en tiempo real:</strong>
                                    Accede a dashboards visuales y monitoriza la evolución de tus emisiones en todo momento.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Planes de reducción con datos reales:</strong>
                                    No solo medimos, también te ayudamos a reducir con estrategias adaptadas a tu negocio.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Cumplimiento normativo sin esfuerzo:</strong>
                                    Cumple con regulaciones como la ISO 14064, el GHG Protocol o el registro en MITECO sin perder tiempo.                                    </p>
                            </li>
                        </ul>

                        <h2 className="pt-4"><strong> ¿Qué consigues con airCO2?</strong></h2>
                        <ul>
                            <li>
                                <p>
                                    <strong>Menos carga de trabajo:</strong>
                                    Digitaliza el proceso y elimina errores manuales.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Más transparencia:</strong>
                                    Facilita la comunicación con clientes, inversores y administraciones.
                                </p>
                            </li>
                            <li>
                                <p>
                                    <strong>Mayor impacto:</strong>
                                    Reduce tu huella con decisiones basadas en datos reales.
                                </p>
                            </li>

                        </ul>



                        <p>Pasa de la complejidad a la acción. <strong>Conoce tu impacto, actúa con precisión y lidera la transición hacia la sostenibilidad.</strong></p>


                    </div>
                </div>


                {/* <div className="width-33percent client-actions-sticky" style={{ height: "100vh", minHeight:"1000px" }} id="form">
                    <iframe src="https://links.bitlyesp.com/widget/booking/45ezNN8mCv9IILWLjirJ" style={{ width: "98%", minHeight: "1000px", border: "none", overflow: "hidden" }} scrolling="no" id="tCTHaSW8Ga2aDNjIJHyn_1742223761245"></iframe><br />
                    <script src="https://links.bitlyesp.com/js/form_embed.js" type="text/javascript"></script>
                </div> */}
                <div className="width-40percent client-actions-sticky" >
                    <div className="border-dark-airco2 rounded-lg p-6 bg-white shadow-md relative glossary-scroll" id="form" >
                        <div>
                            <AgendarForm></AgendarForm>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer></Footer>

    </div>)
}