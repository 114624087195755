import React, { useEffect, useState, useContext } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';

export default function OriginDestinationInputs({ sendDataToParent, origenTerceros,isLoading }) {

  const [lng, updateLng] = useContext(IdiomContext);
  const [t, i18n] = useTranslation("global");

  const [origin, setOrigin] = useState({ name: '', coordinates: '' });
  const [destination, setDestination] = useState({ name: '', coordinates: '' });
  const [isRoundTripChecked, setRoundTripChecked] = useState(false);
  const [numberOfTrips, setNumberOfTrips] = useState(origenTerceros?1:'');



  const handleChangeOrigin = (address) => {
    setOrigin({ name: address, coordinates: '' });
  };

  const handleChangeDestination = (address) => {
    setDestination({ name: address, coordinates: '' });
  };

  const handleSelectOrigin = async (address) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    setOrigin({ name: address, coordinates: latLng });
  };

  const handleSelectDestination = async (address) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    setDestination({ name: address, coordinates: latLng });
  };

  const searchDistance = (e) => {
    e.preventDefault();
    sendDataToParent({
      origin: origin,
      destination: destination,
      numberOfTrips: numberOfTrips,
      roundTrip: isRoundTripChecked
    })
  }

  const handleCheckboxChange = () => {
    setRoundTripChecked(!isRoundTripChecked);
  };

  const handleNumberOfTripsChange = (number) => {
    setNumberOfTrips(number);
  };



  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <form onSubmit={searchDistance}>
        <div style={{ position: 'relative', width: '300px' }}>
          <PlacesAutocomplete value={origin.name} onChange={handleChangeOrigin} onSelect={handleSelectOrigin}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <label>Origen</label>
                <input
                  name="origen"
                  className='custom-width-md'
                  {...getInputProps({ placeholder: t("footPrint.enterOrigin") })}
                  required
                />
                <div className='relative z-1' style={suggestions.length > 0 || loading ? { outlineStyle: 'solid', outlineWidth: '2px', outlineColor: 'rgb(141, 212, 189)', outlineOffset: '-1px' } : {}}>
                  {loading && <div className='w-full bg-white  p-2'>Cargando...</div>}
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      className='w-full p-2'
                      {...getSuggestionItemProps(suggestion)}
                      style={{ backgroundColor: suggestion.active ? '#f0f0f0' : '#fff', cursor: 'pointer' }}
                    >
                      {suggestion.description}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>

        <div style={{ position: 'relative', width: '300px', marginTop: '10px' }}>
          <PlacesAutocomplete value={destination.name} onChange={handleChangeDestination} onSelect={handleSelectDestination}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <label>Destino</label>
                <input
                  name="destination"
                  className='custom-width-md'
                  {...getInputProps({ placeholder: t("footPrint.enterDestination") })}
                  required
                />
                <div className='relative z-1' style={suggestions.length > 0 || loading ? { outlineStyle: 'solid', outlineWidth: '2px', outlineColor: 'rgb(141, 212, 189)', outlineOffset: '-1px' } : {}}>
                  {loading && <div className='w-full bg-white  p-2'>Cargando...</div>}
                  {suggestions.map((suggestion, index) => (
                    <div
                      key={index}
                      className='w-full p-2'
                      {...getSuggestionItemProps(suggestion)}
                      style={{ backgroundColor: suggestion.active ? '#f0f0f0' : '#fff', cursor: 'pointer' }}
                    >
                      {suggestion.description}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        


          <div className={origenTerceros?'hidden':'relative'} style={{ position: 'relative', width: '300px', marginTop: '10px' }}>

            <label className=''>{t("footPrint.numViajes")}</label>
            <input className='rounded-lg w-full' defaultValue={origenTerceros?1:''} type='number' min={1} step={1} onChange={(e) => handleNumberOfTripsChange(e.target.value)} required />

            {/* <div className='w-1/2'>
              <label className='flex items-center justify-end'>
                  <input className='mr-1' type='checkbox' checked={isRoundTripChecked} onChange={handleCheckboxChange}/>
                  Ida y vuelta
              </label>
          </div> */}
          </div>

        

        <div className='mt-4 flex justify-center'>
          <button className='btn-sm btn-green text-md' type="submit" >{
            isLoading ?
            <Spinner animation="border" ></Spinner>
            :
            
          t("footPrint.calcularDistancia")
          
          
          }</button>
        </div>
      </form>
    </div>
  );
};

