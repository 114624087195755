import axios from 'axios';
import React, { useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Card from "react-bootstrap/Card";
import { useTranslation } from 'react-i18next';
import logo from '../../../images/Logo verde_airCO2.png';
import unveriefFootprint from '../../../images/unverifiedFootprint.svg';
import veriefFootprint from '../../../images/verifiedFootprint.svg';
import UserService from '../../../services/UserService';
import CalculationsList from './CalculationsList';

export default function CreateCertification({ certificationStep, changeStep, getNewCertification, userId }) {

    const [t, i18n] = useTranslation("global");
    const certificationType = "MITECO"
    const [selectedCalculation, setSelectedCalculation] = useState();
    const [isVerified, setIsVerified] = useState(null);
    const [newCertification, setNewCertification] = useState({
        data: [],
        isFetching: false
    });
    const calculationsCache = useRef(null);


    const footprinttTypes = [
        { id: 1, verified: false, name: "Huella no verificada", description: "Se tratan de informes de huella de carbono que no han pasado un proceso de auditoría para la verificación de los resultados. Por ello, el MITECO tendrá que revisarlos y el proceso de inscripción llevará más tiempo.", img: unveriefFootprint },
        { id: 2, verified: true, name: "Huella verificada", description: "Se tratan de informes de huellas de carbono que han pasado por un proceso de auditoría para la verificación de los cálculos. Las huellas de carbono bajo esta categoría son inscritas más rápido al tratarse de cálculos que ya han sido revisados por entidades acreditadas. Si quieres optar por esta vía y dotar a tus informes de un mayor aseguramiento, consúltanos para poder ayudarte", img: veriefFootprint },
    ]

    const handleSetSelectedCalculation = (calculation) => {
        setSelectedCalculation(calculation)
    }


    const fetchCreateCertification = async () => {
        setNewCertification({ data: newCertification.data, isFetching: true });
        try {
            let body = {
                userId: userId,
                calculationId: selectedCalculation.id,
                certificationType: certificationType,
                isCalculationVerified: isVerified
            };

            const response = await axios.post(`${process.env.REACT_APP_CH_CERTIFICATION}`, body, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });

            setNewCertification({ data: response.data, isFetching: false });
            getNewCertification(response.data.data)

            let url = `/footprint/certification/${response.data.data.id}`;
            window.history.pushState({}, '', encodeURI(url));

        } catch (exception) {
            console.error(exception);
            setNewCertification({ data: [], isFetching: false });
        }
    };


    const backcToCalculationSelect = () => {
        setIsVerified(null);
        changeStep(1);
    }


    const createCertification = () => {
        fetchCreateCertification();
    }

    const nextStep = () => {
        changeStep(2);

    }


    return (

        <div className={` mt-2 ${certificationStep == 1 && 'bg-white'} rounded-lg w-full h-full p-4`} style={{ minHeight: '400px' }}>
            {certificationStep == 1 &&
                <>
                    <CalculationsList
                        userId={userId}
                        calculationsCache={calculationsCache}
                        selectedCalculation={selectedCalculation}
                        handleSetSelectedCalculation={handleSetSelectedCalculation}
                        nextStep={nextStep}
                        t={t}
                        certificationType={certificationType}
                    />
                </>
            }

            {certificationStep == 2 &&
                ((newCertification.data?.data == undefined && !newCertification.isFetching) ?
                    <div>
                        <div className='pt-5 flex flex-wrap gap-3' style={{ justifyContent: 'space-around' }}>
                            {selectedCalculation != undefined &&
                                footprinttTypes.map((x, i) => {
                                    return (
                                        <Card key={i}

                                            onClick={() => setIsVerified(x.verified)}
                                            style={{ width: '20rem', height: '25rem', borderRadius: '20px', border: (isVerified != null && isVerified == x.verified) ? '3px solid var(--airco2-green-color)' : 'none', }}
                                            className=' p-1 flex  shadow-lg   transform hover:scale-105 transition duration-700 ease-out'>
                                            <Card.Title className='flex justify-center'>
                                                <img src={x.img} width={x.id == 1 ? 150 : 110} className={`py-2 `} />
                                            </Card.Title>
                                            <Card.Body className={`flex flex-col text-airco2-general-grey`}>
                                                <span className='text-md text-center font-semibold'>{t(`${x.name}`)} </span>
                                                <div className='text-sm text-justify'>{x.description}</div>
                                            </Card.Body>
                                        </Card>
                                    )
                                })
                            }
                        </div>
                        <div className='flex justify-center mt-4 mr-4 gap-4'>
                            <button className='btn-sm btn-cancel ' onClick={() => backcToCalculationSelect()}>Atrás</button>
                            <button className='btn-sm btn-green ' onClick={() => createCertification()} disabled={isVerified == null || newCertification.isFetching}>
                                {newCertification.isFetching ? <Spinner animation="border" variant="dark" /> : "Continuar"}
                            </button>
                        </div>
                    </div>
                    :
                    <div>
                        <div className=" h-full flex justify-center items-center sec-neutro-background ">
                            <div className="relative ">
                                <img src={logo} alt="logo" className='mx-auto' style={{ width: "323px" }} />
                                <div className="text-center text-lg absolute font-semibold sec-grey-text mb-4" style={{ top: "150px", left: "52px" }}>
                                    Cargando documentación
                                </div>
                                <div className="flex justify-center items-center absolute" style={{ top: "85px", left: "191px" }}>
                                    <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-airco2-secondary " role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>

    )
}