import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import employe from '../../../../../images/team3.svg';
import { formatThousands } from "../../../utils/Utils";

export default function EmployeBilling({ highLights, formatNumber, calculation, totalCO2eByReportData }) {

    const [t, i18n] = useTranslation("global");
    const [totalCO2, setTotalCO2] = useState();
    const [showSpinner, setShowSpinner] = useState(true);

    const [perEmployee, setPerEmployee] = useState();
    const [perBilling, setPerBilling] = useState();

    useEffect(() => {
        let total = undefined;
        if (calculation?.data?.totalKgCO2e === 0 && (calculation?.data?.calculationStatus === "CURRENTYEAR" || calculation?.data.year == new Date().getFullYear() - 1)) {
            if (totalCO2eByReportData.isFetching) {
                setShowSpinner(true);
            } else {
                total = totalCO2eByReportData.data
                setShowSpinner(false);
            }
        } else {
            total = calculation?.data?.totalKgCO2e;
            setShowSpinner(false);
        }
        setTotalCO2(total);

        if (total && highLights.isFetching) {
            setShowSpinner(false);
        } else {
            setShowSpinner(true);
        }


        if (highLights?.data.perEmployee && total) {
            setPerEmployee(formatNumber(total / highLights?.data.perEmployee, 2));
        }
        if (highLights?.data.perBilling && total) {
            setPerBilling(formatNumber(total / highLights?.data.perBilling, 2));
        }
    }, [highLights, calculation, totalCO2eByReportData]);







    return (
        <div className='grid-report-hightlights  rounded-sm border border-gray-200 px-2 py-3'>

            <div className='flex flex-col items-center '>
                <img src={employe} className="" />
            </div>

            <div className='flex flex-col text-center items-center justify-center text-sm'>
                {t("footPrint.empleadosFacturacion")}
            </div>

            <div className='flex flex-col justify-center text-center'>
                <table>
                    <tbody>
                        {showSpinner && <tr> <div className='text-center'>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true" /></div>
                        </tr>}
                        {!showSpinner && <>
                            {perEmployee !== undefined &&
                                <tr>
                                    <td className='font-bold text-xl text-right '>{formatThousands(perEmployee)}</td>
                                    <td className='text-xs text-left pl-2'>kgCO<sub>2</sub>e/{t("footPrint.employee")}</td>
                                </tr>
                            }
                            {highLights.isFetching && perEmployee === undefined &&
                                <tr>
                                    <td colSpan={2} className='text-xs text-center'>
                                        {t("footPrint.sinDatoEmpleados")}
                                    </td>
                                </tr>}
                        </>}
                        {showSpinner && <tr><div className="text-center">  <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true" /></div></tr>}

                        {!showSpinner && <>
                            {perBilling !== undefined &&
                                <tr>
                                    <td className='font-bold text-xl'>{formatThousands(perBilling)}</td>
                                    <td className='text-xs '> kgCO<sub>2</sub>e/{t("footPrint.facturacion")}</td>
                                </tr>}
                            {highLights.isFetching && perBilling === undefined && <tr>
                                <td colSpan={2} className='text-xs text-center'>
                                    {t("footPrint.sinDatoFacturacion")}
                                </td>
                            </tr>
                            }
                        </>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
