import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';

export default function CertificateBanner({ calculation, handleShow, show, handleClose, handleEmailCertification, buttonDisabled, textModal }) {
    const [t, i18n] = useTranslation("global");

    return <>

        <Modal show={show} animation={false} data-aos="fade-up" >
            <Modal.Header className='sec-neutro-background'>
                <Modal.Title>{t("footPrint.certificaTuHuella")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t("footPrint.certificarModal")}</p><br />
                <p>{t("footPrint.certificarEquipo")}</p><br />
                <p>{t("footPrint.certificarTerceros")}</p>

                {/* <div className='flex flex-inline'>
                                <input type='checkbox' id="checkFinalizarCalculo" className='mt-1'></input>
                                <label className='ml-2 text-sm'>{t("footPrint.revisado")}</label>
                            </div> */}
                <div className='flex space-x-4 justify-center mt-4'>
                    <button onClick={() => handleClose()} className='btn-sm btn-cancel p-2'>{t("footPrint.cancelar")}</button>
                    <button
                        className={`btn-sm p-2 btn-green `}
                        disabled={buttonDisabled}
                        onClick={() => handleEmailCertification()}>
                        {buttonDisabled ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        ) : (
                            t(textModal)
                        )}


                    </button>
                </div>
            </Modal.Body>
        </Modal>
        {(calculation?.data?.hasRequestedCertification !== true && calculation?.data?.calculationStatus === "FINALIZED") &&
            <div className='mb-4 '>
                <div className="sec-blue-background py-4 px-3" >
                    <div className=" flex flex-col lg:flex-row justify-between items-center">

                        <div className="txt " style={{ flex: "2" }}>
                            <h3 className="ml-4  text-white mb-2 " >
                                <p className='mb-2'>{t("footPrint.certificaHuella")}</p>
                                <p className='mb-2 text-xs'>{t("footPrint.certificaHuella2")}</p>
                            </h3>
                        </div>

                        <div className="btn-sabermas" style={{ flex: "1" }}>
                            <button onClick={() => handleShow()} className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:max-w-none align-items-center ">
                                <span className="h-100 btn-md btn-banner hover:bg-white shadow"  >{t("footPrint.contactanos")}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        }</>
}