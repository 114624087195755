import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Header from '../../../../pages/landings/Header';
import GetContantInformation from "./auth/GetContantInformation";
import QuestionWizard from './questions/QuestionWizard';
import project_complete from '../../../../images/tarea-completada.png';
import AirCO2Spinner from '../../utils/AirCO2Spinner';

export default function FormTemplate() {
    const [t, i18n] = useTranslation("global");

    const [event, setEvent] = useState(false);
    const [deleteCurrentQuestion, setDeleteCurrentQuestion] = useState(false);
    const [showQuestionsListButton, setShowQuestionsListButton] = useState(false);

    const updateShowQuestionListButton = (state) => {
        setShowQuestionsListButton(state)
        setDeleteCurrentQuestion(!state)
    }

    const pathParams = useParams();
    const urlSegments = decodeURI(window.location.origin + window.location.pathname).split('/');
    useEffect(() => {
        if (urlSegments[urlSegments.length - 2].includes("evento")) {
            setEvent(true)
        };
    }, [])

    const [externalQuestionnaire, setExternalQuestionnaire] = useState(
        {
            data: {
                idExternal: pathParams.idExternal,
                organization: pathParams.organization,
                isAnonymous: false,
                organizationLogo: '',
                organizationWeb: '',
                year: pathParams.year,
                idContact: pathParams.idContact || undefined
            },
            isFetching: false
        }
    );

    useEffect(() => {
        if (externalQuestionnaire?.data?.idExternal) {
            fetchDataFirstTime();
        }
    }, [externalQuestionnaire?.data?.idExternal]);


    const handleSetContactData = useCallback((contact) => {
        setExternalQuestionnaire((prevState) => ({
            ...prevState,
            data: {
                ...prevState.data,
                idContact: contact.contactId,
            }
        }));
    }, []);


    const fetchDataFirstTime = useCallback(async () => {
        setExternalQuestionnaire(prevState => ({
            ...prevState,
            isFetching: true // Inicia el indicador de carga
        }));

        try {
            const response = await axios.get(`${process.env.REACT_APP_EXTERNAL_QUESTIONNAIRE}/questionnaire/public/${externalQuestionnaire?.data.idExternal}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            if (response.data.success) {
                setExternalQuestionnaire((prevState) => ({
                    ...prevState,
                    data: {
                        ...prevState.data,
                        organizationLogo: response.data?.data?.urlOrganizationLogo || '',
                        isAnonymous: response.data?.data?.isAnonymous || false,
                        organizationWeb: response.data?.data?.urlOrganizationWebsite || '',
                        goQuestion: true,
                        defaultOrgBuilding: response.data?.data?.defaultOrganizationBuilding || null
                    },
                    isFetching: false // Finaliza el indicador de carga
                }));
            } else {
                setExternalQuestionnaire({
                    data: undefined,
                    isFetching: false // Finaliza el indicador de carga
                });
            }

        } catch (exception) {
            console.error(exception);
            setExternalQuestionnaire(prevState => ({
                ...prevState,
                isFetching: false // Finaliza el indicador de carga en caso de error
            }));
        }
    }, [externalQuestionnaire?.data?.idExternal]);

    return <div className="sec-neutro-background" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Header tercerosLogo={externalQuestionnaire?.data?.organizationLogo}></Header>

        {externalQuestionnaire.isFetching &&
            <AirCO2Spinner info={"Carbonmanager"} title={"Cargando..."}></AirCO2Spinner>
        }

        {!externalQuestionnaire.isFetching && externalQuestionnaire?.data?.organization &&
            <div className='flex flex-col mt-80 container'>
                <div className="  px-4 sm:px-6 lg:px-8 max-w-9xl py-8 mx-auto  w-full">

                    <div className='flex-1'>
                        <div className=" flex mb-5">

                            <div className=" text-gray-800 text-center px-1 w-full">
                                <p className="text-xl md:text-2xl font-bold mb-2">
                                    {t("fpThird.datosHuella")}<span> {event ? urlSegments[urlSegments.length - 1] : (externalQuestionnaire?.data?.year ? externalQuestionnaire?.data?.year : '')}</span>
                                </p>
                                <p className="text-xl md:text-2xl font-bold ">
                                    {t("fpThird.deLaOrganizacion")} <span>{externalQuestionnaire?.data?.organization ? externalQuestionnaire?.data?.organization : ''}</span>
                                </p>
                            </div>

                            {showQuestionsListButton &&
                                <div className='flex items-end mb-2 justify-end'>
                                    <button className=' flex flex-col items-end' arial-label="btn-saveFom" type='onclick'
                                        onClick={() => setDeleteCurrentQuestion(true)}
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-list-numbers" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M11 6h9" />
                                            <path d="M11 12h9" />
                                            <path d="M12 18h8" />
                                            <path d="M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4" />
                                            <path d="M6 10v-6l-2 2" />
                                        </svg>
                                        <span className='text-xs font-bold italic'>{t("fpThird.preguntas")}</span>
                                    </button>
                                </div>
                            }

                        </div>


                        {!externalQuestionnaire.isFetching
                            && externalQuestionnaire?.data?.goQuestion
                            && externalQuestionnaire?.data?.idContact === undefined &&
                            < GetContantInformation
                                externalQuestionnaire={externalQuestionnaire?.data}
                                handleSetContactData={handleSetContactData}
                                t={t}
                            />
                        }

                        {!externalQuestionnaire.isFetching
                            && externalQuestionnaire?.data?.goQuestion
                            && externalQuestionnaire?.data?.idContact !== undefined &&
                            <QuestionWizard
                                externalQuestionnaire={externalQuestionnaire?.data}
                                deleteCurrentQuestion={deleteCurrentQuestion}
                                updateShowQuestionListButton={updateShowQuestionListButton}
                                event={event}
                                t={t} />
                        }



                    </div>
                </div>
            </div>
        }
        {!externalQuestionnaire.isFetching && externalQuestionnaire?.data === undefined &&
            <div className='flex flex-col mt-80 container'>
                <div className="  px-4 sm:px-6 lg:px-8 max-w-9xl py-8 mx-auto  w-full">

                    <div className='flex flex-col justify-center mt-4'>
                        <div className="mt-4 flex justify-center">
                            <img src={project_complete} />
                        </div>
                        <p className='text-center'>{t("fpThird.formularioFinalizado")}</p>
                        <div className="text-center mt-4">
                            <button className="btn-green mt-4 px-4 py-2" type="button" onClick={() => window.location.href = "http://www.airco2.earth"}>
                                {t("fpThird.btnairco")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        }
    </div>
}