import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IdiomContext } from "../../../../context/createcontext";
import Header from '../../Header';
import Sidebar from '../../Sidebar';


export default function IntroISOSignificance() {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext)
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();

    return (

        <div className={`sec-neutro-background flex h-screen overflow-hidden`}>

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                <button arial-label="btn-saveFom" className="ml-4 mt-2 text-base font-semibold flex flex-row items-center" type='onclick' onClick={() => window.history.back()}  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M9 11l-4 4l4 4m-4 -4h11a4 4 0 0 0 0 -8h-1" />
                    </svg>
                    <span>{t("footPrint.volver")}</span>
                </button>
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                        {/* Page header */}
                        <div className="mb-8">
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Certificación de Huella de Carbono bajo ISO</h1>
                        </div>
                        <h2 className='text-lg md:text-xl text-gray-800 font-semibold'>
                        </h2>
                        <p>
                            Certificar la huella de carbono bajo las normas ISO (como la ISO 14064) ofrece múltiples ventajas
                            para las empresas y organizaciones que buscan mejorar su gestión ambiental y transparencia en la comunicación
                            de sus emisiones. Uno de los aspectos clave de este proceso es la <strong>evaluación de la significancia</strong>,
                            que permite justificar la inclusión o exclusión de datos y métodos según los criterios establecidos en la norma.
                        </p>

                        <div className='bg-white rounded-lg mt-4 py-4 px-2'>
                            <h2 className='text-xl text-gray-800 font-semibold mb-4 '>
                                Beneficios de la certificación ISO
                            </h2>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                <div className='flex flex-col flex-wrap '>
                                    <p className='text-lg pb-1'><b>🌍 Mayor credibilidad y transparencia</b></p>
                                    <p className='text-sm'>   La certificación ISO garantiza que el cálculo de la huella de carbono
                                        sigue una metodología reconocida internacionalmente,
                                        lo que refuerza la confianza de clientes, inversores y otros grupos de interés.
                                    </p>

                                </div>

                                <div className=''>
                                    <p className='text-lg pb-1'><b>📊 Mejora en la gestión y toma de decisiones</b></p>
                                    <p className='text-sm'>
                                        El análisis de significancia permite identificar las fuentes de emisión más relevantes,
                                        facilitando la optimización de estrategias de reducción y eficiencia energética.
                                    </p>
                                </div>

                                <div className=''>
                                    <p className='text-lg pb-1'><b>📜 Cumplimiento de requisitos normativos y acceso a mercados</b></p>
                                    <p className='text-sm'>
                                        Cada vez más clientes y administraciones públicas exigen certificaciones ambientales
                                        en sus procesos de contratación y compras, por lo que la certificación ISO puede abrir
                                        nuevas oportunidades comerciales.
                                    </p>
                                </div>

                                <div className=''>
                                    <p className='text-lg pb-1'><b>⚖️ Preparación para futuras regulaciones.</b></p>
                                    <p className='text-sm'>
                                        Las normativas ambientales son cada vez más estrictas. Contar con una certificación ISO
                                        ayuda a las empresas a adelantarse a futuras exigencias legales y reducir riesgos regulatorios.
                                    </p>
                                </div>

                                <div className=''>
                                    <p className='text-lg pb-1'><b>🔍 Facilita la verificación externa</b></p>
                                    <p className='text-sm'>
                                        Un cálculo basado en ISO proporciona una base sólida para auditorías y verificaciones externas,
                                        minimizando riesgos de errores o inconsistencias en los informes de emisiones  </p>
                                </div>

                                <div className=''>
                                    <p className='text-lg pb-1'><b>🌱 Impulso a la sostenibilidad corporativa</b></p>
                                    <p className='text-sm'>
                                        Demuestra un compromiso real con la reducción de emisiones y la sostenibilidad, lo que puede mejorar
                                        la reputación de la empresa y fortalecer su estrategia de responsabilidad social corporativa (RSC).  </p>
                                </div>
                            </div>
                        </div>

                        <div className='mt-4 flex justify-center'>
                            <button className='btn-sm btn-green px-4 py-2" ' onClick={() => navigate('/footprint/certification/iso-significance/new')} >
                                Empezar registro
                            </button>
                        </div>

                    </div>
                </main>
            </div>
        </div>
    )
}