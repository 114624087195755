import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import UserService from "../services/UserService";
import { toast } from "react-hot-toast";

const RequestContext = createContext();

export const useRequest = () => useContext(RequestContext);

export const RequestProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [responseData, setResponseData] = useState(null);

    const makeRequest = async (endpoint, method = "GET", data = null, toastMessages) => {
        setLoading(true);
        setError(null);
        setResponseData(null);
        const toastId = toast.loading(toastMessages.upload);

        try {
            const config = {
                method,
                url: endpoint,
                data,
                responseType: "json",  // Por defecto, supondremos que las respuestas son JSON
                headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${UserService.getToken()}`,
                          },
            };

            const response = await axios(config);

            if(response.data.success && !response.data.message.includes("ERROR")){
                toast.success(toastMessages.success, { id: toastId });
            } else {
                toast.error(toastMessages.error, { id: toastId })
            }

            // Guardamos la respuesta y mostramos el spinner
            setResponseData(response.data);
            return response.data;  // Retorna los datos para que el componente pueda usarlos
        } catch (err) {
            setError(err.message || "Error en la petición");
            toast.error(`${toastMessages.error}: ${err.message || "Ocurrió un error"}!`,{ id: toastId })
            throw err;
        } finally {
            setLoading(false);
            // toast.dismiss();
        }
    };

    return (
        <RequestContext.Provider value={{ loading, error, responseData, makeRequest }}>
            {children}
        </RequestContext.Provider>
    );
};
