import React from 'react'
import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import UserService from "../../../../services/UserService";
import axios from "axios";
import { formatThousands } from '../../utils/Utils';


export default function DecarbonizationScenario({ createScenario }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();
    const [resumeScenario, setResumeScenario] = useState({ data: undefined, isFetching: false });


    const fetchCalculationEvolutions = async () => {
        const url = `${process.env.REACT_APP_CH_DECARBONIZATION}s`;
        try {
            const response = await axios.get(`${url}`, {
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${UserService.getToken()}`,
                },
            });
            setResumeScenario({ data: response?.data?.data, isFetching: true })
        } catch (exception) {
            setResumeScenario({ data: undefined, isFetching: false });
            console.error(exception);
        }
    };


    useEffect(() => {
        fetchCalculationEvolutions();
    }, [createScenario])


    return (
        <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            {!resumeScenario.isFetching &&
                <div>
                    <div className='flex justify-center mt-8'>
                        <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full text-airco2-secondary " role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <div className='text-center mt-8'>
                        <p>Cargando...</p>
                    </div>
                </div>
            }
            {resumeScenario.isFetching && resumeScenario.data?.length === 0 &&
                <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                    <div className="max-w-2xl m-auto mt-16">
                        <div className="text-center px-4">
                            <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-gray-200 to-gray-100 dark:from-gray-700 dark:to-gray-800 mb-4">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-seeding" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M12 10a6 6 0 0 0 -6 -6h-3v2a6 6 0 0 0 6 6h3" stroke-dasharray="40" stroke-dashoffset="40">
                                        <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" />
                                    </path>
                                    <path d="M12 14a6 6 0 0 1 6 -6h3v1a6 6 0 0 1 -6 6h-3" stroke-dasharray="40" stroke-dashoffset="40">
                                        <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" begin="3s" />
                                    </path>
                                    <path d="M12 20l0 -10" stroke-dasharray="10" stroke-dashoffset="10">
                                        <animate attributeName="stroke-dashoffset" from="10" to="0" dur="3s" repeatCount="indefinite" begin="5s" />
                                    </path>
                                </svg>
                            </div>
                            <h2 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-2">¡Reducir tu Huella de Carbono será más fácil!</h2>
                            <div className="mb-6">
                                <p> Aun no tienes escenenarios creados. </p>
                                <p>¡Crea el primero para empezar a  marcar la diferencia!</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {resumeScenario.isFetching && resumeScenario.data?.length !== 0 &&
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto ">
                        <div className="container-simple-table mb-8">
                            <table className="simple-table">
                                <thead className="">
                                    <tr>
                                        <th> <div className="text-left">{t("decarbonization.scenarioName")}</div></th>
                                        <th> <div className="text-left">{t("decarbonization.periodo")}</div></th>
                                        <th> <div className="text-left">{t("decarbonization.ObjetivoScopes1and2")}</div></th>
                                        <th> <div className="text-left">{t("decarbonization.ObjetivoScope3")}</div></th>
                                        <th> <div className="text-left">{t("decarbonization.percentComplete")}</div></th>
                                        <th> <div className="text-left"></div></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        resumeScenario.isFetching &&
                                        resumeScenario?.data.map((data, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <a href={`/footprint/decarbonization/scenario/${data.id}`}>{data.name}</a></td>
                                                <td>{data.baseYear} - {data.targetYear}</td>
                                                <td>{formatThousands(data.targetScopes1and2)}%</td>
                                                <td>{formatThousands(data.targetScope3)}%</td>
                                                <td>    <div className="progress-bar-descarbonization">
                                                    <div
                                                        className="progress-bar-fill"
                                                        style={{ width: `${formatThousands(data.percentAsigned)}%` }}
                                                    />
                                                    <div className="progress-bar-text">
                                                        {formatThousands(data.percentAsigned)}%
                                                    </div>
                                                </div></td>
                                                <td className='flex gap-1'>
                                                    <button onClick={() => navigate(`scenario/${data.id}`)} className=' w-full text-center airco2-a-tag-italic' >
                                                        {t("footPrint.verDetalle")}
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </main>
            }
        </div>

    )
}
