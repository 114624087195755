import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useEffect, useRef } from "react";

export default function Inputs({ questBlockId, inputObj, inputOptions, defalultValueDate, selectKey, selectedOthers, handleInputChange, handleSelectChange, handleSecondSelectDisplay, handleTableViewDefaultValue, currencies, expenseQuestions, changeCurrency, selectedCurrency }) {
    const [t, i18n] = useTranslation("global");

    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current && inputObj.disabled) {
            inputRef.current.value = undefined;
            handleInputChange({ target: inputRef.current });
        }
    }, [inputObj.disabled]);

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? '1px solid var(--airco2-green-color)' : provided.border,
            boxShadow: state.isFocused ? '0 0 0 1px var(--airco2-green-color)' : provided.boxShadow,
            '&:hover': {
                borderColor: state.isFocused ? 'var(--airco2-green-color)' : provided.borderColor,
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--airco2-green-color)' : provided.backgroundColor,
            '&:hover': {
                backgroundColor: 'var(--airco2-green-color)',

            },
            color: state.isSelected ? 'white' : provided.color,
        }),
    };

    const input = inputObj.input;

    return (<>
        {input.inputType === 'LIST' &&
            (input.inputOptions == "SEDES" ?
                <div>
                    <Select
                        key={`input_${input.inputOptions}_${questBlockId}`}
                        name={`${input.inputOptions}`}
                        className='custom-width-md text-xs '
                        options={inputOptions.sort((a, b) => a.name.localeCompare(b.name)).map(option => ({ label: option.name, value: option.id, hijos: option.combustible }))}
                        styles={customStyles}
                        placeholder={`${t('footPrint.selecciona')}...`}
                        onChange={(e) => { handleSecondSelectDisplay(e); handleSelectChange(e, input.inputOptions) }}
                        defaultValue={() => handleTableViewDefaultValue(inputOptions, input.inputOptions)}
                        required />
                </div>
                :
                <div>
                    <Select
                        key={selectKey}
                        name={`${input.inputOptions}`}
                        className='custom-width-md text-xs '
                        options={inputOptions.sort((a, b) => a.name.localeCompare(b.name)).map(option => ({
                            label: option.name, value: option.id, [input.inputOptions === 'FLEET' ? 'fleetData' : 'hijos']: input.inputOptions === 'FLEET' ? option : option.combustible
                        }))}
                        styles={customStyles}
                        placeholder={`${t('footPrint.selecciona')}...`}
                        onChange={(e) => { handleSecondSelectDisplay(e); handleSelectChange(e, input.inputOptions) }}
                        defaultValue={() => handleTableViewDefaultValue(inputOptions, input.inputOptions)}
                        required={inputObj.required ? true : false}
                    />
                </div>)}

        {input.inputType === 'INT' &&
            <div>
                <input key={`input_${input.inputOptions}_${questBlockId}`}
                    name={`${input.inputOptions}`}
                    onChange={(e) => handleInputChange(e)}
                    // defaultValue={defaultValue} 
                    type="number" step="1" min="0"
                    className='custom-width-md  text-xs'
                    style={{ borderColor: 'hsl(0, 0%, 80%)' }} required />
            </div>
        }

        {input.inputType === 'DOUBLE' && input.inputOptions === "QUANTITY0" &&
            <div>
                <input
                    id="input-quantity0"
                    key={`input_${input.inputOptions}_${questBlockId}`}
                    name={`${input.inputOptions}`}
                    onChange={(e) => handleInputChange(e)}
                    // defaultValue={defaultValue}
                    className={`custom-width-md  text-xs`}
                    style={{ borderColor: 'hsl(0, 0%, 80%)', backgroundColor: selectedOthers ? "transparent" : "#f0f0f0" }}
                    required={selectedOthers}
                    disabled={!selectedOthers}
                    type="number" step="0.01" min="0.00" />
            </div>
        }

        {input.inputType === 'DOUBLE' && input.inputOptions !== "QUANTITY0" &&
            <div className="flex quantity-expense-container">
                {expenseQuestions.includes(questBlockId) &&
                    <select
                        value={selectedCurrency}
                        className="quantity-expense-input text-xs text-left"
                        style={{ borderColor: 'hsl(0, 0%, 80%)', width: '100px' }}
                        onChange={changeCurrency}
                    >
                        {currencies.map((curr) => (
                            <option key={curr} value={curr}>{curr}</option>
                        ))}
                    </select>
                }
                <input
                    ref={inputRef}
                    key={`input_${input.inputOptions}_${questBlockId}`}
                    id={`${input.id == 4 || input.id == 116 || input.id == 123 ? 'quantity-distance-input' + (input.inputOptions.includes("question-child") ? "_" + input.inputOptions.split("_")[2] : "") : ''}`}
                    name={`${input.inputOptions}`}
                    onChange={(e) => handleInputChange(e)}
                    className='custom-width-md  text-xs quantity-expense-input'
                    style={{ borderColor: 'hsl(0, 0%, 80%)', backgroundColor: !inputObj.disabled ? "transparent" : "#f0f0f0" }}
                    required={inputObj.required ? true : false}
                    disabled={inputObj.disabled}
                    type="number" step="0.01" min="0.00" />
            </div>
        }

        {input.inputType === 'STRING' &&
            <div>
                <input
                    key={`input_${input.inputOptions}_${questBlockId}`}
                    name={`${input.inputOptions}`}
                    onChange={(e) => handleInputChange(e)}
                    className='custom-width-md  text-xs'
                    style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                    type="text"
                    required={inputObj.required ? true : false}

                />
            </div>
        }


        {input.inputType === 'DATE' &&
            <div><input
                key={`input_${input.inputOptions}_${questBlockId}`}
                name={`${input.inputOptions}`}
                onChange={(e) => handleInputChange(e)}
                defaultValue={input.inputOptions == "REGISTRATION_DATE" ? defalultValueDate : ""}
                className='custom-width-md  text-xs'
                style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                type="date"
                required={inputObj.required ? true : false}
            />
            </div>
        }


        {input.inputType === 'BOOL' &&
            <div className='flex flex-inline'>
                <input
                    key={`input_${input.inputOptions}_${questBlockId}`}
                    name={`${input.inputOptions}`}
                    onChange={(e) => handleInputChange(e)}
                    // defaultValue={defaultValue}
                    className='custom-width-md text-xs'
                    style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                    type="checkbox" />
            </div>
        }</>)
}