import React, { useState, useContext, useEffect } from 'react';
import { IdiomContext } from "../../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import UserService from '../../../../services/UserService';
import { useParams } from 'react-router-dom';
import Select from "react-select";
import OrganizationsLocations from '../OrganizationsLocations';
import Spinner from 'react-bootstrap/Spinner';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export default function ClientForm({ id, openInModal, handleOpenEditNewWindow, setNewCreatedModalElement }) {

    const pathParams = useParams();
    const navigate = useNavigate();
    let idClient = pathParams.id;
    if (id) {
        idClient = id;
    }
    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");

    const [cif, setCif] = useState('');
    const [legalName, setLegalName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [identifier, setIdentifier] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [state, setState] = useState('');
    const [clientOrganizations, setClientOrganizations] = useState([]);

    const [fetchingClient, setFetchingClient] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [countries, setCountries] = useState([]);
    const [addNewClient, setAddNewClient] = useState(false);
    const [keyLocation, setKeyLocation] = useState(0);
    const [organizations, setOrganizations] = useState({
        data: [],
        isFetching: true
    });

    useEffect(() => {
        const fetchOrganization = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_CH_ORGANIZATIONS}?userId=${UserService.getUserId()}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });

                setOrganizations({ data: response.data, isFetching: true });

            } catch (exception) {
                setOrganizations({ data: [], isFetching: true });
            }
        };

        const fetchLocations = async () => {
            try {
                await axios.get(`${process.env.REACT_APP_CH_LOCATIONS}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                }).then(response => {

                    const countriesList = response.data.data.filter(item => item.regionList.length > 0).map(item => ({
                        id: item.id,
                        name: item.name,
                        regionList: item.regionList
                    }))

                    const sortedCountries = JSON.parse(JSON.stringify(countriesList)).sort((a, b) => {
                        return a.name.localeCompare(b.name);
                    });

                    setCountries(sortedCountries);
                });
            } catch (exception) {
                console.error(exception);
            }
        };

        const fetchClient = async () => {
            try {
                setFetchingClient(true);
                const response = await axios.get(`${process.env.REACT_APP_CH_CLIENT}/${idClient}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                let data = response.data.data;

                setCif(data.cif);
                setLegalName(data.legalName);
                setCompanyName(data.companyName);
                setIdentifier(data.identifier);
                setEmail(data.email);
                setAddress(data.address);
                setPostalCode(data.postalCode);
                setCountry(data.countryId);
                setRegion(data.regionId);
                setState(data.stateId);
                setClientOrganizations(data.organizationDtoList.map((org) => { return { label: org.name, value: org.id } }));

            } catch (exception) {
                console.error('Error fetching vehicle fleet data:', error);
            } finally {
                setFetchingClient(false)
            }
        };

        if (idClient !== undefined) {
            fetchClient();
        }
        fetchLocations();
        fetchOrganization();

    }, []);

    const resetInputs = () => {
        setCif('');
        setLegalName('');
        setCompanyName('');
        setIdentifier('');
        setEmail('');
        setAddress('');
        setPostalCode('');
        setCountry('');
        setRegion('');
        setState('');
        setClientOrganizations([]);
        setKeyLocation(keyLocation++)
    }

    const handleSubmitPost = async (e) => {
        e.preventDefault();

        let data = {
            cif: cif,
            legalName: legalName,
            companyName: companyName,
            identifier: identifier,
            email: email,
            address: address,
            postalCode: postalCode,
            stateId: state,
            organizationIdList: clientOrganizations.map((org) => { return org.value }),
            userId: UserService.getUserId()
        }
        setShowSpinner(true);
        const loadingToast = toast.loading(t("clients.guardando"), {duration: 8000});
        try {
            const response = await axios.post(`${process.env.REACT_APP_CH_CLIENT}`, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });

            if (response.data.success) {
                toast.success(t("clients.guardado"), { id: loadingToast });

                if (addNewClient) {
                    resetInputs();
                } else {
                    if (openInModal) {
                        handleOpenEditNewWindow("list", "");
                        setNewCreatedModalElement({ type: "client", value: response.data.data });
                    } else {
                        navigate(`/footprint/clients`)
                    }
                }

            } else {
                toast.error(t("clients.error"), { id: loadingToast });
            }

        } catch (error) {
            console.error("Error creating client:", error);
        } finally {
            setShowSpinner(false);
        }


    }

    const handleSubmitPut = async (e) => {
        e.preventDefault();

        let data = {
            id: idClient,
            cif: cif,
            legalName: legalName,
            companyName: companyName,
            identifier: identifier,
            email: email,
            address: address,
            postalCode: postalCode,
            stateId: state,
            organizationIdList: clientOrganizations.map((org) => { return org.value }),
            userId: UserService.getUserId()
        }

        setShowSpinner(true);
        const loadingToast = toast.loading(t("clients.actualizando"), {duration: 8000});
        try {
            const response = await axios.patch(`${process.env.REACT_APP_CH_CLIENT}/${idClient}`, data, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });

            if (response.data.success) {
                toast.success(t("clients.actualizado"), { id: loadingToast });
                if (openInModal) {
                    handleOpenEditNewWindow("list", "");
                    setNewCreatedModalElement({ type: "client", value: response.data.data });
                }
            } else {
                toast.error(t("clients.error"), { id: loadingToast });
            }

        } catch (error) {
            console.error("Error creating client:", error);
        } finally {
            setShowSpinner(false);
        }


    }

    const handleSetSedes = (index, stateId) => {
        if (stateId != undefined && stateId != "") {
            setState(stateId)
        }
    }

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? '1px solid var(--airco2-green-color)' : provided.border,
            borderRadius: '0px',
            borderColor: '#64748b',
            padding: '2px 5px 2px 5px',
            boxShadow: state.isFocused ? '0 0 0 1px var(--airco2-green-color)' : provided.boxShadow,
            '&:hover': {
                borderColor: state.isFocused ? 'var(--airco2-green-color)' : '#64748b',
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--airco2-green-color)' : (state.isSelected ? 'var(--airco2-orange-color)' : provided.backgroundColor),
            '&:hover': {
                backgroundColor: 'var(--airco2-green-color)',
                color: 'white'
            },
            color: state.isSelected ? 'white' : provided.color,
        }),
    };


    return (
        <div>

            {(idClient != undefined && fetchingClient) ?
                <div className='flex justify-center items-center gap-2'>
                    <Spinner className="" animation="border" variant='dark' />
                    <p>{t("clients.cargando")}</p>
                </div>
                :
                <form onSubmit={idClient === undefined ? handleSubmitPost : handleSubmitPut} className='text-airco2-general mt-4  '>

                    <div className='row'>
                        <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                            <label className="block text-sm font-medium w-full  mb-1" htmlFor="companyName">{t("clients.nombre")}<span className="text-red-500">*</span></label>
                            <input id="companyName" className="form-user-data  w-full bg-white" type="text" required value={companyName} onChange={(e) => { setCompanyName(e.target.value) }} />
                        </div>
                        <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                            <label className="block text-sm font-medium w-full  mb-1" htmlFor="identifier">{t("clients.identificador")}<span className="text-red-500">*</span></label>
                            <input id="identifier" className="form-user-data  w-full bg-white" type="text" required value={identifier} onChange={(e) => { setIdentifier(e.target.value) }} />
                        </div>
                        <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                            <label className="block text-sm font-medium w-full  mb-1" htmlFor="legalName">{t("clients.nombreLegal")}<span className="text-red-500">*</span></label>
                            <input id="legalName" className="form-user-data  w-full bg-white" type="text" required value={legalName} onChange={(e) => { setLegalName(e.target.value) }} />
                        </div>
                        <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                            <label className="block text-sm font-medium w-full  mb-1" htmlFor="cif">{t("clients.cif")}<span className="text-red-500">*</span></label>
                            <input id="cif" className="form-user-data  w-full bg-white" type="text" required value={cif} onChange={(e) => { setCif(e.target.value) }} />
                        </div>
                        <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                            <label className="block text-sm font-medium w-full  mb-1" htmlFor="email">{t("clients.email")}<span className="text-red-500">*</span></label>
                            <input id="email" className="form-user-data  w-full bg-white" type="text" required value={email} onChange={(e) => { setEmail(e.target.value) }} />
                        </div>
                        <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                            <label className="block text-sm font-medium mb-1" htmlFor="organizationType">{t("footPrint.organizacion")} <span className="text-red-500">*</span></label>
                            <Select
                                id="organizationType"
                                name="organizationType"
                                className="w-full bg-white"
                                styles={customStyles}
                                options={organizations?.data?.data?.map(option => ({
                                    label: option.name,
                                    value: option.id
                                }))}
                                isMulti
                                placeholder={`${t('footPrint.selecciona')}...`}
                                onChange={(selectedOptions) => {
                                    setClientOrganizations(selectedOptions || []);
                                }}
                                value={clientOrganizations.length > 0 ? clientOrganizations : null}
                                isLoading={!organizations?.data}
                                required
                            />
                        </div>

                        {countries !== undefined && countries.length > 0 &&
                            <OrganizationsLocations
                                key={keyLocation}
                                countries={countries}
                                selectedInputs={[country, region, state]}
                                index={0}
                                handleSetSedes={handleSetSedes}
                                customDivStyle={'pb-2 col-12 col-md-4 col-xxl-3'} />
                        }

                        {countries === undefined || countries.length == 0 &&
                            <div className='pb-2 col-12 col-md-4 col-xxl-3 flex justify-center items-center'>
                                <Spinner className='m-2' animation="border" variant='dark' />
                            </div>
                        }

                        <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                            <label className="block text-sm font-medium w-full  mb-1" htmlFor="postalCode">{t("clients.cp")}<span className="text-red-500">*</span></label>
                            <input id="postalCode" className="form-user-data  w-full bg-white" type="text" required value={postalCode} onChange={(e) => { setPostalCode(e.target.value) }} />
                        </div>

                        <div className='pb-2 col-12 col-md-4 col-xxl-3'>
                            <label className="block text-sm font-medium w-full  mb-1" htmlFor="address">{t("clients.direccion")}<span className="text-red-500">*</span></label>
                            <input id="address" className="form-user-data  w-full bg-white" type="text" required value={address} onChange={(e) => { setAddress(e.target.value) }} />
                        </div>

                    </div>

                    {/* 
                    <div className='flex items-center mt-8 pt-2 justify-end' >
                        <button  arial-label="btn-saveFom" className="btn btn-green text-white ml-3" type='submit' style={{ width: '10rem' }} >  {showSpinner.save ? <Spinner animation="border" variant='light' /> : t("footPrint.guardar")}</button>
                    </div> */}


                    <div className='flex justify-end items-center mt-4'>
                        {idClient == undefined &&
                            <div className='flex flex-inline h-full items-center'>
                                <input
                                    name="newClient"
                                    onChange={(e) => setAddNewClient(e.target.checked)}
                                    checked={addNewClient}
                                    className='custom-width-md text-xs mr-2'
                                    style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                    type="checkbox" />
                                <label className="block text-sm font-medium mb-0" htmlFor="newClient">{t("clients.añadirOtro")}</label>
                            </div>
                        }
                        <div>
                            <button
                                disabled={showSpinner}
                                arial-label="btn-saveFom"
                                className="btn-sm btn-green text-white ml-3"
                                type='submit'
                                style={{ width: '10rem' }} >
                                {showSpinner ?
                                    <Spinner animation="border" variant='light' />
                                    :
                                    t("footPrint.guardar")}
                            </button>
                        </div>
                    </div>

                </form>
            }
        </div>
    )
}