import React from 'react'
import axios from 'axios';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import UserService from '../../../services/UserService'
import { formatDateTime, formatThousands } from '../utils/Utils'
import { Form, FormGroup, Input, Label } from 'reactstrap';
import Banner from '../../../components/utils/Banner';
import MarketplacImages from './ImagesAdmin'
import Sidebar from '../Sidebar';
import Header from '../Header';

export default function MarketplaceProvider() {


    return (<>
        <BodyPage></BodyPage>
    </>)
}

function ProvidersMarketplace() {
    const [providers, setProviders] = useState({
        data: [],
        isFetching: false
    });

    useEffect(() => {
        const fetchProviders = async () => {
            try {

                const response = await axios.get(`${process.env.REACT_APP_MP_ALL_PROVIDERS}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setProviders({ data: response.data, isFetching: true });

            } catch (exception) {
                console.error(exception)
                setProviders({ data: [], isFetching: true });
            }
        };
        fetchProviders();
    }, []);



    const statusColor = (status) => {
        switch (status) {
            case true:
                return <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00b341" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 12l5 5l10 -10" />
                </svg>
            case false:
            default:
                return <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff4500" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1="18" y1="6" x2="6" y2="18" />
                    <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
        }
    };

    const useSortableData = (items, config = null) => {
        const [sortConfig, setSortConfig] = useState(config);

        const sortedItems = useMemo(() => {
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    if (!isNaN(a[sortConfig.key]) || moment(a[sortConfig.key]).isValid()) {
                        if (a[sortConfig.key] < b[sortConfig.key]) {
                            return sortConfig.direction === 'ascending' ? -1 : 1;
                        }
                        if (a[sortConfig.key] > b[sortConfig.key]) {
                            return sortConfig.direction === 'ascending' ? 1 : -1;
                        }
                    } else {
                        if (a[sortConfig.key].toString().toLowerCase() < b[sortConfig.key].toString().toLowerCase()) {
                            return sortConfig.direction === 'ascending' ? -1 : 1;
                        }
                        if (a[sortConfig.key].toString().toLowerCase() > b[sortConfig.key].toString().toLowerCase()) {
                            return sortConfig.direction === 'ascending' ? 1 : -1;
                        }
                    }

                    return 0;
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        return { items: sortedItems, requestSort, sortConfig };
    };


    const { items, requestSort, sortConfig } = useSortableData(providers.data);

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const handleOpen = (p, index) => {
        setShowProviderModal(true);
        setProviderModal(p)
        setProviderIndexModal(index)
    }

    const setProvider = (p, index) => {
        let newArr = [...providers.data];
        newArr[index] = p;
        setProviders({ data: newArr, isFetching: true });
        requestSort(sortConfig)
    }

    const handleOpenProviderImage = (p) => {
        setShowProviderImagesModal(true);
        setProviderImagesModal(p)
    }
    const [showProviderModal, setShowProviderModal] = useState(false)
    const [providerModal, setProviderModal] = useState(true)
    const [providerIndexModal, setProviderIndexModal] = useState(true)

    const [showProviderImagesModal, setShowProviderImagesModal] = useState(false)
    const [providerImagesModal, setProviderImagesModal] = useState(true)


    return (<>
        <EditProvider provider={providerModal} show={showProviderModal} setShow={setShowProviderModal} setProvider={setProvider} index={providerIndexModal}></EditProvider>
        <ProviderImages provider={providerImagesModal} show={showProviderImagesModal} setShow={setShowProviderImagesModal}></ProviderImages>

        <p className='text-xs'>Pulsar en las cabeceras para ordenar</p>
        <div className="overflow-x-auto" style={{ height: '800px', overflowY: 'scroll' }}>
            <table className="simple-table-sm">
                <thead className="text-center text-white sec-green-background sticky top-0 z-20">
                    <tr>
                        <th>
                            <button type="button" onClick={() => requestSort('name')}
                                className={`${getClassNamesFor('name')} `}>
                                Nombre
                            </button>
                        </th>
                        <th> <button type="button" onClick={() => requestSort('email')}
                            className={`${getClassNamesFor('email')} `}>
                            Email
                        </button></th>
                        <th> <button type="button" onClick={() => requestSort('forestNumber')}
                            className={`${getClassNamesFor('forestNumber')} `}>
                            Número de montes
                        </button></th>


                        <th>
                            <button type="button" onClick={() => requestSort('chargesEnabled')}
                                className={`${getClassNamesFor('chargesEnabled')} `}>
                                ¿Stripe charges enabled?
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('submited')}
                                className={`${getClassNamesFor('submited')} `}>
                                ¿Stripe submited?
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('creationDate')}
                                className={`${getClassNamesFor('creationDate')} `}>
                                Creado
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('updatedDate')}
                                className={`${getClassNamesFor('updatedDate')} `}>
                                Actualizado
                            </button>
                        </th>
                        <th>Acciones</th>
                    </tr>

                </thead>
                <tbody className='bg-white'>
                    {!providers.isFetching && <tr><td colSpan="8" className='text-center'><Spinner animation="border" variant="dark" /></td></tr>}
                    {items.map((p, i) => {

                        return (<tr key={i}>
                            <td>
                                {p.name}
                            </td>
                            <td>
                                {p.email ? p.email.toString() : ""}
                            </td>
                            <td>
                                {p.productProvider ? p.productProvider.length : ""}

                            </td>
                            <td>
                                <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(p.stripeChargesEnabled)}`}>
                                    {statusColor(p.stripeChargesEnabled)}
                                </div>
                            </td>
                            <td>
                                <div className={`inline-flex font-medium rounded-full text-center px-2.5 py-0.5 ${statusColor(p.stripeSubmited)}`}>
                                    {statusColor(p.stripeSubmited)}
                                </div>
                            </td>

                            <td>
                                {formatDateTime(p.creationDate)}
                            </td>
                            <td>
                                {formatDateTime(p.updatedDate)}
                            </td>
                            <td>
                                <div className='flex gap-2'>
                                    <button onClick={() => handleOpen(p, i)} className="active" aria-pressed="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-pencil" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#98d8c3" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                                            <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                                        </svg>
                                    </button>

                                    <button onClick={() => handleOpenProviderImage(p)} className="active" aria-pressed="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-photo" width="30" height="30" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#98d8c3" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <line x1="15" y1="8" x2="15.01" y2="8" />
                                            <rect x="4" y="4" width="16" height="16" rx="3" />
                                            <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
                                            <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
                                        </svg>
                                    </button>
                                </div>

                                {/* <Link to="/adminmarketplace/provider/images?origen=mp" state={p}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-photo" width="28" height="28" viewBox="0 0 24 24" strokeWidth="2"  stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <line x1="15" y1="8" x2="15.01" y2="8" />
                                    <rect x="4" y="4" width="16" height="16" rx="3" />
                                    <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
                                    <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
                                </svg>
                            </Link> */}

                            </td>
                        </tr>)
                    })}

                </tbody>
            </table>
        </div></>)
}

function ProviderImages({ provider, show, setShow }) {
    const handleClose = () => {
        setShow(false)

    }
    return <><Modal show={show}>
        <Modal.Header>
            <Modal.Title>Imágenes <b>{provider.name}  </b>      ({provider.id})
            </Modal.Title>
            <div className="flex justify-end">
                <button onClick={() => handleClose()}>
                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                </button>
            </div>
        </Modal.Header>
        <Modal.Body>
            {/* <iframe src=''>

            </iframe> */}

            <MarketplacImages origin={'provider'} state={provider} ></MarketplacImages>



        </Modal.Body>
        <Modal.Footer>
            <Button className=" text-xs btn-dashboard  btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => handleClose()}>
                Cerrar
            </Button>

        </Modal.Footer>

    </Modal></>
}

function EditProvider({ provider, setProvider, show, setShow, index }) {

    const handleClose = () => {
        setShow(false)
        setRespuestaPost({ message: "", type: "" })
    }

    const [respuestaPost, setRespuestaPost] = useState({
        type: '',
        message: ''
    });



    const handleSubmit = (event) => {
        event.preventDefault();


        let send = {

            "name": event.target.name.value,
            "externalId": provider.externalId,
            "email": provider.email,
            "updatedDate": new Date().toISOString(),

            "id": provider.id,
            "stripeId": provider.stripeId,
            "creationDate": provider.creationDate,
            "stripeSubmited": provider.stripeSubmited,
            "stripeChargesEnabled": provider.stripeChargesEnabled,
            // "forestNumber": provider.producr,

            "providerTranslation": [
                {
                    "summary": event.target.summary.value,
                    "description": event.target.description.value,
                    "languageId": 1
                }
            ],
            "gallery": []

        }


        axios.post(`${process.env.REACT_APP_MP_PRIVATE_PROVIDER}`,
            send, {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${UserService.getToken()}`
            }
        })
            .then(response => {

                if (response.status == 200) {
                    setRespuestaPost({ message: "Provider actualizado correctamente", type: "success" })
                    setProvider(send, index)

                } else {
                    setRespuestaPost({ message: "No se ha podido guardar. Inténtalo de nuevo más tarde", type: "error" })
                }






            })
    }

    return (<><Modal show={show}>
        <Modal.Header closeButton>
            <Modal.Title>Información <b>{provider.name}  </b>      ({provider.id})
            </Modal.Title>
            <div className="flex justify-end">
                <button onClick={() => handleClose()}>
                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                </button>
            </div>
        </Modal.Header>
        <Modal.Body>
            {respuestaPost.message !== "" && <Banner type={respuestaPost.type} open setOpen={true}>{respuestaPost.message}</Banner>}


            {respuestaPost.message === "" &&
                <Form className='text-xs' model={provider} onSubmit={(e) => handleSubmit(e)}>

                    <Row>
                        <Col col="6">
                            <FormGroup >
                                <Label for="name">Nombre</Label>
                                <Input className='text-xs' type="text" name="name" id="name" required defaultValue={provider.name} />
                            </FormGroup>
                        </Col>
                        <Col col="6">
                            <FormGroup >
                                <Label for="email">Email</Label>
                                <Input className='text-xs' type="text" name="email" id="email" disabled required defaultValue={provider.email} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col col="12">
                            <FormGroup >
                                <Label for="summary">Descripción corta</Label>
                                <Input className='text-xs' type="text" name="summamry" id="summary" required defaultValue={provider.providerTranslation?.[0]?.summary} maxLength={255} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col col="12">
                            <FormGroup >
                                <Label for="description">Descripción larga</Label>
                                <Input className='text-xs' type="textarea" rows={3} name="description" id="description" required defaultValue={provider.providerTranslation?.[0]?.description} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col col="12">
                            <Button className=" btn-dashboard text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" type="submit">Actualizar</Button>
                        </Col>
                    </Row>
                </Form>
            }

        </Modal.Body>
        <Modal.Footer>
            <Button className=" btn-dashboard text-xs btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap" variant="secondary" onClick={() => handleClose()}>
                Cerrar
            </Button>

        </Modal.Footer>

    </Modal></>)
}



function BodyPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        {/* Page header */}
                        <div className="mb-2">
                            {/* Title */}
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Marketplace - Proveedores</h1>
                        </div>

                        {/* Content */}
                        <div className="bg-white rounded-sm mb-8">
                            <div className="flex flex-col xxl:flex-row xxl:-mr-px">
                                <div className="flex h-screen overflow-hidden">
                                    <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                                            <div className="mt">
                                                <div className="panel panel-default">

                                                    <ProvidersMarketplace></ProvidersMarketplace>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </main>

            </div>

        </div>
    )
}
