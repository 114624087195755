import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import GoogleDistance from '../calculo-huella/GetOriginDestinationDistance';

export default function ModalGoogleDistance({ googleDistanceModal, setGoogleDistanceModal, handleDistanceFromGoogle,setShow, distanceCulationType }) {
    const [t, i18n] = useTranslation("global");
 

    return <Modal show={googleDistanceModal.view}>
        <ModalHeader className='sec-green-background'>
            <Modal.Title className="text-white">{t("footPrint.calcularDistancia")}</Modal.Title>
        </ModalHeader>
        <ModalBody>
            <div>
                <GoogleDistance sendDataToParent={handleDistanceFromGoogle} idInput={googleDistanceModal.idInput} origenTerceros={googleDistanceModal.origenTerceros} questionTitle={distanceCulationType} />
            </div>
        </ModalBody>
        <ModalFooter className='flex justify-center'>
            <button className="btn-sm btn-cancel" onClick={() => {setGoogleDistanceModal(false),setShow(googleDistanceModal.origenTerceros?true:false)}}>
                {t("footPrint.cerrarCerrar")}
            </button>
        </ModalFooter>
    </Modal>
}