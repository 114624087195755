import { Modal } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export default function ModalConfirm({ show, onConfirm, onCancel, title, message, confirm, cancel }) {
    const [t, i18n] = useTranslation("global");

    return (<Modal show={show} animation={false} data-aos="fade-up" onHide={onCancel} backdropClassName="xl-secondary-modal-backshadow">
        <Modal.Header className='sec-neutro-background sec-grey-text'>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
            <button className="btn-sm btn-cancel mr-4 px-4 py-2" onClick={onCancel}>
                {cancel || t("footPrint.cerrar")}
            </button>
            <button className="btn-sm btn-green mr-4 px-4 py-2" onClick={onConfirm}>
                {confirm || t("footPrint.confirmar")}
            </button>
        </Modal.Footer>
    </Modal >)
}