import React from 'react'
import axios from 'axios';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import UserService from '../../../services/UserService'
import { formatDateTime, formatThousands } from '../utils/Utils'
import MarketplaceOrdersLineItem from './MarketplaceOrdersLineItem';
import FilterButton from '../utils/DropdownFilterOrders';
import Sidebar from '../Sidebar';
import Header from '../Header';

export default function MarketplaceOrders() {


    return (<>
        <BodyPage></BodyPage>
    </>)
}

function ProvidersOrders() {
    const [orders, setOrders] = useState({
        data: [],
        isFetching: false
    });
    const [orderItems, setOrderItems] = useState([])

    useEffect(() => {
        const fetchProviders = async () => {
            try {

                const response = await axios.get(`${process.env.REACT_APP_MP_ORDERS}`, {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setOrders({ data: response.data, isFetching: true });
                setOrderItems(response.data);
            } catch (exception) {
                console.error(exception)
                setOrders({ data: [], isFetching: true });
            }
        };
        fetchProviders();
    }, []);


    const useSortableData = (items, config = null) => {
        const [sortConfig, setSortConfig] = useState(config);
        const sortedItems = useMemo(() => {
            let sortableItems = [...items];
            if (sortConfig !== null) {
                sortableItems.sort((a, b) => {
                    if (!isNaN(a[sortConfig.key]) || moment(a[sortConfig.key]).isValid()) {
                        if (a[sortConfig.key] < b[sortConfig.key]) {
                            return sortConfig.direction === 'ascending' ? -1 : 1;
                        }
                        if (a[sortConfig.key] > b[sortConfig.key]) {
                            return sortConfig.direction === 'ascending' ? 1 : -1;
                        }
                    } else {
                        if (a[sortConfig.key].toString().toLowerCase() < b[sortConfig.key].toString().toLowerCase()) {
                            return sortConfig.direction === 'ascending' ? -1 : 1;
                        }
                        if (a[sortConfig.key].toString().toLowerCase() > b[sortConfig.key].toString().toLowerCase()) {
                            return sortConfig.direction === 'ascending' ? 1 : -1;
                        }
                    }

                    return 0;
                });
            }
            return sortableItems;
        }, [items, sortConfig]);

        const requestSort = (key) => {
            let direction = 'ascending';
            if (
                sortConfig &&
                sortConfig.key === key &&
                sortConfig.direction === 'ascending'
            ) {
                direction = 'descending';
            }
            setSortConfig({ key, direction });
        };

        return { items: sortedItems, requestSort, sortConfig };
    };

    const { items, requestSort, sortConfig } = useSortableData(orderItems);

    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };
    const [openDescriptions, setOpenDescriptions] = useState(false);

    const toggleDescription = (id) => {
        setOpenDescriptions((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    //BGcolor para los estados de pagos
    const statusColor = (props) => {
        switch (props) {
            case 'NONE':
                return 'bg-gray-100 text-gray-500';
            case 'IN_PROGRESS':
                return 'bg-yellow-500 text-grey-100';
            case 'SUCCESS':
                return 'bg-green-100 text-green-600';
            case 'ERROR':
                return 'bg-red-100 text-red-600';
        }
    };

    const statusPaymentUniq = [];
    orders.data.map((v) => {
        if (statusPaymentUniq.indexOf(v.paymentStatus) === -1) {
            statusPaymentUniq.push(v.paymentStatus)
        }
    })

    const stateList = [];
    statusPaymentUniq.map((v) => {
        stateList.push({
            state: v,
            check: false,
        });
    })

    const applyStatePaymentFilter = (data) => {
        setCheck([...data]);
        updateTableLines(data)
        // setCurrentPage(0)
    };

    const [stateCheck, setCheck] = useState([])
    useEffect(() => {
        setCheck([...stateList]);
    }, []);

    const updateTableLines = (data) => {

        if (data == null) {
            data = stateCheck;
        }
        let states = data.filter(c => c.check).map(c => c.state);

        let filteredList = [...orders.data];

        if (states.length > 0) {
            filteredList = filteredList.filter((c) => states.includes(c.paymentStatus));
        }

        if (filteredList != undefined) { setOrderItems(filteredList); }
    };
    return (<>
        <div className="grid grid-flow-col sm:auto-cols-max justify-start gap-2 mb-2">
            <FilterButton
                statusPaymentUniq={statusPaymentUniq}
                stateCheck={stateList}
                applyStatePaymentFilter={applyStatePaymentFilter}
                nombreFiltro={"Estado del pago"}
            />
        </div>
        <p className='text-xs'>Pulsar en las cabeceras para ordenar</p>
        <div className="overflow-x-auto" style={{ height: '800px', overflowY: 'scroll' }}>
            <table className="simple-table-sm">
                <thead className="text-center text-white sec-green-background sticky top-0 z-20">
                    <tr>
                        <th>
                            <button type="button" onClick={() => requestSort('id')}
                                className={`${getClassNamesFor('id')} `}>
                                Id
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('nameEmpresa')}
                                className={`${getClassNamesFor('nameEmpresa')} `}>
                                Empresa
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('emailEmpresa')}
                                className={`${getClassNamesFor('emailEmpresa')} `}>
                                Email
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('creationDate')}
                                className={`${getClassNamesFor('creationDate')} `}>
                                Fecha Creación
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('updatedDate')}
                                className={`${getClassNamesFor('updatedDate')} `}>
                                Fecha Compra
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('total')}
                                className={`${getClassNamesFor('total')} `}>
                                Total
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('totalVat')}
                                className={`${getClassNamesFor('totalVat')} `}>
                                Total VAT
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('paymentStatus')}
                                className={`${getClassNamesFor('paymentStatus')} `}>
                                Estado Pago
                            </button>
                        </th>
                        <th>
                            <button type="button" onClick={() => requestSort('orderStatus')}
                                className={`${getClassNamesFor('orderStatus')} `}>
                                Order Status
                            </button>
                        </th>

                        <th>Acciones</th>
                    </tr>

                </thead>
                <tbody className='text-center bg-white'>
                    {!orders.isFetching && <tr><td colSpan="10" className='text-center'><Spinner animation="border" variant="dark" /></td></tr>}
                    {items.map((p, i) => {
                        return (<><tr key={i}>
                            <td>
                                {p.id}
                            </td>
                            <td>
                                {p.nameEmpresa}
                            </td>
                            <td>
                                {p.emailEmpresa}
                            </td>
                            <td>
                                {formatDateTime(p.creationDate)}
                            </td>
                            <td>
                                {formatDateTime(p.updatedDate)}
                            </td>
                            <td>
                                {formatThousands(p.total)}€
                            </td>
                            <td>
                                {formatThousands(p.total)}€
                            </td>
                            <td>
                                <div className={`text-center rounded-full py-0.5 px-2 ${statusColor(p.paymentStatus)}`}>
                                    {p.paymentStatus}
                                </div>
                            </td>
                            <td>
                                {p.orderStatus}
                                {(p.orderStatus === 'EXPIRED') &&
                                    <p>{formatDateTime(p.expiredDate)}</p>

                                }
                            </td>


                            <td>
                                <div className='flex gap-1'>
                                    <button
                                        className={`text-gray-400 hover:text-gray-500 transform ${openDescriptions[p.id] && 'rotate-180'}`}
                                        aria-expanded={openDescriptions[p.id]}
                                        onClick={() => toggleDescription(p.id)}
                                        aria-controls={`description-${p.id}`}
                                        title="Ver todo"
                                    >
                                        <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32">
                                            <path d="M16 20l-5.4-5.4 1.4-1.4 4 4 4-4 1.4 1.4z" />
                                        </svg>
                                    </button>
                                    {p.paymentStatus === "SUCCESS" ?
                                        <>
                                            <button className="active" aria-pressed="true">
                                                <a href={p.urlInvoiceStripe} target="_blank" title="Factura empresa">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-dollar" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                        <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                        <path d="M14 11h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5" />
                                                        <path d="M12 17v1m0 -8v1" />
                                                    </svg>
                                                </a>
                                            </button>

                                            <button className="active" aria-pressed="true">
                                                <a href={p.certificatePublicUrl} target="_blank" title="Certificado compensación">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-certificate" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                                        <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5" />
                                                        <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73" />
                                                        <path d="M6 9l12 0" />
                                                        <path d="M6 12l3 0" />
                                                        <path d="M6 15l2 0" />
                                                    </svg>
                                                </a>
                                            </button>
                                        </>
                                        : <></>
                                    }
                                </div>
                            </td>
                        </tr>
                            <tr id={`description-${p.id}`} role="region" className={`${!openDescriptions[p.id] && 'hidden'}`}>
                                <td colSpan="10" className="px-2 first:pl-5 last:pr-5 py-3">

                                    <table className="hover:table-auto w-full divide-y divide-gray-200 text-airco2-general">
                                        <thead>
                                            <tr>
                                                <th className="">IdOrderLine</th>
                                                <th className="text-center">Monte - idMonte</th>
                                                <th className="text-center">Comision</th>
                                                <th className="text-center">Precio t</th>
                                                <th className="text-center">Cantidad t</th>
                                                <th className="text-center">Total</th>
                                                <th className="">Acciones</th>
                                            </tr>
                                        </thead>
                                        {p.orderLineList.map(itemOrder => {
                                            return <MarketplaceOrdersLineItem itemOrder={itemOrder} key={"line" + itemOrder.id}></MarketplaceOrdersLineItem>
                                        })}

                                    </table>
                                </td>
                            </tr></>)
                    })}

                </tbody>
            </table>
        </div></>)
}


function BodyPage() {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        {/* Page header */}
                        <div className="mb-2">
                            {/* Title */}
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">Marketplace - Orders</h1>
                        </div>

                        {/* Content */}
                        <div className="bg-white rounded-sm mb-8">
                            <div className="flex flex-col xxl:flex-row xxl:-mr-px">
                                <div className="flex h-screen overflow-hidden">
                                    <div className=" sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                                            <div className="mt">
                                                <div className="panel panel-default">

                                                    <ProvidersOrders></ProvidersOrders>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>


                    </div>
                </main>

            </div>

        </div>
    )
}
