import React, { useState, useContext, useEffect } from 'react';
import { IdiomContext } from "../../../context/createcontext"
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import UserService from '../../../services/UserService';
import FileUploader from './FileUploader';
import UndocumentedAnswersTabler from './UndocumentedAnswersTabler'; 
import ReductionPlanModal from './ReductionPlanModal';
export default function UploadDocumentationMiteco({ certificationDetailData }) {

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate(); 

    const [certificationDetail, setCertificationDetail] = useState(certificationDetailData.data);
    const [selectedDocumentation, setSelectedDocumentation] = useState(certificationDetail?.mitecoDocumentationDtoList[0]);
    const [documentationToUpload, setDocumentationToUpload] = useState([]);
    const allDocumentationStatus = ["INCOMPLETE", "IN_PROGRESS", "IN_REVIEW", "COMPLETED", "WITH_ERRORS"];


    const getStatusDocumentationText = (props) => {
        switch (props) {
            case "INCOMPLETE":
                return "Incompleto";
            case "IN_PROGRESS":
                return "En curso";
            case "IN_REVIEW":
                return "En revisión";
            case "COMPLETED":
                return "Completado";
            case "WITH_ERRORS":
                return "Revisar errores";
        }
    }

    const getStatusDocumentationSvg = (props) => {
        switch (props) {
            case "INCOMPLETE":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-dotted" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7.5 4.21l0 .01" />
                        <path d="M4.21 7.5l0 .01" />
                        <path d="M3 12l0 .01" />
                        <path d="M4.21 16.5l0 .01" />
                        <path d="M7.5 19.79l0 .01" />
                        <path d="M12 21l0 .01" />
                        <path d="M16.5 19.79l0 .01" />
                        <path d="M19.79 16.5l0 .01" />
                        <path d="M21 12l0 .01" />
                        <path d="M19.79 7.5l0 .01" />
                        <path d="M16.5 4.21l0 .01" />
                        <path d="M12 3l0 .01" />
                    </svg>
                );
            case "IN_PROGRESS":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-dashed" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M8.56 3.69a9 9 0 0 0 -2.92 1.95" />
                        <path d="M3.69 8.56a9 9 0 0 0 -.69 3.44" />
                        <path d="M3.69 15.44a9 9 0 0 0 1.95 2.92" />
                        <path d="M8.56 20.31a9 9 0 0 0 3.44 .69" />
                        <path d="M15.44 20.31a9 9 0 0 0 2.92 -1.95" />
                        <path d="M20.31 15.44a9 9 0 0 0 .69 -3.44" />
                        <path d="M20.31 8.56a9 9 0 0 0 -1.95 -2.92" />
                        <path d="M15.44 3.69a9 9 0 0 0 -3.44 -.69" />
                    </svg>

                );
            case "IN_REVIEW":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-search" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10 12a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                        <path d="M12 18c-.328 0 -.652 -.017 -.97 -.05c-3.172 -.332 -5.85 -2.315 -8.03 -5.95c2.4 -4 5.4 -6 9 -6c3.465 0 6.374 1.853 8.727 5.558" />
                        <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                        <path d="M20.2 20.2l1.8 1.8" />
                    </svg>

                );
            case "COMPLETED":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check-filled" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" strokeWidth="0" fill="var(--airco2-green-color)" />
                    </svg>

                );
            case "WITH_ERRORS":
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-triangle-filled" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 1.67c.955 0 1.845 .467 2.39 1.247l.105 .16l8.114 13.548a2.914 2.914 0 0 1 -2.307 4.363l-.195 .008h-16.225a2.914 2.914 0 0 1 -2.582 -4.2l.099 -.185l8.11 -13.538a2.914 2.914 0 0 1 2.491 -1.403zm.01 13.33l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm-.01 -7a1 1 0 0 0 -.993 .883l-.007 .117v4l.007 .117a1 1 0 0 0 1.986 0l.007 -.117v-4l-.007 -.117a1 1 0 0 0 -.993 -.883z" strokeWidth="0" fill="var(--airco2-orange-color)" />
                    </svg>

                );
        }
    }

    const [reuquestedUrl, setRequestedUrl] = useState({
        data: [],
        isFetching: false
    });

    const [fetchingUrl, setFetchingUrl] = useState();

    const getDocumentationUrl = async (id) => {

        try {

            setFetchingUrl(id)
            setRequestedUrl({ data: reuquestedUrl.data, isFetching: true });

            const response = await axios.get(`${process.env.REACT_APP_CH_CERTIFICATION}/miteco/${certificationDetail.id}/documentation/${id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            
            let objeto = response.data;
            setRequestedUrl({ data: objeto, isFetching: false });
            window.open(objeto.data, '_blank')
            setFetchingUrl()

        } catch (exception) {
            console.error(exception);
            setRequestedUrl({ data: [], isFetching: false });
            setFetchingUrl()

        }
    };

    const handleOpenFile = (props) => {

        if (props.fileUrl.includes("https://")) {
            window.open(props.fileUrl, '_blank')
        } else {
            getDocumentationUrl(props.id)
        }
    }


    const [deletingDocumentation, setDeletingDocumentation] = useState();

    const fetchDeleteDocumentation = async (id) => {

        try {
            setDeletingDocumentation(id);

            const response = await axios.delete(`${process.env.REACT_APP_CH_CERTIFICATION}/miteco/${certificationDetail.id}/documentation/${selectedDocumentation.id}?idCertificationDocumentation=${id}`, {
                headers: {
                    'Accept': 'application/json',
                    //   'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            
            let objeto = response.data;

            setCertificationDetail((certificationDetail) => {
                const nuevoArray = certificationDetail.mitecoDocumentationDtoList.map((item) => {
                    return item.id === objeto.data.id ? objeto.data : item;
                });
                return { ...certificationDetail, mitecoDocumentationDtoList: nuevoArray };
            });

            setSelectedDocumentation(objeto.data);
            setDeletingDocumentation();

        } catch (error) {
            console.error(error);
            setDeletingDocumentation()
        }
    }

    const handleDeleteFile = (props) => {
        fetchDeleteDocumentation(props.id)
    }


    const [savedDocumentation, setSavedDocumentation] = useState({
        data: [],
        isFetching: false
    });

    const saveDocumentation = async (data, newCertificationFile) => {
        try {

            setSavedDocumentation({ data: savedDocumentation.data, isFetching: true });

            let endpoint = `${process.env.REACT_APP_CH_CERTIFICATION}/miteco/${certificationDetail.id}/documentation/${selectedDocumentation.id}${newCertificationFile ? "/files" : "" }`;

            const response = await axios.post(endpoint, data, {
                headers: {
                    'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });

            let objeto = response.data;
            setSavedDocumentation({ data: objeto, isFetching: false });

            setCertificationDetail((certificationDetail) => {
                const nuevoArray = certificationDetail.mitecoDocumentationDtoList.map((item) => {
                    return item.id === objeto.data.id ? objeto.data : item;
                });
                return { ...certificationDetail, mitecoDocumentationDtoList: nuevoArray };
            });

            setSelectedDocumentation(objeto.data);
            setDocumentationToUpload([]);

        } catch (exception) {
            console.error(exception);
            setSavedDocumentation({ data: [], isFetching: false });
        }
    };

    const uploadFiles = (files) => {

        let formData = new FormData();

        files.forEach((file) => {
            formData.append('files', file);
        });

        setDocumentationToUpload(files)
        saveDocumentation(formData, true);
    }



    const handleBlur = (certificationMitecoId, idDoc, nuevoValor) => {

        const preguntaActual = selectedDocumentation.questionDtoList.find((q) => q.certificationMitecoId === certificationMitecoId);
        if (nuevoValor != "" && (preguntaActual.answer == undefined || preguntaActual.answer == "" || preguntaActual.answer !== nuevoValor)) {
            let data = [{
                certificationMitecoId: certificationMitecoId,
                answer: nuevoValor,
                id: idDoc != undefined ? idDoc : ""
            }];
            saveDocumentation(data, false);
        }
    };



    const [showInvoicesMNodal, setShowInvoicesMNodal] = useState(false);
    const [showReductionPlanModal, setShowReductionPlanModal] = useState(false);

    
    const updateShow = (props) => {
        if(props.modal == "CALCANSWERS"){
            setShowInvoicesMNodal(props.state)
        } else {
            setShowReductionPlanModal(props.state)
        }
    }

    const saveDocumentationFromCalculationDoc = (props) => {

        let data = [{idCalculationDocumentation: props.id, }]
        saveDocumentation(data, false);
        setUndocumentedAnswers({ data: undocumentedAnswers.data, isFetching: false, total: undocumentedAnswers.total - 1 });

        if(undocumentedAnswers.total != null && (undocumentedAnswers.total - 1) == 0){
            fetchAnswer();
        }
    }


    const [undocumentedAnswers, setUndocumentedAnswers] = useState({
        data: [],
        isFetching: false,
        total: null
    });

    const fetchAnswer = async () => {
        const url = `${process.env.REACT_APP_CH_CALCULATION}/${certificationDetail.calculationId}/undocumented-answers`
        try {
            setUndocumentedAnswers({ data: undocumentedAnswers.data, isFetching: true, total: null });
            const response = await axios.get(`${url}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            let data = response.data;
            let total = 0;
            data.data.map((item) => {
                total += item.answerBodyOutputDtos.length;
            }) 
            setUndocumentedAnswers({ data: data, isFetching: false, total: total });
        } catch (exception) {
            setUndocumentedAnswers({ data: [], isFetching: false, total: null });
            console.error('Error al realizar la solicitud:', exception);
        }
    };

    useEffect(()=> {
        fetchAnswer();
    },[]);

      
  const updateDocumentationMiteco = (props) => {

    setCertificationDetail((certificationDetail) => {
        const nuevoArray = certificationDetail.mitecoDocumentationDtoList.map((item) => {
            return item.id === props.data.id ? props.data : item;
        });
        return { ...certificationDetail, mitecoDocumentationDtoList: nuevoArray };
    });

    setSelectedDocumentation(props.data);


  }


    return (
        <>
            {certificationDetail == undefined ? <div className='flex justify-center'><Spinner animation="border" variant="dark" /></div> :

                <div className='bg-white rounded-lg mt-4 py-4 px-4' style={{minHeight: '400px'}} >
                    <div className='p-2 md:p-4 mitecoDocumentationWrapper '>
                        <div className=" p-2 md:p-4 ">
                            <span className='text-lg font-semibold mb-4'>Documentación necesaria:</span>
                            {certificationDetail != undefined && certificationDetail.mitecoDocumentationDtoList.map((step) => {
                                return (
                                    <div className={`cursor-pointer bg-grey-200 rounded-lg text-airco2-general-grey ${selectedDocumentation?.id == step.id && 'shadow font-semibold'}`} key={step.id} onClick={() => {setSelectedDocumentation(step);}} style={{ border: selectedDocumentation?.id == step.id ? '1px solid var(--airco2-grey-color)' : 'none' }}>
                                        <td className='text-left' style={{ padding: '1rem 0.75rem' }}>
                                            <div className='flex items-center gap-2'>
                                                <div>{getStatusDocumentationSvg(step.generalStatus)}</div>
                                                <div>{step.name}</div>
                                            </div>

                                        </td>
                                    </div>
                                )

                            })
                            }

                        </div>

                        <div className="mitecoDocDataWrapper ">
                            <div className='w-full rounded sec-neutro-background mb-4 p-2 md:p-4 flex flex-wrap space-x-4 justify-center'>
                                {allDocumentationStatus.map(v => {
                                    return (
                                        <div className='flex items-center justify-center space-x-2'>
                                            <span>{getStatusDocumentationSvg(v)}</span>
                                            <span>{getStatusDocumentationText(v)}</span>
                                        </div>
                                    )
                                })}

                            </div>
                            <div className='w-full rounded sec-neutro-background p-4 text-airco2-general-grey'>
                                <div className='h-full mitecoDocDataDiv'>

                                    <div>
                                        <div className='text-xl font-bold flex justify-between'>
                                            <span>{selectedDocumentation.name}</span>
                                            {selectedDocumentation?.documentUrl != undefined &&
                                            <button onClick={()=> window.open(selectedDocumentation?.documentUrl, '_blank')}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-download" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                    <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                    <path d="M12 17v-6" />
                                                    <path d="M9.5 14.5l2.5 2.5l2.5 -2.5" />
                                                </svg>
                                            </button>}
                                        </div>
                                        <div>
                                            <p className='text-sm' dangerouslySetInnerHTML={{ __html: selectedDocumentation.description }} />
                                              {selectedDocumentation.generalStatus == "WITH_ERRORS" && 
                                                <div className='mt-2 flex flex-col'>
                                                    <span className='flex items-center space-x-2'> 
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-alert-triangle" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                                            <path d="M12 9v4" />
                                                            <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
                                                            <path d="M12 16h.01" />
                                                        </svg>
                                                        <span style={{color: 'var(--airco2-orange-color)'}}>Errores:</span>
                                                     </span>
                                                    {selectedDocumentation?.documents?.map((doc) => {
                                                        if(doc?.errorMessage != undefined) {
                                                            return (
                                                                <span className=' px-2 text-sm italic'>- {doc.errorMessage}</span>
                                                            )
                                                        }
                                                    })}
                                                    {selectedDocumentation?.questionDtoList?.map((question) => {
                                                        if(question?.errorMessage != undefined) {
                                                            return (
                                                                <span className=' px-2 text-sm italic'>- {question.errorMessage}</span>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                                }
                                            {selectedDocumentation?.errorMessage != undefined && <p className='mt-2'></p>}
                                        </div>
                                    </div>


                                    {/* BLOQUE PARA VER-BORRAR DOCUMENTACIÓN Y MODAL DE SUBIR DOCUMENTOS PARA DATOS DE LA CALCULADORA */}
                                    <div>{(selectedDocumentation?.documents != undefined || documentationToUpload.length > 0 || [4,6].includes(selectedDocumentation.id)) &&
                                        <>
                                            <span>Mi documentación:</span>
                                            {/* SI HAY CALCULATIONANSWERS SIN DOCUMENTOS MODAL PARA SUBIR CADA DOCUMENTOS A SU RESPUESTA */}
                                            {selectedDocumentation.id == 4 ? 
                                                (undocumentedAnswers.isFetching ?
                                                    <Spinner animation="border" size="sm" />
                                                    :
                                                    ((undocumentedAnswers.total != null && undocumentedAnswers.total > 0) ?
                                                        <div>
                                                            <p className='text-sm'>Tienes <b className='text-lg'>{undocumentedAnswers.total}</b> datos en tu cálculo sin ninguna documentación asignada, es necesario que adjuntes la documentación correspondiente para cada dato introducido.</p>
                                                            <div className='flex justify-center'>
                                                                <button className='mt-2 text-sm btn-sm btn-green rounded' onClick={()=>setShowInvoicesMNodal(true)}>Completar documentación</button>
                                                            </div>
                                                            <UndocumentedAnswersTabler 
                                                                idCalculation={certificationDetail.calculationId} 
                                                                show={showInvoicesMNodal} 
                                                                updateShow={updateShow} 
                                                                saveDocumentationFromCalculationDoc={saveDocumentationFromCalculationDoc}
                                                                answers={undocumentedAnswers}
                                                            />
                                                        </div>

                                                    :
                                                        <div className='text-sm'>Puedes ver todos los documentos de este paso en 'Ver detalle de emisiones' desde el <span className="airco2-a-tag-italic" onClick={()=>navigate(`/footprint/myfootprint/report/${certificationDetail.calculationId}`)}>detalle del cálculo</span>.</div>
                                                    )
                                                )
                                            :
                                            //VER Y BORRAR DOCUMENTOS SUBIDOS
                                            <div>
                                                {(selectedDocumentation.id == 6 && selectedDocumentation?.documents == undefined) &&
                                                    <div className='flex flex-col text-sm'>
                                                        <span>Puedes subir tu propio plan de reducción o puedes generar uno con nuestra funcionalidad de reducción.</span>
                                                        <div className='flex justify-center'>
                                                            <button className='mt-2 text-sm btn-sm btn-green rounded' onClick={()=>setShowReductionPlanModal(true)}>Generar plan de reducción</button>
                                                        </div>
                                                        <ReductionPlanModal
                                                                show={showReductionPlanModal} 
                                                                organizationName={certificationDetail.organizationName} 
                                                                calcualtionYear={certificationDetail.calculationYear} 
                                                                idCalculation={certificationDetail.calculationId}
                                                                updateShow={updateShow}
                                                                updateDocumentationMiteco={updateDocumentationMiteco}
                                                                idCertification={certificationDetail.id}
                                                            />

                                                    </div>
                                                }
                                                <div className='mitecoUploaddedDocsGrid'>
                                                    {selectedDocumentation?.documents?.map((doc) => {
                                                        return (
                                                            <div className='flex justify-between items-center'>
                                                                <div className='text-sm'>{doc.fileName}</div>
                                                                <div className='flex justify-center items-center space-x-2'>
                                                                    <button onClick={() => handleOpenFile(doc)} disabled={fetchingUrl == doc.id}>
                                                                        {fetchingUrl == doc.id ?
                                                                            <Spinner animation="border" size="sm" />
                                                                            :
                                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-description" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                                                            <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                                                                            <path d="M9 17h6" />
                                                                            <path d="M9 13h6" />
                                                                        </svg> 
                                                                        }
                                                                    </button>
                                                                    <button className={`${(doc.documentationCertificationStatus == "COMPLETED" || doc.documentationCertificationStatus == "IN_REVIEW") && "hidden"}`} onClick={() => handleDeleteFile(doc)} disabled={deletingDocumentation == doc.id}>
                                                                        {deletingDocumentation == doc.id ?
                                                                            <Spinner animation="border" size="sm" />
                                                                            :
                                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-trash" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <path d="M4 7l16 0" />
                                                                            <path d="M10 11l0 6" />
                                                                            <path d="M14 11l0 6" />
                                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                                        </svg>
                                                                        }
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        )
                                                    })
                                                    }
                                                    {/* MOSTRAR DOCUMENTOS QUE ESTÁN SUBIENDOSE */}
                                                    {documentationToUpload?.map((doc) => {
                                                        return (
                                                            <div className='flex justify-between items-center'>
                                                                <div className='flex justify-center items-center space-x-2'>
                                                                    <Spinner animation="border" size="sm" />
                                                                </div>
                                                                <div className='text-sm' style={{ color: 'var(--airco2-orange-color)' }}>{doc.name}</div>

                                                            </div>

                                                        )
                                                    })
                                                    }

                                                </div>
                                            </div>}
                                        </>}
                                    </div>


                                    {/* BLOQUE RESPONDER POREGUNTAS / SUBIR DOCUMENTOS */}
                                    {selectedDocumentation?.questionDtoList != undefined ?
                                        <div className=''>
                                            {selectedDocumentation?.questionDtoList?.map((question) => {
                                                return (
                                                    <div className='flex flex-col space-y-2' key={question.documentationMitecoId}>
                                                        <label className='text-sm'>{question.question}</label>
                                                        <textarea 
                                                            id={`question-${question.documentationMitecoId}`}  
                                                            defaultValue={question?.answer} 
                                                            disabled={question.editable != undefined && !question.editable} 
                                                            onBlur={(e) => handleBlur(question.certificationMitecoId, question?.certificationDocumentationId, e.target.value)}/>
                                                    </div>
                                                )
                                            })}
                                        </div> 
                                        :
                                        selectedDocumentation.id == 4 ? "" :
                                            <div> 
                                                {selectedDocumentation.generalStatus == "COMPLETED" || selectedDocumentation.generalStatus == "IN_REVIEW" ? "" :
                                                    <FileUploader uploadFiles={uploadFiles} />
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            }
        </>
    )
}