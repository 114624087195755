import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { IdiomContext } from "../../../context/createcontext";
import folder from '../../../images/folder2.svg';
import UserService from "../../../services/UserService";
import GoogleDistance from './GetOriginDestinationDistance';

function InputsComponent({
    questBlock,
    idCalculation,
    addAnswer1,
    isButtonDisabled,
    inputData,
    showSendButton,
    colLength,
    processState,
    year,
    extractedData
}) {

    const [lng, updateLng] = useContext(IdiomContext);
    const [t, i18n] = useTranslation("global");
    const [inputDataModified, setInputDataModified] = useState(null);
    const [defalultValueDate, setDefaultValueDate] = useState(null);
    const [submitBtnState, setSubmitBtnState] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOthers, setSelectedOthers] = useState(false);
    const [selectedValue, setSelectedValue] = useState("");
    const [firstSelectValue, setFirstSelectValue] = useState();
    const [otherSelectValue, setOtherSelectValue] = useState();
    const [googleDistanceModal, setGoogleDistanceModal] = useState({ view: false, idInput: null });
    const [savedAnswer, setSavedAnswer] = useState({
        data: [],
        isFetching: false,
    });

    const invoiceProcessStates = {
        NOTUPLOADED: 'NOTUPLOADED',
        ERROR: 'ERROR',
        UPLOADING: 'UPLOADING',
        UPLOADED: 'UPLOADED',
        SENT: 'SENT',
        COMPLETED: 'COMPLETED',
        SUCCESS: 'SUCCESS',
        SAVEKO: 'SAVEKO'
    };

    const getInputType = (inputType) => {
        switch (inputType) {
            case "STRING":
                return "text";
            case "DOUBLE":
                return "number";
            case "INT":
                return "number";
            case "BOOL":
                return "checkbox";
            case "DATE":
                return "date";
        }
    };


    useEffect(() => {
        if (extractedData != undefined) {
            inputData.map((x, i) => {

                let inputValue = null;
                if (extractedData[0].input != undefined) {
                    inputValue = extractedData.find((element) => element?.input?.id == x.input.id).input.value;
                } else {
                    inputValue = extractedData.find((element) => element?.id == x.input.id).value;
                }

                if (inputValue != "" && inputValue != null && inputValue != undefined) {
                    if (x.input.inputType == "LIST" && x.input.options != "SEDES") {
                        if('combustible' in x.input.options[0]) {
                            let checkOptionSelected = x.input.options.find((option) => option.name == inputValue.name);
                            if(checkOptionSelected != undefined) {
                                setFirstSelectValue({ value: undefined, label: inputValue.name })
                                handleSelectChange({ value: undefined, label: inputValue.name }, x.input.inputOptions);
                                let hijos = x.input.options.find((element) => element?.name == inputValue?.name);
                                handleSecondSelectDisplay({hijos: hijos.combustible});
    
                                handleSelectChangeSecondSelect({ value: inputValue.combustible.id, label: inputValue.combustible.name }, x.input.inputOptions);
                                setSelectedValue({ value: inputValue.combustible.id, label: inputValue.combustible.name });
  
                            }
                            
 
                        } else {

                            let checkOptionSelectedByName = x.input.options.find((option) => option.name == inputValue.name);
                            let checkOptionSelectedById = x.input.options.find((option) => option.id == inputValue.id);

                            if(checkOptionSelectedByName != undefined && (checkOptionSelectedByName.id != undefined && checkOptionSelectedByName.id != null)) {

                                handleSelectChange({ value: inputValue.id, label: inputValue.name }, x.input.inputOptions);
                                if (x.input.inputOptions == "CONCEPT") {
                                    setFirstSelectValue({ value: inputValue.id, label: inputValue.name })
                                } else {
                                    setOtherSelectValue({ value: inputValue.id, label: inputValue.name })

                                }
                            } 
 

                        }

                    } else {
                        x.input.value = inputValue;
                        handleInputChange({ name: x.input.inputOptions, type: getInputType(x.input.inpuType), value: x.input.value })
                    }
                }
            });
            setInputDataModified(inputData)
        }
    }, [extractedData])



    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? '1px solid var(--airco2-green-color)' : provided.border,
            boxShadow: state.isFocused ? '0 0 0 1px var(--airco2-green-color)' : provided.boxShadow,
            '&:hover': {
                borderColor: state.isFocused ? 'var(--airco2-green-color)' : provided.borderColor,
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'var(--airco2-green-color)' : provided.backgroundColor,
            '&:hover': {
                backgroundColor: 'var(--airco2-green-color)',

            },
            color: state.isSelected ? 'var(--airco2-green-color)' : provided.color,
        }),
    };

    //Si cambia de pregunta
    useEffect(() => {
        setSelectedOption(null);
        setSelectedOthers(false);
    }, [questBlock])

    useEffect(() => {
        setSubmitBtnState(false)
    }, [isButtonDisabled]);

    useEffect(() => {
        const objetoTransformado = {};

        inputData.map(inputObj => {
            const input = inputObj.input;
            const options = input.options;

            if (options != undefined && options.length === 1) {
                for (const item of options) {
                    objetoTransformado[input.inputOptions.toLowerCase()] = item.id;
                }
            }

        });
        setFormSavingData({ ...objetoTransformado });

    }, [inputData]);


    const handleSecondSelectDisplay = (option) => {
        if (option.hijos !== undefined) {
            setSelectedValue(null)
            setSelectedOption(option.hijos);
        }
    };

    const [formSavingData, setFormSavingData] = useState({});
    const handleInputChange = (e) => {
        const { name, value, type } = e.target != undefined ? e.target : e;
        const isCheckbox = type === 'checkbox';

        if ((questBlock.data.id === 11 || questBlock.data.id === 14) && name.toLowerCase() === "quantity2") { //ROAD DIST Y SUPP
            if (value !== "") {
                setSelectedOthers(true);
            } else {
                setSelectedOthers(false);
                setFormSavingData(prevState => {
                    const newState = { ...prevState };
                    if ('quantity0' in newState) {
                        delete newState.quantity0;  // Elimina la clave 'quantity0'
                        document.getElementById("input-quantity0").value = "";
                    }
                    return newState;
                });
            }
        }

        setFormSavingData((prevState) => ({
            ...prevState,
            [name.toLowerCase()]: isCheckbox ? e.target.checked : value,
        }));
    }

    const handleSelectChange = (e, inputName) => {
        const { value } = e;
        if (inputName === "CONCEPT") {
            if (e.label == "Otros") {
                setSelectedOthers(true);
            } else {
                setSelectedOthers(false);
                setFormSavingData(prevState => {
                    const newState = { ...prevState };
                    if ('quantity0' in newState) {
                        delete newState.quantity0;  // Elimina la clave 'quantity0'
                        document.getElementById("input-quantity0").value = "";
                    }
                    return newState;
                });
            }
        }
        setFormSavingData((prevState) => ({
            ...prevState,
            [inputName.toLowerCase()]: value,
        }));
    }

    const handleSelectChangeSecondSelect = (e, inputName) => {
        const { value } = e;
        setFormSavingData((prevState) => ({
            ...prevState,
            [inputName.toLowerCase()]: value,
        }));
    }

    //Handle que hace la llamada para guardar la linea en BBDD
    const handlerSubmit = e => {
        e.preventDefault();
        setSubmitBtnState(true);
        formSavingData["question"] = parseInt(questBlock?.data.id, 10);
        formSavingData.registration_date = formSavingData.registration_date ? formSavingData.registration_date : defalultValueDate
        formSavingData.organizationBuilding = formSavingData.sedes != undefined ? formSavingData.sedes : formSavingData.organizationBuilding;
        delete formSavingData.sedes;

        const fetchSavedAnswer = async () => {

            const url = `${process.env.REACT_APP_CH_CALCULATION}/${idCalculation}/answer`;
            try {
                setSavedAnswer({ savedAnswer: savedAnswer.data, isFetching: true });
                const response = await axios.post(url, formSavingData, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });
                setSavedAnswer({ data: response.data, isFetching: false });

                if (response.data.success) {
                    processState(invoiceProcessStates.SUCCESS);
                    addAnswer1({data: response.data, origin: "inputs"});
                } else {
                    processState(invoiceProcessStates.SAVEKO);
                }
            } catch (exception) {
                processState(invoiceProcessStates.SAVEKO);
                setSavedAnswer({ data: [], isFetching: false });
            }
        }
        fetchSavedAnswer();
    }

    const handleModalDistanceCalculation = () => {
        setGoogleDistanceModal({ view: true, idInput: `quantity-distance-input` });
    }

    const renderTooltipDistancia = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.calcularDistancia")}
        </Tooltip>
    );

    const renderTooltipFecha = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.noDate")}
        </Tooltip>
    );

    const handleDistanceFromGoogle = (data) => {
        if (data != undefined) {
            setFormSavingData((prevState) => ({
                ...prevState,
                ["quantity"]: data.distance,
                ["destination"]: data.destinationName,
                ["origin"]: data.originName
            }));
        }
    }

    useEffect(() => {
        const currentDate = new Date();
        currentDate.setFullYear(year);
        const formattedDate = currentDate.toISOString().split('T')[0];
        setDefaultValueDate(formattedDate);
    }, [year]);

    useEffect(() => {
        const currentDate = new Date();
        currentDate.setFullYear(year);
        const formattedDate = currentDate.toISOString().split('T')[0];
        setDefaultValueDate(formattedDate);
    }, [year]);

    const [mostrarInfo, setMostrarInfo] = useState({});
    const toggleInfo = (inputId) => {
        setMostrarInfo(prevState => ({
            ...prevState,
            [inputId]: !prevState[inputId]
        }));
    };

    return (
        <div id="firstComponent" className='mb-4 mt-2'>

            <Modal show={googleDistanceModal.view}>
                <ModalHeader className='sec-neutro-background'>
                    <ModalTitle className="font-semibold sec-grey-text">{t("footPrint.calcularDistancia")}</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <GoogleDistance sendDataToParent={handleDistanceFromGoogle} idInput="quantity-distance-input" questionTitle={questBlock.data.title} />
                    </div>
                </ModalBody>
                <ModalFooter className="flex justify-center">
                    <button className="btn-sm btn-cancel rounded-lg" onClick={() => setGoogleDistanceModal({ view: false, idInput: null })}>
                        {t("fpThird.cerrar")}
                    </button>
                </ModalFooter>
            </Modal>

            <div id="footprint-form-section" >
                <div className="" >
                    <form id="footprint-form" className='' onSubmit={handlerSubmit}>
                        <div className='row justify-start'>

                            {inputDataModified?.map((inputObj, i) => {
                                const input = inputObj.input;
                                const options = input.options;


                                return (
                                    <>
                                        <div className={`col-12 col-md-${colLength + 2} col-xxl-${colLength} mb-3 text-xs`} key={input.id}
                                            style={{ display: input.title === 'Teletrabajo' ? 'none' : 'block' }}>
                                            <label className={`custom-width-md flex items-center m-0`} >
                                                <span>{input.title}</span>
                                                {inputObj.required && <span className=" pl-1 text-red-500">*</span>}

                                                {input.id == 4 &&
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipDistancia}
                                                    >
                                                        <button onClick={handleModalDistanceCalculation} type="button">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-google-maps" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M12 9.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                                                                <path d="M6.428 12.494l7.314 -9.252" />
                                                                <path d="M10.002 7.935l-2.937 -2.545" />
                                                                <path d="M17.693 6.593l-8.336 9.979" />
                                                                <path d="M17.591 6.376c.472 .907 .715 1.914 .709 2.935a7.263 7.263 0 0 1 -.72 3.18a19.085 19.085 0 0 1 -2.089 3c-.784 .933 -1.49 1.93 -2.11 2.98c-.314 .62 -.568 1.27 -.757 1.938c-.121 .36 -.277 .591 -.622 .591c-.315 0 -.463 -.136 -.626 -.593a10.595 10.595 0 0 0 -.779 -1.978a18.18 18.18 0 0 0 -1.423 -2.091c-.877 -1.184 -2.179 -2.535 -2.853 -4.071a7.077 7.077 0 0 1 -.621 -2.967a6.226 6.226 0 0 1 1.476 -4.055a6.25 6.25 0 0 1 4.811 -2.245a6.462 6.462 0 0 1 1.918 .284a6.255 6.255 0 0 1 3.686 3.092z" />
                                                            </svg>
                                                        </button>
                                                    </OverlayTrigger>}

                                                {input.id == 55 &&
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={{ show: 250, hide: 400 }}
                                                        overlay={renderTooltipFecha}
                                                    >

                                                        <svg style={{ marginBottom: '-5px' }} xmlns="http://www.w3.org/2000/svg" className="pl-1 pb-2 icon icon-tabler icon-tabler-info-circle" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                                                            <path d="M12 9h.01" />
                                                            <path d="M11 12h1v4h1" />
                                                        </svg>

                                                    </OverlayTrigger>}
                                            </label>

                                            <div>
                                                {input.inputType === 'LIST' && <div>
                                                    <Select
                                                        key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                                                        name={`${input.inputOptions}`}
                                                        className='custom-width-md text-xs '
                                                        options={options.sort((a, b) => a.name.localeCompare(b.name)).map(option => ({ label: option.name, value: option.id, hijos: option.combustible }))}
                                                        styles={customStyles}
                                                        placeholder={`${t('footPrint.selecciona')}...`}
                                                        onChange={(e) => { handleSecondSelectDisplay(e); handleSelectChange(e, input.inputOptions) }}
                                                        defaultValue={options.length === 1 ? { label: options[0].name, value: options[0].id } : (input.inputOptions == "CONCEPT" ? (firstSelectValue != undefined ? firstSelectValue : null) : (otherSelectValue != undefined ? otherSelectValue : null))}
                                                        required />
                                                </div>}

                                                {input.inputType === 'INT' &&
                                                    <div>
                                                        <input key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                                                            name={`${input.inputOptions}`}
                                                            onChange={(e) => handleInputChange(e)}
                                                            defaultValue={(input?.value != "" && input?.value != null) ? input.value : null} type="number" step="1" min="0"
                                                            className='custom-width-md  text-xs'
                                                            style={{ borderColor: 'hsl(0, 0%, 80%)' }} required />
                                                    </div>
                                                }

                                                {input.inputType === 'DOUBLE' && input.inputOptions === "QUANTITY0" &&
                                                    <div>
                                                        <input
                                                            id="input-quantity0"
                                                            key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                                                            name={`${input.inputOptions}`}
                                                            onChange={(e) => handleInputChange(e)}
                                                            defaultValue={(input?.value != "" && input?.value != null) ? input.value : null}
                                                            className={`custom-width-md  text-xs`}
                                                            style={{ borderColor: 'hsl(0, 0%, 80%)', backgroundColor: selectedOthers ? "transparent" : "#f0f0f0" }}
                                                            required={selectedOthers}
                                                            disabled={!selectedOthers}
                                                            type="number" step="0.01" min="0.00" />
                                                    </div>
                                                }

                                                {input.inputType === 'DOUBLE' && input.inputOptions !== "QUANTITY0" &&
                                                    <div>
                                                        <input key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                                                            id={`${input.id == 4 ? 'quantity-distance-input' : ''}`}
                                                            name={`${input.inputOptions}`}
                                                            onChange={(e) => handleInputChange(e)}
                                                            defaultValue={(input?.value != "" && input?.value != null) ? input.value : null}
                                                            className='custom-width-md  text-xs'
                                                            style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                            required={inputObj.required ? true : false}
                                                            type="number" step="0.01" min="0.00" />
                                                    </div>
                                                }

                                                {input.inputType === 'STRING' &&
                                                    <div>
                                                        <input
                                                            key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                                                            name={`${input.inputOptions}`}
                                                            onChange={(e) => handleInputChange(e)}
                                                            defaultValue={formSavingData[input.inputOptions.toLowerCase()] || input?.value != "" ? input.value : ""}
                                                            className='custom-width-md  text-xs'
                                                            style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                            type="text"
                                                            required={inputObj.required ? true : false}

                                                        />
                                                    </div>
                                                }


                                                {input.inputType === 'DATE' &&
                                                    <div><input
                                                        key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                                                        name={`${input.inputOptions}`}
                                                        onChange={(e) => handleInputChange(e)}
                                                        defaultValue={(input?.value != "" && input?.value != null) ? input.value : null}
                                                        className='custom-width-md  text-xs'
                                                        style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                        type="date"
                                                        required={inputObj.required ? true : false}
                                                    />
                                                    </div>
                                                }


                                                {input.inputType === 'BOOL' &&
                                                    <div className='flex flex-inline'>
                                                        <input
                                                            key={`input_${input.inputOptions}_${questBlock?.data.id}`}
                                                            name={`${input.inputOptions}`}
                                                            onChange={(e) => handleInputChange(e)}
                                                            defaultValue={(input?.value != "" && input?.value != null) ? input.value : null}
                                                            className='custom-width-md text-xs'
                                                            style={{ borderColor: 'hsl(0, 0%, 80%)' }}
                                                            type="checkbox" />
                                                    </div>
                                                }

                                            </div>

                                            {inputObj.description && <div>
                                                <button
                                                    onClick={() => toggleInfo(input.id)}
                                                    className="text-xs text-gray-500 focus:outline-none"
                                                    type="button"
                                                    style={{ fontSize: '10px' }}
                                                >
                                                    {mostrarInfo[input.id] ? t("footPrint.ocultarInfoInput") + ' ▲' : t("footPrint.mostrarInfoInput") + ' ▼'}
                                                </button>
                                                {mostrarInfo[input.id] && <div>
                                                    <label style={{ fontSize: '10px' }} className='m-0 italic'>
                                                        {inputObj.description}
                                                    </label>
                                                </div>}
                                            </div>}
                                        </div>
                                        {(i === 1 && selectedOption != null) &&
                                            <div className={`col-12 col-md-${colLength + 2} col-xxl-${colLength} mb-3 text-xs`} key="div_combustible">
                                                <label className='custom-width-md m-0'>
                                                    {selectedOption && selectedOption[0].subtitle && selectedOption.length > 0 ? selectedOption[0].subtitle : 'Tipo'}
                                                    <span className=" pl-1 text-red-500">*</span>

                                                </label>
                                                <Select
                                                    key={`input_combustible`}
                                                    name={`combustible`}
                                                    className='custom-width-md text-xs'
                                                    options={selectedOption.sort((a, b) => a.name.localeCompare(b.name)).map(combOption => ({ value: combOption.id, label: combOption.name }))}
                                                    styles={customStyles}
                                                    placeholder={`${t('footPrint.selecciona')}...`}
                                                    value={selectedValue}
                                                    onChange={(e) => {
                                                        setSelectedValue(e);
                                                        handleSelectChangeSecondSelect(e, "concept");
                                                    }}
                                                    required>
                                                </Select>
                                            </div>
                                        }
                                    </>
                                );

                            })}

                        </div >


                        <div className='flex justify-end'>
                            <button type="submit" className={`btn-sm btn-green text-xs ${!showSendButton ? "hidden" : ""}`} disabled={submitBtnState}>{submitBtnState ? <Spinner animation="border" variant="light" size="sm" /> : "Guardar"}</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
}

export default InputsComponent;