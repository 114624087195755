import axios from "axios";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export default function AgendarForm({ }) {

    const [t, i18n] = useTranslation("global");

    const [name, setName] = useState("")
    const [phone, setPhone] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [consent, setConsent] = useState(false);
    const [submittingForm, setSubmittingForm] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [formResponse, setFormResponse] = useState(undefined);


    const handleSubmit = async (e) => {
        e.preventDefault();
        gtag_report_conversion();
        setSubmittingForm(true)
        setErrorMessage(undefined);

        if (!name || !phone || !companyName) {
            setErrorMessage(t("downloads.formError"));
            setSubmittingForm(undefined)
            return;
        }
        const webhookUrl = `https://chat.googleapis.com/v1/spaces/AAAAj7B5b1c/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=doSmng6wVJNMSE3-tk0URXBqtDwRw_XLPClEykWfiio`


        const message = {
            cards: [
                {
                    header: {
                        title: "📩 Nueva solicitud de contacto",
                        subtitle: "Conoce tu huella",
                        imageUrl: "https://cdn-icons-png.flaticon.com/512/950/950237.png",
                    },
                    sections: [
                        {
                            widgets: [
                                {
                                    textParagraph: {
                                        text: `👤 Nombre: ${name}`,
                                    },
                                },
                                {
                                    textParagraph: {
                                        text: `📞 Teléfono: ${phone}`,
                                    },
                                },
                                {
                                    textParagraph: {
                                        text: `🏢 Empresa: ${companyName}`,
                                    },
                                },
                            ],
                        },
                        {
                            widgets: [
                                {
                                    buttons: [
                                        {
                                            textButton: {
                                                text: "📲 LLAMAR AHORA",
                                                onClick: {
                                                    openLink: { url: `tel:${phone}` },
                                                },
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        };

        let titleMsg = "";
        let bodyMsg = "";
        let iconMsg = "";

        try {
            const response = await axios.post(webhookUrl, message, {
                headers: { "Content-Type": "application/json" }
            });
            console.info("Mensaje enviado con éxito ✅", response.data);
            titleMsg = "¡Formulario enviado con éxito!";
            bodyMsg = "Gracias por completar el formulario. Nuestro equipo se pondrá en contacto contigo lo antes posible para darte más información sobre nuestra plataforma. ¡Esperamos hablar pronto!";
            iconMsg = "✔️";
        } catch (error) {
            console.error("Error al enviar el mensaje ❌", error);
            titleMsg = "¡Ups! Algo salió mal";
            bodyMsg = "Hubo un problema al enviar tu formulario. Por favor, intenta nuevamente más tarde o contáctanos si el problema persiste.";
            iconMsg = "❌";
        }


        setFormResponse({ title: titleMsg, body: bodyMsg, icon: iconMsg });
        setSubmittingForm(undefined)
    }



    function gtag_report_conversion(url) {
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };
        if (window.gtag) {
            window.gtag('event', 'conversion', {
                'send_to': 'AW-16840019347/6OZfCL6h3agaEJOb-d0-',
                'value': 1.0,
                'currency': 'EUR',
                'event_callback': callback
            });
        } else {
            console.error("gtag is not defined!");
        }
        return false;
    }


    return (<>
        {formResponse && <>

            <div className="font-bold text-xl mb-3"><h2>{formResponse.icon} {formResponse.title}</h2></div>
            <p>{formResponse.body}</p>

        </>}
        {!formResponse && <>
            <h2 className="text-2xl font-bold mb-4 mt-5 text-center ">¡Deja tus datos y te llamamos!</h2>

            <form onSubmit={handleSubmit} className="mb-4">
                <div className='flex flex-wrap mb-3'>
                    <div className='w-full pr-1 mt-1 mb-1'>
                        <label className="block text-sm font-medium mb-1" htmlFor="name">Nombre completo <span className="text-red-500">*</span></label>
                        <input id="name" className="form-user-data w-full bg-white" type="text" required defaultValue={''} onChange={(e) => { setName(e.target.value) }} />
                    </div>
                </div>

                <div className='flex flex-wrap mb-3'>
                    <div className='w-full pr-1 mt-1 mb-1'>
                        <label className="block text-sm font-medium mb-1" htmlFor="phone">Teléfono <span className="text-red-500">*</span></label>
                        <input id="phone" className=" w-full bg-white form-user-data" type="tel" pattern="[0-9]{7}|[0-9]{9}" defaultValue={''} required onChange={(e) => { setPhone(e.target.value) }} />
                    </div>
                </div>

                <div className='flex flex-wrap mb-3'>
                    <div className='w-full pr-1 mt-1 mb-1'>
                        <label className="block text-sm font-medium mb-1" htmlFor="companyName">Empresa <span className="text-red-500">*</span></label>
                        <input id="name" className="form-user-data w-full bg-white" type="text" required defaultValue={''} onChange={(e) => { setCompanyName(e.target.value) }} />
                    </div>
                </div>
                <div>
                    <label className="flex items-center font-medium mt-2">
                        <input type="checkbox" className="form-checkbox" required onChange={e => { setConsent(e.target.checked) }} />
                        <span className="text-sm ml-2">{t("auth.conditions")} <a href="/terminos">{t("auth.terms")}</a><span className="text-red-500" >*</span></span>
                    </label>
                </div>
                <div className=" mt-6">
                    <button disabled={submittingForm} className="btn sec-dark-green-background text-white whitespace-nowrap w-full" type="submit" >
                        {submittingForm ? <Spinner animation="border" variant="light" /> : "Solicitar llamada ahora"}
                    </button>
                    {errorMessage && <p className="text-xs mt-1" style={{ color: 'red' }}>{errorMessage}</p>}

                </div>
                <div className="text-xs pt-3 text-center">
                    ¿Tienes prisa? Llámanos directamente al <a href="tel:+34684213683" className="dark-link">+34 684 213 683</a>.
                </div>
            </form>
        </>
        }
    </>)
}