import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ModalConfirm from '../utils/ModalConfirm';

function ColumnMapping({ view, userColumns, predefinedColumns, onMappingSubmit, userFirstLineData, inputOptions, predefinedColumnsRequired, terceros }) {

  const [t, i18n] = useTranslation("global");
  const [showSpinner, setShowSpinner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [requiredFieldsNotMapped, setRequiredFieldsNotMapped] = useState([]);

  const [columnMappings, setColumnMappings] = useState(
    predefinedColumns.map(column => ({ name: column, index: null }))
  );

  const handleMappingChange = (e, index) => {
    const selectedColumn = e.target.value;
      setColumnMappings((prevState) => {
        const newMappings = [...prevState];
        newMappings[index] = JSON.parse(selectedColumn);
        return newMappings;
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Filtra los campos requeridos que no están mapeados
    const missingMappings = predefinedColumnsRequired.filter((col) =>
      !columnMappings.some((mapping) => mapping.name === col && mapping.index !== null)
    );

    if (missingMappings.length > 0) {
      setRequiredFieldsNotMapped(missingMappings.join(", "));
      setShowModal(true);
    } else {
      OnModalConfirm();
    }
  }

  const OnModalConfirm = () => {
    onMappingSubmit(columnMappings);
  }
  const OnModalCancel = () => {
    setShowModal(false);
  }

  const hanldeReset = () => {
    onMappingSubmit([]);
  }
  
  const getMatchedColumnIndex = (col) => {
    return (userColumns.findIndex(
      (userCol) => userCol !== undefined &&
        (String(userCol) === String(col) || String(userCol).includes(col) || String(col).includes(userCol))
    ))
  }

  useEffect(() => {
    predefinedColumns.forEach((col, index) => {
      const matchedColumnIndex = getMatchedColumnIndex(col);

      if (matchedColumnIndex !== -1) {
        setColumnMappings((prevState) => {
          const newMappings = [...prevState];
          newMappings[index] = { name: col, index: matchedColumnIndex };
          return newMappings;
        });
      }
    });
  }, [predefinedColumns, userColumns]);

  return (
    <div className="flex justify-center">
      <ModalConfirm show={showModal}
        onConfirm={OnModalConfirm}
        onCancel={OnModalCancel}
        title={t("footPrint.missingMappings")}
        message={<div><p>{t("footPrint.notMappingFields")} <b>{requiredFieldsNotMapped}</b>.</p>
          <p className='text-center mt-1'>{t("footPrint.wantContinue")}</p></div>}>
      </ModalConfirm>
      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        <table className={` ${terceros ? 'sec-neutro-background' : 'bg-white'} text-sm table-auto self-center`}>
          <tbody>
            {predefinedColumns.map((col, index) => {

              let matchedColumnIndex = getMatchedColumnIndex(col);
              const defaultValue = matchedColumnIndex !== -1 ? 
                JSON.stringify({
                  name: col,
                  index: matchedColumnIndex,
                })
                : "";

              return (
                <tr key={index} >
                  <td className='pt-4 pr-4'>
                    <label className=''>{col}:
                      {predefinedColumnsRequired.includes(col) && <span className="text-red-500">*</span>}
                    </label>
                  </td>
                  <td className='pt-4 pr-4'>
                    <select className='text-sm' style={{ maxWidth: '300px' }}
                      onChange={(e) => handleMappingChange(e, index)}
                      defaultValue={ defaultValue }
                    >
                      <option value={JSON.stringify({ name: col, index: null })}>{t("footPrint.cabeceraAdecuada")}</option>
                      {userColumns.map((userCol, i) => (
                        <option key={i} value={JSON.stringify({ name: col, index: i })} style={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {userCol}
                        </option>
                      ))}
                    </select>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className='flex '>
          <button className='mt-4 m-4 btn-sm btn-banner-o w-1/4' type="submit">{t("fpThird.continuar")}</button>
        </div>

      </form>
    </div >

  );
}

export default ColumnMapping;