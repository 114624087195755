import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import UserService from '../../../../services/UserService';
import CalculationsList from '../CalculationsList';

export default function CreateCertificationISO({ getNewCertification, userId }) {
    const [t, i18n] = useTranslation("global");
    const certificationType = "ISO_SIGNIFICANCE_ASSESSMENT"
    const [selectedCalculation, setSelectedCalculation] = useState();
    const [newCertification, setNewCertification] = useState({
        data: [],
        isFetching: false
    });

    const calculationsCache = useRef(null);

    const handleSetSelectedCalculation = (calculation) => {
        setSelectedCalculation(calculation)
    }

    const nextStep = () => {
        if (selectedCalculation) {
            fetchCreateCertification();
        }
    }


    const fetchCreateCertification = async () => {
        setNewCertification({ data: newCertification.data, isFetching: true });
        try {
            let body = {
                userId: userId,
                calculationId: selectedCalculation.id,
                certificationType: certificationType
            };

            const response = await axios.post(`${process.env.REACT_APP_CH_CERTIFICATION}`, body, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });

            setNewCertification({ data: response.data, isFetching: false });
            getNewCertification(response.data.data)

            let url = `/footprint/certification/${response.data.data.id}`;
            window.history.pushState({}, '', encodeURI(url));

        } catch (exception) {
            console.error(exception);
            setNewCertification({ data: [], isFetching: false });
        }
    };

    return (
        <div className={` mt-2 bg-white rounded-lg w-full h-full p-4`}
            style={{ minHeight: '400px' }}>
            <CalculationsList
                userId={userId}
                calculationsCache={calculationsCache}
                selectedCalculation={selectedCalculation}
                handleSetSelectedCalculation={handleSetSelectedCalculation}
                nextStep={nextStep}
                t={t}
                certificationType={certificationType}
            />
        </div>
    )
}