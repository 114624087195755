import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Spinner } from 'reactstrap'
import activityIndex from '../../../../../images/activityIndexImg.svg'
import { formatThousands } from '../../../utils/Utils'

export default function ActivityIndex({ calculation, totalCO2eByReportData }) {
    const [t, i18n] = useTranslation("global");
    const [totalCO2, setTotalCO2] = useState(calculation?.data?.totalKgCO2e);
    const [showSpinner, setShowSpinner] = useState(true);
    useEffect(() => {
        if (calculation?.data?.totalKgCO2e === 0 && (calculation?.data?.calculationStatus === "CURRENTYEAR" || calculation?.data.year == new Date().getFullYear() - 1)) {
            if (totalCO2eByReportData.isFetching) {
                setShowSpinner(true);
            } else {
                setTotalCO2(totalCO2eByReportData.data);
                setShowSpinner(false);
            }
        } else {
            setShowSpinner(false);
        }
    }, [calculation, totalCO2eByReportData]);

    return (
        <div className='grid-report-hightlights rounded-sm border border-gray-200 px-2 py-6 mb-4 '>
            <div className='flex flex-col items-center'>
                <img src={activityIndex} className=" flex flex-col items-center" />
            </div>

            <div className='flex flex-col text-center items-center justify-center'>
                <div>
                    {t("footPrint.indiceActividad")}
                </div>
            </div>

            <div className='flex flex-col  w-full   justify-center'>
                {showSpinner && <div className='text-center'>
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true" />
                </div>}

                {!showSpinner && <>
                    <div className='text-center mt-1 text-md '>
                        <span>
                            <span className='font-bold'> {formatThousands(totalCO2 / calculation.data.iaNumber)}  </span>
                            <span className='text-xs'>kgCO₂e /ud. {calculation.data.iaDescription.toLowerCase()}</span>
                        </span>
                        {/* <div className='w-full'>{`(${calculation.data.iaDescription})`}</div> */}
                    </div>
                </>
                }
            </div>
        </div>
    )
}