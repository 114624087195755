import { useState, useEffect } from "react";
import axios from "axios";
import { Modal, ModalBody, ModalHeader, ModalTitle, Col, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import UserService from "../../../../services/UserService";
import FootprintAlertsList from "./FootprintAlertsList";
import Sidebar from "../../Sidebar";
import Header from "../../Header";

function FootprintAlertsAdmin() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingAlertId, setEditingAlertId] = useState(null);
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    message: "",
    selectedUsers: [],
    finishDate: "",
    active: false,
  });

  useEffect(() => {
    if (usuarios.length === 0) {
      fetchAllUsers();
    }
  }, []);

  const fetchAllUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ADMIN_USERS}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${UserService.getToken()}`,
        },
      });

      let usersOptions = response.data.filter((usuario) => usuario.userType === "Representante empresarial")
        .map((user) => ({
          value: user.id,
          label: user.email,
        }));
      usersOptions.unshift({ value: "ALL", label: "ALL" });
      setUsuarios(usersOptions);
    } catch (error) {
      console.error("Error al obtener usuarios:", error);
    }
  };

  const showAlertModal = (alert = null) => {
    if (alert) {
      setFormData({
        title: alert.title,
        type: alert.type,
        message: alert.message,
        selectedUsers: alert.appliedUsers === "ALL" ? ["ALL"] : alert.appliedUsers.split(","),
        finishDate: alert.finishDate,
        active: alert.active,
      });
      setIsEditing(true);
      setEditingAlertId(alert.id);
    } else {
      setFormData({
        title: "",
        type: "",
        message: "",
        selectedUsers: [],
        finishDate: "",
        active: false,
      });
      setIsEditing(false);
      setEditingAlertId(null);
    }
    setDisplayModal(true);
  };

  const closeModal = () => {
    setDisplayModal(false);
    setIsEditing(false);
    setEditingAlertId(null);
    setFormData({
      title: "",
      type: "",
      message: "",
      selectedUsers: [],
      finishDate: "",
      active: false,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    setFormData((prev) => ({ ...prev, active: e.target.checked }));
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData((prev) => ({
      ...prev,
      selectedUsers: selectedOptions ? selectedOptions.map((opt) => opt.label) : [],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFetching(true);
    try {
      const url = isEditing
        ? `${process.env.REACT_APP_CH_ALERTS}/update/${editingAlertId}`
        : `${process.env.REACT_APP_CH_ALERTS}/new`;
      const method = isEditing ? "put" : "post";

      const alertData = {
        ...formData,
        appliedUsers: formData.selectedUsers.length > 1 ? formData.selectedUsers.join(",") : formData.selectedUsers[0],
      };

      if (editingAlertId != null) {
        alertData.id = editingAlertId;
      }

      const response = await axios({
        method,
        url,
        data: alertData,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${UserService.getToken()}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        closeModal();
      } else {
        console.error("Error al guardar la alerta");
      }
    } catch (error) {
      console.error("Error en la petición:", error.response?.data || error.message);
    } finally {
      setTriggerUpdate(!triggerUpdate);
      setFetching(false);
    }
  };

  const alertType = {
    INFO: {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="var(--airco2-green-color)"
          strokeLinecap="round"
          strokeLinejoin="round"
          width="24"
          height="24"
          strokeWidth="2"
        >
          <path d="M12 9h.01"></path>
          <path d="M11 12h1v4h1"></path>
          <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"></path>
        </svg>
      ),
      value: "INFO",
      id: "INFO",
    },
    ERROR: {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="var(--airco2-orange-color)"
          strokeLinecap="round"
          strokeLinejoin="round"
          width="24"
          height="24"
          strokeWidth="2"
        >
          <path d="M12 9v4"></path>
          <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z"></path>
          <path d="M12 16h.01"></path>
        </svg>
      ),
      value: "ERROR",
      id: "ERROR",
    },
  };

  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-2">
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">CarbonManager - Alertas</h1>
            </div>

            <div className=" mb-8">
              <div className="flex flex-col xxl:flex-row xxl:-mr-px">
                <div>
                  <div className="pr-4 sm:pr-6 lg:pr-8 w-full max-w-9xl mx-auto sec-neutro-background">
                    <div className="flex justify-end space-x-2">
                      <button
                        className="text-right text-sm btn-sm btn-banner-o"
                        onClick={() => showAlertModal()}
                      >
                        Nueva alerta
                      </button>
                    </div>
                  </div>

                  {/* Content */}
                  <div className=" mb-8">
                    <div className="flex flex-col xxl:flex-row xxl:-mr-px p-4">
                      <div>
                        <Modal show={displayModal}>
                          <ModalHeader className="sec-green-background flex justify-between">
                            <ModalTitle>{isEditing ? "Editar alerta" : "Crear nueva alerta"}</ModalTitle>
                            <button onClick={closeModal}>
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path d="M18 6L6 18" />
                                <path d="M6 6L18 18" />
                              </svg>
                            </button>
                          </ModalHeader>
                          <ModalBody>
                            <form onSubmit={handleSubmit} className="space-y-4">
                              <Row>
                                <Col md="6">
                                  <label className="text-sm">Título</label>
                                  <input
                                    className="text-sm w-full"
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleInputChange}
                                    required
                                  />
                                </Col>
                                <Col md="6">
                                  <label className="text-sm">Tipo</label>
                                  <select
                                    className="text-sm w-full"
                                    name="type"
                                    value={formData.type}
                                    onChange={handleInputChange}
                                    required
                                  >
                                    <option value="" disabled>
                                      Selecciona
                                    </option>
                                    {Object.values(alertType).map((x) => (
                                      <option key={x.id} value={x.value}>
                                        {x.value}
                                      </option>
                                    ))}
                                  </select>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label className="text-sm">Mensaje</label>
                                  <textarea
                                    className="text-sm w-full"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    required
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <label className="text-sm">Usuarios</label>
                                  <Select
                                    isMulti
                                    className="custom-width-md text-sm"
                                    options={usuarios}
                                    value={usuarios.filter((user) =>
                                      formData.selectedUsers.includes(user.label)
                                    )}
                                    onChange={handleSelectChange}
                                    placeholder="Selecciona"
                                    required
                                  />
                                </Col>
                                <Col>
                                  <label className="text-sm">Fecha fin</label>
                                  <input
                                    className="text-sm w-full"
                                    type="datetime-local"
                                    name="finishDate"
                                    value={formData.finishDate ? formData.finishDate.split('T')[0] + 'T' + formData.finishDate.split('T')[1].substring(0, 5) : ''}
                                    onChange={handleInputChange}
                                    required
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <label className="text-sm flex items-center space-x-2">
                                    <input
                                      type="checkbox"
                                      checked={formData.active}
                                      onChange={handleCheckboxChange}
                                    />
                                    <span>Activa</span>
                                  </label>
                                  <p className="text-xs text-gray-500 mt-1">
                                    ⚠️ Aunque la alerta esté activa, si la fecha de
                                    finalización ya ha pasado, no se mostrará.
                                  </p>
                                </Col>
                              </Row>
                              <div className="flex flex-col justify-center space-y-2">
                                <span>Preview:</span>
                                <div className="max-w-md bg-white shadow-lg rounded-lg flex">
                                  <div className="flex-1 w-0 p-4">
                                    <div className="flex items-start">
                                      <div className="ml-3 flex-1">
                                        <div className="flex gap-2">
                                          {alertType[formData.type]?.icon}
                                          <p className="text-md font-semibold text-gray-900">
                                            {formData.title}
                                          </p>
                                        </div>
                                        <p className="mt-1 text-sm text-gray-500">
                                          {formData.message}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pt-2 pr-2">
                                    <div>
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        width="24"
                                        height="24"
                                        strokeWidth="2"
                                      >
                                        <path d="M18 6l-12 12"></path>
                                        <path d="M6 6l12 12"></path>
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="flex justify-center m-2 mt-4">
                                <button type="submit" className="btn-sm btn-green" disabled={isFetching}>
                                  <div className="flex gap-1"></div>
                                  <span>{isEditing ? "Actualizar" : "Crear"}</span>
                                  {isFetching &&
                                    <Spinner animation="border" size="sm" variant="light" />}
                                </button>
                              </div>
                            </form>
                          </ModalBody>
                        </Modal>

                        <FootprintAlertsList onEditAlert={showAlertModal} updateList={triggerUpdate}></FootprintAlertsList>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
export default FootprintAlertsAdmin;
