import { useLocation } from "react-router-dom";
import axios from "axios";
import UserService from "../../../services/UserService";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-hot-toast";

const AlertBanner = () => {
    const [lastAlerts, setLastAlerts] = useState([]);
    const location = useLocation();
    const lastAlertsRef = useRef([]);

    const printIcon = (type) => {
        const icons = {
            INFO: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="var(--airco2-green-color)" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="2">
                    <path d="M12 9h.01"></path>
                    <path d="M11 12h1v4h1"></path>
                    <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z"></path>
                </svg> 
            ),
            ERROR: (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="var(--airco2-orange-color)" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="2">
                    <path d="M12 9v4"></path>
                    <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z"></path>
                    <path d="M12 16h.01"></path>
                </svg> 
            ),
        };
        return icons[type] || null;
    };

    useEffect(() => {
        if (!location.pathname.startsWith("/footprint")) {
            return;
        }

        const fetchAlerts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_CH_ALERTS}/active`, {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${UserService.getToken()}`
                    }
                });

                if (!response.data || !Array.isArray(response.data.data) || response.data.data.length === 0) {
                    return;
                }

                let newAlerts = response.data.data;

                let uniqueAlerts = newAlerts.filter(
                    (alert) => !lastAlertsRef.current.some((prevAlert) => areAlertsEqual(alert, prevAlert))
                );

                if (uniqueAlerts.length > 0) {
                    lastAlertsRef.current = [...lastAlertsRef.current, ...uniqueAlerts];
                    setLastAlerts([...lastAlertsRef.current]);

                    uniqueAlerts.forEach((alert) => {
                        toast.custom((t) => (
                            <div className={` ${t.visible ? 'animate-enter' : 'animate-leave'} max-w-md bg-white shadow-lg rounded-lg pointer-events-auto flex`}>
                                <div className="flex-1 p-4">
                                    <div className="flex items-start">
                                        <div className="ml-3 flex-1">
                                            <div className="flex gap-2">
                                                {printIcon(alert.type)}
                                                <p className="text-md font-semibold text-gray-900">{alert.title}</p>
                                            </div>
                                            <p className="mt-1 text-sm text-gray-500">{alert.message}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-2 pr-2">
                                    <button onClick={() => { 
                                        toast.dismiss(t.id); 
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="2">
                                            <path d="M18 6l-12 12"></path>
                                            <path d="M6 6l12 12"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        ), {
                            duration: Infinity,
                            position: "top-right",
                        });
                    });
                }
            } catch (error) {
                console.error("Error al obtener alertas:", error);
            }
        };

        fetchAlerts();
        //30 min
        const interval = setInterval(fetchAlerts, 1800000);

        return () => clearInterval(interval);
    }, [location.pathname, lastAlerts]);

    const areAlertsEqual = (alert1, alert2) => {
        return (
            alert1.id === alert2.id &&
            alert1.message === alert2.message &&
            alert1.title === alert2.title
        );
    };

    return null;
};

export default AlertBanner;