import { useMemo } from "react";

export default function QuestionsList({ externalQuestionnaire, questions, onQuestionClick, updatedShowAnswerTable, t }) {


    const extraText = useMemo(() => {
        if (!questions) {
            return "";
        }

        const questionIds = questions.map((v) => v.id);

        let extraText = "";
        if (questionIds?.length === 1) {
            if (questionIds.includes(3)) {
                extraText = t("fpThird.comoDistribuidor") + externalQuestionnaire.organization;
            } else if (questionIds.includes(4)) {
                extraText = t("fpThird.comoProveedor") + externalQuestionnaire.organization;
            }
        } else if (questionIds?.length === 2 && questionIds?.includes(3) && questionIds?.includes(4)) {
            extraText = t("fpThird.comoDistribuidorProveedor") + externalQuestionnaire.organization;
        }
        return extraText;
    }, [questions]);


    return (<>
        <div className="max-w-3xl m-auto">
            <p className='mb-3'>
                {t("fpThird.en")}  <b> {externalQuestionnaire.organization} </b> {t("fpThird.bienvenidaTerceros1")}
            </p>
            <p>
                {t("fpThird.bienvenidaTerceros2") + extraText + t("fpThird.bienvenidaTerceros3")}
            </p>

            <ul className='mt-3 flex flex-col gap-3 pl-3 font-semibold pt-3 ' style={{ listStyleType: 'decimal' }}>
                {questions.map((x, y) => (
                    <li key={y}>
                        <div className="flex justify-between items-center">
                            <span
                                className="linkhover col-10"
                                onClick={() => { onQuestionClick(x, true, y); updatedShowAnswerTable(false); }}
                                style={{ cursor: "pointer" }}
                            >
                                {x.name}
                            </span>
                            {x.answersByContact > 0 && (
                                <span
                                    className="text-sm linkhover text-xs"
                                    onClick={() => {
                                        onQuestionClick(x, true, y);
                                        updatedShowAnswerTable(true);
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    ({x.answersByContact}{" "}
                                    {t("fpThird.respuestas").toLowerCase()})
                                </span>
                            )}
                        </div>
                    </li>
                ))}

            </ul>
            <div className='flex justify-center gap-4' style={{ marginTop: '4rem' }}>
                <button className="btn-sm btn-green text-md" type="submit" onClick={() => onQuestionClick(questions[0], true, 0)}>{t("fpThird.responder")}</button>
            </div>
        </div>
    </>);

}