import axios from "axios";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

export default function DownloadForm({ downloadSlug, lng, dowloadType }) {

    const [t, i18n] = useTranslation("global");

    const [name, setName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [consent, setConsent] = useState(false);
    const [submittingForm, setSubmittingForm] = useState(false);

    const [errorCorporateEmail, setErrorCorporateEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [fileResponse, setFileResponse] = useState(undefined);


    const blockedDomains = [
        'gmail.com', 'hotmail.com', 'outlook.com', 'yahoo.com', 'icloud.com',
        'live.com', 'aol.com', 'protonmail.com', 'zoho.com', 'gmx.com',
        'mail.com', 'yandex.com', 'fastmail.com', 'hushmail.com', 'tutanota.com',
        'me.com', 'mac.com', 'msn.com', 'qq.com', 'ymail.com', 'rocketmail.com',
        'email.com', 'inbox.com', 'rediffmail.com', 'lycos.com', 'excite.com',
        'juno.com', 'lavabit.com', 'mail.ru', 'runbox.com', 'post.com', 'usa.com',
        'bigpond.com', 'btinternet.com', 'charter.net', 'comcast.net', 'cox.net',
        'earthlink.net', 'verizon.net', 'bellsouth.net', 'sbcglobal.net', 'att.net',
        'shaw.ca', 'rogers.com', 'telus.net', 'optonline.net', 'sympatico.ca',
        'netscape.net', 'blueyonder.co.uk', 'virgin.net', 'ntlworld.com',
        'tiscali.co.uk', 'talktalk.net', 'sky.com',
        '10minutemail.com', 'tempmail.net', 'mailinator.com', 'guerrillamail.com',
        'trashmail.com', 'dispostable.com', 'yopmail.com', 'sharklasers.com',
        'fakeinbox.com', 'throwawaymail.com', 'edectus.com'
    ];

    const isCorporateEmail = (email) => {
        const domain = email.split('@')[1];
        return !blockedDomains.includes(domain);
    };

    const handleChangeEmail = (value) => {
        if (value && !isCorporateEmail(value)) {
            setErrorCorporateEmail(t("downloads.errorCorporateEmail"));
            setEmail(undefined);
        } else {
            setErrorCorporateEmail(undefined);
            setEmail(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmittingForm(true)
        setErrorMessage(undefined);

        if (!name || !lastName || !email || !phone || !companyName) {
            setErrorMessage(t("downloads.formError"));
            setSubmittingForm(undefined)
            return;
        }
        const url = `${process.env.REACT_APP_PUBLIC_DOWNLOAD}/file?lng=${lng}`
        const response = await axios.post(`${url}`,
            JSON.stringify({
                name,
                lastName,
                email,
                phone,
                companyName,
                downloadSlug
            }),
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            });
        if (response.status = 200) {
            handleOpenLink(response.data.data.downloadFile);
            setFileResponse(response.data.data.downloadFile);
            sendChatMessage(response.data.data.downloadTitle)
        }
        setSubmittingForm(undefined)
    }

    const handleOpenLink = (link) => {
        window.open(link, "_blank", "noopener,noreferrer");
    };


    const sendChatMessage = async (downloadTitle) => {
        const webhookUrl = `https://chat.googleapis.com/v1/spaces/AAAAj7B5b1c/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=doSmng6wVJNMSE3-tk0URXBqtDwRw_XLPClEykWfiio`


        const message = {
            cards: [
                {
                    header: {
                        title: "📩 Descarga recursos",
                        subtitle: downloadTitle,
                        imageUrl: "https://cdn-icons-png.flaticon.com/512/950/950237.png",
                    },
                    sections: [
                        {
                            widgets: [
                                {
                                    textParagraph: {
                                        text: `👤 Nombre: ${name} ${lastName}`,
                                    },
                                },
                                {
                                    textParagraph: {
                                        text: `📞 Teléfono: ${phone}`,
                                    },
                                },
                                {
                                    textParagraph: {
                                        text: `📧 Email: ${email}`,
                                    },
                                },
                                {
                                    textParagraph: {
                                        text: `🏢 Empresa: ${companyName}`,
                                    },
                                },
                            ],
                        },
                        {
                            widgets: [
                                {
                                    buttons: [
                                        {
                                            textButton: {
                                                text: "📲 LLAMAR AHORA",
                                                onClick: {
                                                    openLink: { url: `tel:${phone}` },
                                                },
                                            },
                                        },
                                        {
                                            textButton: {
                                                text: "📨 ENVIAR EMAIL AHORA",
                                                onClick: {
                                                    openLink: { url: `mailto:${email}` },
                                                },
                                            },
                                        }
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        };

        try {
            const response = await axios.post(webhookUrl, message, {
                headers: { "Content-Type": "application/json" }
            });
            console.info("Mensaje enviado con éxito ✅", response.data);
        } catch (error) {
            console.error("Error al enviar el mensaje ❌", error);
        }
    }

    return (<>
        {fileResponse && <>
            <div className="font-bold text-center">{t("downloads.downloadFileSuccess")}</div>
            <div className="mt-4 text-sm">{t("downloads.openHere")}</div>
            <button onClick={() => handleOpenLink(fileResponse)} className="mt-4 btn sec-green-background text-white whitespace-nowrap w-full">
                {`${t("downloads.open")} ${dowloadType}`}
            </button>
        </>}
        {!fileResponse &&
            <form onSubmit={handleSubmit}>
                <div className='flex flex-wrap'>
                    <div className='w-full pr-1 mt-1 mb-1'>
                        <label className="block text-sm font-medium mb-1" htmlFor="name">{t("downloads.name")} <span className="text-red-500">*</span></label>
                        <input id="name" className="form-user-data w-full bg-white" type="text" required defaultValue={''} onChange={(e) => { setName(e.target.value) }} />
                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className='w-full pr-1 mt-1 mb-1'>
                        <label className="block text-sm font-medium mb-1" htmlFor="lastName">{t("downloads.lastName")} <span className="text-red-500">*</span></label>
                        <input id="name" className="form-user-data w-full bg-white" type="text" required defaultValue={''} onChange={(e) => { setLastName(e.target.value) }} />
                    </div>
                </div>

                <div className='flex flex-wrap'>
                    <div className='w-full pr-1 mt-1 mb-1'>
                        <label className="block text-sm font-medium mb-1" htmlFor="phone">{t("downloads.phone")}<span className="text-red-500">*</span></label>
                        <input id="phone" className=" w-full bg-white form-user-data" type="tel" pattern="[0-9]{7}|[0-9]{9}" defaultValue={''} required onChange={(e) => { setPhone(e.target.value) }} />
                    </div>
                </div>
                <div className='flex flex-wrap  '>
                    <div className='w-full pr-1 mt-1 mb-1 '>
                        <label className="block text-sm font-medium mb-1" htmlFor="email">{t("downloads.email")}<span className="text-red-500">*</span></label>
                        <input id="email" className=" w-full bg-white form-user-data" type="email" defaultValue={''} required onChange={(e) => { handleChangeEmail(e.target.value) }} />
                        {errorCorporateEmail && <p className="text-xs mt-1" style={{ color: 'red' }}>{errorCorporateEmail}</p>}

                    </div>
                </div>
                <div className='flex flex-wrap'>
                    <div className='w-full pr-1 mt-1 mb-1'>
                        <label className="block text-sm font-medium mb-1" htmlFor="companyName">{t("downloads.companyName")} <span className="text-red-500">*</span></label>
                        <input id="name" className="form-user-data w-full bg-white" type="text" required defaultValue={''} onChange={(e) => { setCompanyName(e.target.value) }} />
                    </div>
                </div>
                <div>
                    <label className="flex items-center font-medium mt-2">
                        <input type="checkbox" className="form-checkbox" required onChange={e => { setConsent(e.target.checked) }} />
                        <span className="text-sm ml-2">{t("auth.conditions")} <a href="/terminos">{t("auth.terms")}</a><span className="text-red-500" >*</span></span>
                    </label>
                </div>
                <div className=" mt-6">
                    <button disabled={submittingForm} className="btn sec-green-background text-white whitespace-nowrap w-full" type="submit" >
                        {submittingForm ? <Spinner animation="border" variant="light" /> : t("downloads.download")}
                    </button>
                    {errorMessage && <p className="text-xs mt-1" style={{ color: 'red' }}>{errorMessage}</p>}

                </div>
            </form>
        }
    </>)
}