import axios from "axios";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import UserService from "../../../../services/UserService";
import FilterButton from "../../utils/DropdownFilterOrders";
import { formatDate } from "../../utils/Utils";

function FootprintCalculationList() {
  const navigate = useNavigate();

  const [subscriptions, setSubscriptions] = useState({
    data: { data: [] },
    isFetching: false,
  });

  const [susbscriptionOrigin, setSusbscriptionOrigin] = useState({
    data: { data: [] },
    isFetching: false,
  });

  const [uniqueStatusList, setUniqueStatusList] = useState([]);
  const [statusList, setStatusList] = useState([]);



  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CH_SUBSCRIPTION}s`,
          {
            headers: {
              // "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          }
        );

        setSubscriptions({ data: response.data, isFetching: true });
        setSusbscriptionOrigin({ data: response.data, isFetching: true });

        //Filtro Estados
        response.data?.data.map((v) => {
          if (uniqueStatusList.indexOf(v.stripeStatus) === -1) {
            uniqueStatusList.push(v.stripeStatus);
          }
        });
        setUniqueStatusList(uniqueStatusList.sort());
        uniqueStatusList.map((v) => {
          statusList.push({
            state: v,
            check: false,
          });
        });
        setStatusList(statusList);

      } catch (exception) {
        console.error(exception);
        setSubscriptions({ data: { data: [] }, isFetching: true });
      }
    };
    fetchSubscriptions();
  }, []);

  const applyStatusFilter = (data) => {
    setFiltros((prevFiltros) => ({
      ...prevFiltros,
      status: data,
    }));
  };

  const [filtros, setFiltros] = useState({
    status: [],
    userName: "",
    userEmail: ""
  });

  useEffect(() => {
    const handleInputChange = () => {

      let filteredData = susbscriptionOrigin.data?.data;

      if (filtros.status) {
        filteredData = OnFilterStatus(filtros.status, filteredData);
      }

      if (filtros.userName) {
        filteredData = OnFilterUsuario(filtros.userName, filteredData);
      }

      if (filtros.userEmail) {
        filteredData = OnFilterEmail(filtros.userEmail, filteredData);
      }

      setSubscriptions({ data: { data: filteredData }, isFetching: true });
    };
    handleInputChange();
  }, [filtros]);



  const OnFilterUsuario = (filter, filteredData) => {
    return filteredData.filter((v) =>

      (v.userName + " " + v.userLastName)?.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const OnFilterEmail = (filter, filteredData) => {
    return filteredData.filter((v) =>
      v.userEmail?.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const OnFilterStatus = (data, filteredData) => {
    let states = data.filter((c) => c.check).map((c) => c.state);

    if (states.length > 0) {
      return filteredData.filter((c) => states.includes(c.stripeStatus));
    }
    return filteredData;
  };


  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);
    const sortedItems = useMemo(() => {
      let sortableItems = [...items];

      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          let valueA = a[sortConfig.key];
          let valueB = b[sortConfig.key];

          if (sortConfig.key.includes(".")) {
            let params = sortConfig.key.split(".");
            valueA = a[params[0]]?.[params[1]];
            valueB = b[params[0]]?.[params[1]];
          }

          if (valueA === null || valueA === undefined || valueA === "") {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }

          if (valueB === null || valueB === undefined || valueB === "") {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }

          if (!isNaN(valueA) || moment(valueA).isValid()) {
            if (valueA < valueB) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (valueA > valueB) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          } else {
            if (
              valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (
              valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }

          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(
    subscriptions.data?.data
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  //BGcolor para los estados de pagos
  const statusColor = (props) => {
    switch (props) {
      case "active":
        return "bg-green-100 text-green-600";
      case "canceled":
        return "bg-red-100 text-red-600";
    }
  };


  return (
    <>

      {!subscriptions.isFetching && (
        <Spinner animation="border" variant="dark" />
      )}

      {subscriptions.isFetching && subscriptions.data.data != undefined && (
        <>
          <div className="p-3 bg-white mb-3">
            {/* <div className="mb-2 font-bold">
              <h2 className="font-bold">Filtros</h2>
            </div> */}
            <div className="grid gap-2 md:grid-cols-3">
              <div>
                <label
                  className="block text-xs font-semibold mb-1 text-airco2-general"
                  htmlFor="default"
                >
                  Usuario
                </label>
                <Input
                  value={filtros.userName}
                  className="w-full text-xs border-gray-200 hover:border-gray-300"
                  onChange={(e) =>
                    setFiltros((prevFiltros) => ({
                      ...prevFiltros,
                      userName: e.target.value,
                    }))
                  }
                  placeholder="Busca por usuario"
                  type="text"
                />
              </div>
              <div>
                <label
                  className="block text-xs font-semibold mb-1 text-airco2-general"
                  htmlFor="default"
                >
                  Email
                </label>
                <Input
                  value={filtros.userEmail}
                  className="w-full text-xs border-gray-200 hover:border-gray-300"
                  onChange={(e) =>
                    setFiltros((prevFiltros) => ({
                      ...prevFiltros,
                      userEmail: e.target.value,
                    }))
                  }
                  placeholder="Busca por email"
                  type="text"
                />
              </div>
              <div className="w-full rounded-lg mt-4">
                <FilterButton
                  statusPaymentUniq={uniqueStatusList}
                  stateCheck={statusList}
                  applyStatePaymentFilter={applyStatusFilter}
                  nombreFiltro={"Estado"}
                />
              </div>

            </div>
          </div>
          <div className="text-xs font-medium text-right">
            Resultados: {subscriptions.data?.data.length}
          </div>
          {subscriptions.data?.data.length == 0 ? <div className="flex justify-center">Cargando suscripciones&nbsp;<Spinner animation="border" variant="dark" /></div> :
            <div className="container-simple-table mt-2" style={{ height: "800px", overflowY: "scroll" }}>
              <table className="simple-table-sm">
                <thead className="text-center text-white sec-green-background sticky top-0 z-20">
                  <tr>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("id")}
                        className={`${getClassNamesFor("id")} `}
                      >
                        Id
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("userName")}
                        className={`${getClassNamesFor("userName")} `}
                      >
                        Usuario
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("userEmail")}
                        className={`${getClassNamesFor("userEmail")} `}
                      >
                        Email
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("creationDate")}
                        className={`${getClassNamesFor(
                          "creationDate"
                        )} `}
                      >
                        Fecha creación
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("stripeStatus")}
                        className={`${getClassNamesFor(
                          "stripeStatus"
                        )} `}
                      >
                        Estado
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("price")}
                        className={`${getClassNamesFor("price")} `}
                      >
                        Precio
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("priceWTax")}
                        className={`${getClassNamesFor(
                          "priceWTax"
                        )} `}
                      >
                        Precio con IVA
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("expirationDate")}
                        className={`${getClassNamesFor(
                          "expirationDate"
                        )} `}
                      >
                        Fecha cancelación
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("cancelationDate")}
                        className={`${getClassNamesFor(
                          "cancelationDate"
                        )} `}
                      >
                        Fecha expiración
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("planName")}
                        className={`${getClassNamesFor(
                          "planName"
                        )} `}
                      >
                        Plan
                      </button>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {items.map((p, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <td>{p.id}</td>
                          <td>{p.userName + " " + p.userLastName}</td>
                          <td>{p.userEmail}</td>
                          <td>{p.creationDate && formatDate(p.creationDate)}</td>
                          <td><p className={`text-center rounded-full py-0.5 px-2 ${statusColor(p.stripeStatus)}`}>{p.stripeStatus}</p></td>
                          <td>{p.price}</td>
                          <td>{p.priceWTax}</td>
                          <td>{p.expirationDate && formatDate(p.expirationDate)}</td>
                          <td>{p.cancelationDate && formatDate(p.cancelationDate)}</td>
                          <td>{p.planName}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>}
        </>
      )}
    </>
  );
}
export default FootprintCalculationList;
