import axios from "axios";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import UserService from "../../../../../services/UserService";
import FilterButton from "../../../utils/DropdownFilterOrders";
import { formatDateTime, formatThousands } from "../../../utils/Utils";
import { useTranslation } from "react-i18next";
import ModalUpdateCertification from './ModalUpdateCertification';
import ModalCertificationDocumentaion from './ModalCertificationDocumentaion';

function FootprintCertificationList() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  const [certifications, setCertifications] = useState({
    data: { data: [] },
    isFetching: false,
  });
  const [certificationsOrigin, setCertificationsOrigin] = useState({
    data: { data: [] },
    isFetching: false,
  });

  const [uniqueCertificationStatusList, setUniqueCertificationStatusList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [uniqueCertifficationTypesList, setUniqueCertifficationTypesList] = useState([]);
  const [typesList, setTypesList] = useState([]);
  const [uniqueCertificationYearList, setUniqueCertificationYearList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [modalCertification, setModalCertification] = useState({
    view: false,
    certification: null,
  });
  const [modalDocumentation, setModalDocumentation] = useState({
    view: false,
    certification: null,
  });

    

  useEffect(() => {
    const fetchCertifications = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CH_CERTIFICATIONS}`,
          {
            headers: {
              // "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          }
        );
        setCertifications({ data: response.data, isFetching: true });
        setCertificationsOrigin({ data: response.data, isFetching: true });
        //Filtro Estados
        response.data?.data.map((v) => {
          if (uniqueCertificationStatusList.indexOf(v.certificationMitecoStatus) === -1) {
            uniqueCertificationStatusList.push(v.certificationMitecoStatus);
          }
        });
        setUniqueCertificationStatusList(uniqueCertificationStatusList.sort());
        uniqueCertificationStatusList.map((v) => {
          statusList.push({
            state: v,
            check: false,
          });
        });
        setStatusList(statusList);

        //Filtro tipo
        response.data?.data.map((v) => {
          if (
            uniqueCertifficationTypesList.indexOf(v.certificationType) === -1
          ) {
            if (v.certificationType !== undefined) {
              uniqueCertifficationTypesList.push(v.certificationType);
            }
          }
        });
        setUniqueCertifficationTypesList(uniqueCertifficationTypesList.sort());
        uniqueCertifficationTypesList.map((v) => {
          typesList.push({
            state: v,
            check: false,
          });
        });
        setTypesList(typesList);

        //Filtro año
        response.data?.data.map((v) => {
          if (uniqueCertificationYearList.indexOf(v.calculationYear) === -1) {
            if (v.calculationYear !== undefined) {
              uniqueCertificationYearList.push(v.calculationYear);
            }
          }
        });
        setUniqueCertificationYearList(uniqueCertificationYearList.sort());
        uniqueCertificationYearList.map((v) => {
          yearList.push({
            state: v.toString(),
            check: false,
          });
        });
        setYearList(yearList);
      } catch (exception) {
        console.error(exception);
        setCertifications({ data: { data: [] }, isFetching: true });
      }
    };
    fetchCertifications();
  }, []);

  const applyStatusFilter = (data) => {
    setFiltros((prevFiltros) => ({
      ...prevFiltros,
      status: data,
    }));
  };

  const applyTypeFilter = (data) => {
    setFiltros((prevFiltros) => ({
      ...prevFiltros,
      types: data,
    }));
  };

  const applyYearFilter = (data) => {
    setFiltros((prevFiltros) => ({
      ...prevFiltros,
      year: data,
    }));
  };

  const [filtros, setFiltros] = useState({
    status: [],
    types: [],
    year: [],
    organization: "",
    userId: "",
  });

  useEffect(() => {
    const handleInputChange = () => {
      let filteredData = certificationsOrigin.data?.data;

      if (filtros.status) {
        filteredData = OnFilterStatus(filtros.status, filteredData);
      }

      if (filtros.types) {
        filteredData = OnFilterTypes(filtros.types, filteredData);
      }

      if (filtros.year) {
        filteredData = OnFilterYear(filtros.year, filteredData);
      }

      if (filtros.organization) {
        filteredData = OnfilterOrganization(filtros.organization, filteredData);
      }

      if (filtros.userId) {
        filteredData = OnFilterUsuario(filtros.userId, filteredData);
      }

      setCertifications({ data: { data: filteredData }, isFetching: true });
    };
    handleInputChange();
  }, [filtros]);

  const OnfilterOrganization = (filter, filteredData) => {
    return filteredData.filter((v) =>
      v.organizationName?.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const OnFilterUsuario = (filter, filteredData) => {
    return filteredData.filter(
      (v) =>
        v.userId?.toLowerCase().includes(filter.toLowerCase()) ||
        v.userEmail?.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const OnFilterStatus = (data, filteredData) => {
    let states = data.filter((c) => c.check).map((c) => c.state);

    if (states.length > 0) {
      return filteredData.filter((c) => states.includes(c.calculationStatus));
    }
    return filteredData;
  };

  const OnFilterTypes = (data, filteredData) => {
    let states = data.filter((c) => c.check).map((c) => c.state);

    if (states.length > 0) {
      return filteredData.filter((c) => states.includes(c.certificationType));
    }
    return filteredData;
  };

  const OnFilterYear = (data, filteredData) => {
    let states = data.filter((c) => c.check).map((c) => c.state);

    if (states.length > 0) {
      return filteredData.filter((c) => states.includes(c.calculationYear.toString()));
    }
    return filteredData;
  };

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);
    const sortedItems = useMemo(() => {
      let sortableItems = [...items];

      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          let valueA = a[sortConfig.key];
          let valueB = b[sortConfig.key];

          if (sortConfig.key.includes(".")) {
            let params = sortConfig.key.split(".");
            valueA = a[params[0]]?.[params[1]];
            valueB = b[params[0]]?.[params[1]];
          }

          if (valueA === null || valueA === undefined || valueA === "") {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }

          if (valueB === null || valueB === undefined || valueB === "") {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }

          if (!isNaN(valueA) || moment(valueA).isValid()) {
            if (valueA < valueB) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (valueA > valueB) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          } else {
            if (
              valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (
              valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }

          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(
    certifications.data?.data
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const statusColor = (props) => {
    switch (props) {
      case "CREATED":
        return "bg-gray-100 text-gray-500";
      case "SENT":
        return "bg-yellow-500 text-grey-100";
      case "ACCEPTED":
        return "bg-green-100 text-green-500";
      case "COMPLETED":
        return "bg-green-100 text-green-600";
    }
  };

  const updateCertificationData = (certification) => {

    setCertifications((prevState) => {
      const indexToUpdate = prevState.data.data.findIndex(
        (record) => record.id === certification.id
      );

      if (indexToUpdate !== -1) {
        const updatedData = [...prevState.data.data];
        updatedData[indexToUpdate] = certification;
        return {
          ...prevState,
          data: { ...prevState.data, data: updatedData },
          isFetching: true,
        };
      }
      return prevState;

    });

    setCertificationsOrigin((prevState) => {
      const indexToUpdate = prevState.data.data.findIndex(
        (record) => record.id === certification.id
      );

      if (indexToUpdate !== -1) {
        const updatedData = [...prevState.data.data];
        updatedData[indexToUpdate] = certification;
        return {
          ...prevState,
          data: { ...prevState.data, data: updatedData },
          isFetching: true,
        };
      }
      return prevState;

    });
  };

  const [zipSpinner, setZipSpinner] = useState();

  const getZipDocumentation = (certification) => {
    setZipSpinner(certification.id);
    const fetchCertificationDocumentationZip = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CH_CERTIFICATION}/miteco/${certification.id}/documentationZip`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
            responseType: 'blob'  // Specify that you want a blob response
          }
        );

        // Create a blob URL for the downloaded file
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create an anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = certification.certificationType + "_" + certification.organizationName + "_" + certification.calculationYear + '.zip';  // Set the filename
        document.body.appendChild(a);
        a.click();
        a.remove();  // Clean up after the download

        // Clean up the blob URL
        window.URL.revokeObjectURL(url);
      } catch (exception) {
        console.error('Error downloading ZIP file:', exception);
      } finally {
        setZipSpinner();
      }
    };

    fetchCertificationDocumentationZip();
  };




  return (
    <>

      <ModalUpdateCertification
        modalCertification={modalCertification}
        setModalCertification={setModalCertification}
        updateCertificationData={updateCertificationData}
      />

      <ModalCertificationDocumentaion
        modalDocumentation={modalDocumentation}
        setModalDocumentation={setModalDocumentation}
      />

      {!certifications.isFetching && (
        <Spinner animation="border" variant="dark" />
      )}

      {certifications.isFetching && certifications.data.data != undefined && (
        <>
          <div className="p-3 bg-white mb-3">
            {/* <div className="mb-2 font-bold">
              <h2 className="font-bold">Filtros</h2>
            </div> */}
            <div className="grid gap-2 md:grid-cols-3">
              <div>
                <label
                  className="block text-xs font-semibold mb-1 text-airco2-general"
                  htmlFor="default"
                >
                  Usuario
                </label>
                <Input
                  value={filtros.userId}
                  className="w-full text-xs border-gray-200 hover:border-gray-300"
                  onChange={(e) =>
                    setFiltros((prevFiltros) => ({
                      ...prevFiltros,
                      userId: e.target.value,
                    }))
                  }
                  placeholder="Busca por usuario"
                  type="text"
                />
              </div>

              <div>
                <label
                  className="block text-xs font-semibold mb-1 text-airco2-general"
                  htmlFor="default"
                >
                  Organización
                </label>
                <Input
                  value={filtros.organization}
                  className="w-full text-xs border-gray-200 hover:border-gray-300"
                  onChange={(e) =>
                    setFiltros((prevFiltros) => ({
                      ...prevFiltros,
                      organization: e.target.value,
                    }))
                  }
                  placeholder="Busca por empresa"
                  type="text"
                />
              </div>

              <div className="flex items-end">
                <div className="w-full rounded-lg">
                  <FilterButton
                    statusPaymentUniq={uniqueCertificationStatusList}
                    stateCheck={statusList}
                    applyStatePaymentFilter={applyStatusFilter}
                    nombreFiltro={"Estado"}
                  />
                </div>
                <label className="block text-xs font-medium mb-1">
                  {filtros.status.filter((x) => x.check).length > 0 && (
                    <span className="ml-2">
                      Estado:{" "}
                      {filtros.status
                        .filter((x) => x.check)
                        .map((x) => x.state)
                        .join(", ")}
                    </span>
                  )}
                </label>
              </div>

              <div>
                <div className="w-full rounded-lg ">
                  <FilterButton
                    statusPaymentUniq={uniqueCertifficationTypesList}
                    stateCheck={typesList}
                    applyStatePaymentFilter={applyTypeFilter}
                    nombreFiltro={"Tipo"}
                  />
                </div>
                <label className="block text-xs font-medium mb-1">
                  {filtros.types.filter((x) => x.check).length > 0 && (
                    <span className="ml-2">
                      Tipo:{" "}
                      {filtros.types
                        .filter((x) => x.check)
                        .map((x) => x.state)
                        .join(", ")}
                    </span>
                  )}
                </label>
              </div>

              <div>
                <div className="block text-xs font-medium mb-1">
                  <FilterButton
                    statusPaymentUniq={uniqueCertificationYearList}
                    stateCheck={yearList}
                    applyStatePaymentFilter={applyYearFilter}
                    nombreFiltro={"Años"}
                  />
                </div>
                <label className="text-xs">
                  {filtros.year.filter((x) => x.check).length > 0 && (
                    <span className="ml-2">
                      Año:{" "}
                      {filtros.year
                        .filter((x) => x.check)
                        .map((x) => x.state)
                        .join(", ")}
                    </span>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="text-sm font-medium text-right">
            Resultados: {certifications.data?.data.length}
          </div>
          {certifications.data?.data.length == 0 ? (
            <div className="flex justify-center">
              Cargando certificaciones&nbsp;
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            <div className="container-simple-table mt-2" style={{ height: "800px", overflowY: "scroll" }}>
              <table className="simple-table-sm">
                <thead className="text-center text-white sec-green-background sticky top-0 z-20">
                  <tr>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("id")}
                        className={`${getClassNamesFor("id")} `}
                      >
                        Id
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("userId")}
                        className={`${getClassNamesFor("userId")} `}
                      >
                        Id usuario
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("userEmail")}
                        className={`${getClassNamesFor(
                          "userEmail"
                        )} `}
                      >
                        Email usuario
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("organizationName")}
                        className={`${getClassNamesFor("organizationName")} `}
                      >
                        Organización
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("calculationYear")}
                        className={`${getClassNamesFor("calculationYear")} `}
                      >
                        Año
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("certificationType")}
                        className={`${getClassNamesFor("certificationType")} `}
                      >
                        Tipo
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("certificationMitecoStatus")}
                        className={`${getClassNamesFor("certificationMitecoStatus")} `}
                      >
                        Estado
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("questionnaireName")}
                        className={`${getClassNamesFor("questionnaireName")} `}
                      >
                        Tipo cálculo
                      </button>
                    </th>

                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("creationDate")}
                        className={`${getClassNamesFor("creationDate")} `}
                      >
                        Fecha creación
                      </button>
                    </th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {items.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>{item.id}</td>
                        <td className="text-xs">{item.userId}</td>
                        <td>{item.userEmail}</td>
                        <td>{item.organizationName}</td>
                        <td>{item.calculationYear}</td>
                        <td>{item.certificationType}</td>

                        <td>
                          <p
                            className={`text-center rounded-full py-0.5 px-2 ${statusColor(
                              item.certificationMitecoStatus
                            )}`}
                          >
                            {item.certificationMitecoStatus}
                          </p>
                        </td>
                        <td>{item.questionnaireName}</td>
                        <td> {formatDateTime(item.creationDate)} </td>
                        <td>
                          <div className="flex space-x-2 justify-center">
                            <button title="Ver documentos de la certificaión"
                              onClick={() =>
                                setModalDocumentation({
                                  view: true,
                                  certification: item,
                                })
                              }
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-files" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                                <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                                <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                              </svg>
                            </button>
                            <button title="Editar estados y datos"
                              onClick={() =>
                                setModalCertification({
                                  view: true,
                                  certification: item,
                                })
                              }
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-edit" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                <path d="M16 5l3 3" />
                              </svg>
                            </button>
                            <button title="Ver/editar documentación"
                              onClick={() => navigate(`/footprint/certification/${item.id}`, { state: { userId: item.userId, userName: item.userEmail } })}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-report-search" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
                                <path d="M18 12v-5a2 2 0 0 0 -2 -2h-2" />
                                <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                <path d="M8 11h4" />
                                <path d="M8 15h3" />
                                <path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                                <path d="M18.5 19.5l2.5 2.5" />
                              </svg>
                            </button>
                            {item.certificationMitecoStatus != "CREATED" &&
                              <button title="Ver/editar documentación"
                                onClick={() => getZipDocumentation(item)}
                                disabled={zipSpinner == item.id}
                              >
                                {zipSpinner == item.id ?
                                  <Spinner animation="border" variant="dark" />
                                  :
                                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-type-zip" width="28" height="28" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                                    <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4" />
                                    <path d="M16 18h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6" />
                                    <path d="M12 15v6" />
                                    <path d="M5 15h3l-3 6h3" />
                                  </svg>
                                }
                              </button>
                            }
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </>
  );
}
export default FootprintCertificationList;
