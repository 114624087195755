import axios from "axios";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { Input } from "reactstrap";
import UserService from "../../../../services/UserService";
import FilterButton from "../../utils/DropdownFilterOrders";
import { formatDateTime, formatThousands } from "../../utils/Utils";
import ModalFinishCalculation from "../../calculo-huella/ModalFinishCalculation";
import ModalRecalculate from "../../calculo-huella/ModalRecalculate";
import { useTranslation } from "react-i18next";
import ModalUpdateCalculation from "./ModalUpdateCalculation";
import ModalDraftCalculation from "./ModalDraftCalculation";

function FootprintCalculationList() {
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");

  const [calculations, setCalculations] = useState({
    data: { data: [] },
    isFetching: false,
  });
  const [calculationsOrigin, setCalculationsOrigin] = useState({
    data: { data: [] },
    isFetching: false,
  });

  const [uniqueCalculationStatusList, setUniqueCalculationStatusList] =
    useState([]);
  const [statusList, setStatusList] = useState([]);

  const [uniqueCalculationTypesList, setUniqueCalculationTypesList] = useState(
    []
  );
  const [typesList, setTypesList] = useState([]);

  const [uniqueCalculationYearList, setUniqueCalculationYearList] = useState(
    []
  );
  const [yearList, setYearList] = useState([]);

  const [modalFinalizarCalculo, setModalFinalizarCalculo] = useState({
    view: false,
    idCalculation: null,
    idOrganization: null,
  });

  const [modalRecalculate, setModalRecalculate] = useState({
    view: false,
    idCalculation: null,
  });

  const [modalCalculationUpdateReport, setModalCalculationUpdateReport] =
    useState({
      view: false,
      calculation: null,
    });

  const [modalCalculationDraft, setModalCalculationDraft] = useState({
    view: false,
    calculation: null,
  });

  const [finishedCalculation, setFinishedCalculation] = useState(false);

  const [recalculating, setRecalculating] = useState({
    id: 0,
    isFetching: false,
  });

  useEffect(() => {
    const fetchCalculations = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CH_CALCULATION}s`,
          {
            headers: {
              // "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          }
        );

        setCalculations({ data: response.data, isFetching: true });
        setCalculationsOrigin({ data: response.data, isFetching: true });

        //Filtro Estados
        response.data?.data.map((v) => {
          if (uniqueCalculationStatusList.indexOf(v.calculationStatus) === -1) {
            uniqueCalculationStatusList.push(v.calculationStatus);
          }
        });
        setUniqueCalculationStatusList(uniqueCalculationStatusList.sort());
        uniqueCalculationStatusList.map((v) => {
          statusList.push({
            state: v,
            check: false,
          });
        });
        setStatusList(statusList);

        //Filtro tipo
        response.data?.data.map((v) => {
          if (
            uniqueCalculationTypesList.indexOf(v.questionnaire?.name) === -1
          ) {
            if (v.questionnaire !== undefined) {
              uniqueCalculationTypesList.push(v.questionnaire?.name);
            }
          }
        });
        setUniqueCalculationTypesList(uniqueCalculationTypesList.sort());
        uniqueCalculationTypesList.map((v) => {
          typesList.push({
            state: v,
            check: false,
          });
        });
        setTypesList(typesList);

        //Filtro año
        response.data?.data.map((v) => {
          if (uniqueCalculationYearList.indexOf(v.year) === -1) {
            if (v.questionnaire !== undefined) {
              uniqueCalculationYearList.push(v.year);
            }
          }
        });
        setUniqueCalculationYearList(uniqueCalculationYearList.sort());
        uniqueCalculationYearList.map((v) => {
          yearList.push({
            state: v.toString(),
            check: false,
          });
        });
        setYearList(yearList);
      } catch (exception) {
        console.error(exception);
        setCalculations({ data: { data: [] }, isFetching: true });
      }
    };
    fetchCalculations();
  }, [finishedCalculation]);

  const [spinnerDelete, setSpinnerDelete] = useState({
    state: false,
    id: undefined,
  });
  const deleteCalculation = async (id, org, year) => {
    setSpinnerDelete({ state: true, id: id });
    if (
      confirm(
        "¿Deseas borrar el cálculo " +
        id +
        " de la organización '" +
        org +
        "' del año " +
        year +
        "?"
      )
    ) {
      try {
        await axios.delete(`${process.env.REACT_APP_CH_CALCULATION}/${id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${UserService.getToken()}`,
          },
        });
        setCalculations((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            data: prevState.data.data.filter((item) => item.id !== id),
          },
          isFetching: true,
        }));
        setCalculationsOrigin((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            data: prevState.data.data.filter((item) => item.id !== id),
          },
          isFetching: true,
        }));
      } catch (exception) {
        console.error(exception);
        setCalculations({ data: { data: [] }, isFetching: true });
      }
    }
    setSpinnerDelete({ state: false, id: undefined });
  };

  const applyStatusFilter = (data) => {
    setFiltros((prevFiltros) => ({
      ...prevFiltros,
      status: data,
    }));
  };

  const applyTypeFilter = (data) => {
    setFiltros((prevFiltros) => ({
      ...prevFiltros,
      types: data,
    }));
  };

  const applyYearFilter = (data) => {
    setFiltros((prevFiltros) => ({
      ...prevFiltros,
      year: data,
    }));
  };

  const [filtros, setFiltros] = useState({
    status: [],
    types: [],
    year: [],
    organization: "",
    userId: "",
    origin: "",
  });

  useEffect(() => {
    const handleInputChange = () => {
      let filteredData = calculationsOrigin.data?.data;

      if (filtros.status) {
        filteredData = OnFilterStatus(filtros.status, filteredData);
      }

      if (filtros.types) {
        filteredData = OnFilterTypes(filtros.types, filteredData);
      }

      if (filtros.year) {
        filteredData = OnFilterYear(filtros.year, filteredData);
      }

      if (filtros.organization) {
        filteredData = OnfilterOrganization(filtros.organization, filteredData);
      }

      if (filtros.userId) {
        filteredData = OnFilterUsuario(filtros.userId, filteredData);
      }

      if (filtros.origin) {
        filteredData = OnFilterOrigen(filtros.origin, filteredData);
      }

      setCalculations({ data: { data: filteredData }, isFetching: true });
    };
    handleInputChange();
  }, [filtros]);

  const OnfilterOrganization = (filter, filteredData) => {
    return filteredData.filter((v) =>
      v.organization?.name?.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const OnFilterUsuario = (filter, filteredData) => {
    return filteredData.filter(
      (v) =>
        v.userId?.toLowerCase().includes(filter.toLowerCase()) ||
        v.organization?.userEmail?.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const OnFilterOrigen = (filter, filteredData) => {
    return filteredData.filter((v) =>
      v.origin?.toLowerCase().includes(filter.toLowerCase())
    );
  };

  const OnFilterStatus = (data, filteredData) => {
    let states = data.filter((c) => c.check).map((c) => c.state);

    if (states.length > 0) {
      return filteredData.filter((c) => states.includes(c.calculationStatus));
    }
    return filteredData;
  };

  const OnFilterTypes = (data, filteredData) => {
    let states = data.filter((c) => c.check).map((c) => c.state);

    if (states.length > 0) {
      return filteredData.filter((c) => states.includes(c.questionnaire?.name));
    }
    return filteredData;
  };

  const OnFilterYear = (data, filteredData) => {
    let states = data.filter((c) => c.check).map((c) => c.state);

    if (states.length > 0) {
      return filteredData.filter((c) => states.includes(c.year.toString()));
    }
    return filteredData;
  };

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);
    const sortedItems = useMemo(() => {
      let sortableItems = [...items];

      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          let valueA = a[sortConfig.key];
          let valueB = b[sortConfig.key];

          if (sortConfig.key.includes(".")) {
            let params = sortConfig.key.split(".");
            valueA = a[params[0]]?.[params[1]];
            valueB = b[params[0]]?.[params[1]];
          }

          if (valueA === null || valueA === undefined || valueA === "") {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }

          if (valueB === null || valueB === undefined || valueB === "") {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }

          if (!isNaN(valueA) || moment(valueA).isValid()) {
            if (valueA < valueB) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (valueA > valueB) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          } else {
            if (
              valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (
              valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }

          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(
    calculations.data?.data
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  //BGcolor para los estados de pagos
  const statusColor = (props) => {
    switch (props) {
      case "DRAFT":
        return "bg-gray-100 text-gray-500";
      case "EXTERNAL":
        return "bg-yellow-500 text-grey-100";
      case "FINALIZED":
        return "bg-green-100 text-green-600";
      case "ERROR":
        return "bg-red-100 text-red-600";
    }
  };

  const handleEditCalculation = (calculationObjetc) => {
    navigate("/footprint/carbonmanager", {
      state: { calculationOutputDto: { data: calculationObjetc } },
    });
  };

  const handleDocument = (calculationObjetc) => {
    navigate(`/admin/footprint/documents/${calculationObjetc.id}`, {
      state: { calcObjetc: calculationObjetc, dropzone: true, delete: true },
    });
  };

  const handleGetCalculationDetail = (calculationObjetc) => {
    navigate("/footprint/myfootprint/report/" + calculationObjetc.id, {
      state: { user: { data: calculationObjetc.userId } },
    });
  };

  const updateViewFromParent = (props) => {
    setModalFinalizarCalculo({
      view: false,
      idCalculation: null,
      idOrganization: null,
    });
    setFinishedCalculation(!finishedCalculation);
  };

  const handleRecalculate = async (id) => {
    setModalRecalculate({ view: true, id: id });
  };

  const updateFromRecalculate = (id) => {
    const newCalculations = [...calculations.data.data];
    const indexObj = newCalculations.findIndex((obj) => obj.id === id);

    if (indexObj !== -1) {
      newCalculations[indexObj].hasUpdatedFactors = true;
      setCalculations({ data: { data: newCalculations }, isFetching: true });
    } else {
      console.warn("Objeto no encontrado con el ID:", id);
    }
  };

  const updateCalculationData = (calculation) => {
    setCalculations((prevState) => {
      const indexToUpdate = prevState.data.data.findIndex(
        (record) => record.id === calculation.id
      );

      if (indexToUpdate !== -1) {
        const updatedData = [...prevState.data.data];

        updatedData[indexToUpdate] = calculation;
        return {
          ...prevState,
          data: { ...prevState.data, data: updatedData },
          isFetching: true,
        };
      }
      return prevState;
    });

    setCalculationsOrigin((prevState) => {
      const indexToUpdate = prevState.data.data.findIndex(
        (record) => record.id === calculation.id
      );

      if (indexToUpdate !== -1) {
        const updatedData = [...prevState.data.data];

        updatedData[indexToUpdate] = calculation;
        return {
          ...prevState,
          data: { ...prevState.data, data: updatedData },
          isFetching: true,
        };
      }
      return prevState;
    });
  };

  return (
    <>
      <ModalFinishCalculation
        view={modalFinalizarCalculo.view}
        calculationId={modalFinalizarCalculo.idCalculation}
        idOrg={modalFinalizarCalculo.idOrganization}
        updateViewFromParent={updateViewFromParent}
      />

      <ModalRecalculate
        modalRecalculate={modalRecalculate}
        setModalRecalculate={setModalRecalculate}
        recalculating={recalculating}
        setRecalculating={setRecalculating}
        updateFromRecalculate={updateFromRecalculate}
      ></ModalRecalculate>

      <ModalUpdateCalculation
        modalCalculationUpdateReport={modalCalculationUpdateReport}
        setModalCalculationUpdateReport={setModalCalculationUpdateReport}
        updateCalculationData={updateCalculationData}
      ></ModalUpdateCalculation>

      <ModalDraftCalculation
        modalCalculationDraft={modalCalculationDraft}
        setModalCalculationDraft={setModalCalculationDraft}
        updateCalculationData={updateCalculationData}
      ></ModalDraftCalculation>

      {!calculations.isFetching && (
        <Spinner animation="border" variant="dark" />
      )}

      {calculations.isFetching && calculations.data.data != undefined && (
        <>
          <div className="bg-white p-3 mb-3">
            {/* <div className="mb-2 font-bold">
              <h2 className="font-bold">Filtros</h2>
            </div> */}
            <div className="grid gap-2 md:grid-cols-3">
              <div>
                <label
                  className="block text-xs font-semibold mb-1 text-airco2-general"
                  htmlFor="default"
                >
                  Usuario
                </label>
                <Input
                  value={filtros.userId}
                  className="w-full text-xs border-gray-200 hover:border-gray-300"
                  onChange={(e) =>
                    setFiltros((prevFiltros) => ({
                      ...prevFiltros,
                      userId: e.target.value,
                    }))
                  }
                  placeholder="Busca por usuario"
                  type="text"
                />
              </div>

              <div>
                <label
                  className="block text-xs font-semibold mb-1 text-airco2-general"
                  htmlFor="default"
                >
                  Organización
                </label>
                <Input
                  value={filtros.organization}
                  className="w-full text-xs border-gray-200 hover:border-gray-300"
                  onChange={(e) =>
                    setFiltros((prevFiltros) => ({
                      ...prevFiltros,
                      organization: e.target.value,
                    }))
                  }
                  placeholder="Busca por empresa"
                  type="text"
                />
              </div>

              <div>
                <label
                  className="block text-xs font-semibold mb-1 text-airco2-general"
                  htmlFor="default"
                >
                  Origen
                </label>
                <Input
                  value={filtros.origin}
                  className="w-full text-xs border-gray-200 hover:border-gray-300"
                  onChange={(e) =>
                    setFiltros((prevFiltros) => ({
                      ...prevFiltros,
                      origin: e.target.value,
                    }))
                  }
                  placeholder="Busca por origen"
                  type="text"
                />
              </div>

              <div>
                <div className="w-full rounded-lg ">
                  <FilterButton
                    statusPaymentUniq={uniqueCalculationStatusList}
                    stateCheck={statusList}
                    applyStatePaymentFilter={applyStatusFilter}
                    nombreFiltro={"Estado"}
                  />
                </div>
                <label className="block text-xs font-medium mb-1">
                  {filtros.status.filter((x) => x.check).length > 0 && (
                    <span className="ml-2">
                      Estado:{" "}
                      {filtros.status
                        .filter((x) => x.check)
                        .map((x) => x.state)
                        .join(", ")}
                    </span>
                  )}
                </label>
              </div>

              <div>
                <div className="w-full rounded-lg ">
                  <FilterButton
                    statusPaymentUniq={uniqueCalculationTypesList}
                    stateCheck={typesList}
                    applyStatePaymentFilter={applyTypeFilter}
                    nombreFiltro={"Tipo"}
                  />
                </div>
                <label className="block text-xs font-medium mb-1">
                  {filtros.types.filter((x) => x.check).length > 0 && (
                    <span className="ml-2">
                      Tipo:{" "}
                      {filtros.types
                        .filter((x) => x.check)
                        .map((x) => x.state)
                        .join(", ")}
                    </span>
                  )}
                </label>
              </div>

              <div>
                <div className="block text-xs font-medium mb-1">
                  <FilterButton
                    statusPaymentUniq={uniqueCalculationYearList}
                    stateCheck={yearList}
                    applyStatePaymentFilter={applyYearFilter}
                    nombreFiltro={"Años"}
                  />
                </div>
                <label className="text-xs">
                  {filtros.year.filter((x) => x.check).length > 0 && (
                    <span className="ml-2">
                      Año:{" "}
                      {filtros.year
                        .filter((x) => x.check)
                        .map((x) => x.state)
                        .join(", ")}
                    </span>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="text-xs font-medium text-right">
            Resultados: {calculations.data?.data.length}
          </div>
          {calculations.data?.data.length == 0 ? (
            <div className="flex justify-center">
              Cargando cálculos&nbsp;
              <Spinner animation="border" variant="dark" />
            </div>
          ) : (
            // <div
            //   className="overflow-x-auto"
            //   style={{ height: "800px", overflowY: "scroll" }}
            // >


            <div className="container-simple-table mt-2" style={{ height: "800px", overflowY: "scroll" }}>
              <table className="simple-table-sm">
                <thead className="text-center text-white sec-green-background sticky top-0 z-20">
                  <tr>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("id")}
                        className={`${getClassNamesFor("id")} `}
                      >
                        Id
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("userId")}
                        className={`${getClassNamesFor("userId")} `}
                      >
                        Id usuario
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("organization.userEmail")}
                        className={`${getClassNamesFor(
                          "organization.userEmail"
                        )} `}
                      >
                        Email usuario
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("organization.name")}
                        className={`${getClassNamesFor("organization.name")} `}
                      >
                        Organización
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("origin")}
                        className={`${getClassNamesFor("origin")} `}
                      >
                        Origen
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("calculationStatus")}
                        className={`${getClassNamesFor("calculationStatus")} `}
                      >
                        Estado
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("questionnaire.name")}
                        className={`${getClassNamesFor("questionnaire.name")} `}
                      >
                        Tipo cálculo
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("year")}
                        className={`${getClassNamesFor("year")} `}
                      >
                        Año
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("employees")}
                        className={`${getClassNamesFor("employees")} `}
                      >
                        Empleados
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("annualTurnover")}
                        className={`${getClassNamesFor("annualTurnover")} `}
                      >
                        Facturación
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("finishDate")}
                        className={`${getClassNamesFor("finishDate")} `}
                      >
                        Finalización
                      </button>
                    </th>
                    <th>
                      <button
                        type="button"
                        onClick={() => requestSort("totalKgCO2e")}
                        className={`${getClassNamesFor("totalKgCO2e")} `}
                      >
                        kgCO<sub>2</sub>e
                      </button>
                    </th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {items.map((p, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <td>{p.id}</td>
                          <td>{p.userId}</td>
                          <td>{p.organization?.userEmail}</td>
                          <td>{p.organization?.name}</td>
                          <td>{p.origin}</td>

                          <td>
                            <p
                              className={`text-center rounded-full py-0.5 px-2 ${statusColor(
                                p.calculationStatus
                              )}`}
                            >
                              {p.calculationStatus}
                            </p>
                          </td>

                          <td>{p.questionnaire?.name}</td>
                          <td>{p.year}</td>
                          <td>{p.employees}</td>

                          <td>
                            {p.annualTurnover != null &&
                              formatThousands(p.annualTurnover) + "€"}
                          </td>

                          <td>
                            {p.finishDate != undefined &&
                              formatDateTime(p.finishDate)}
                          </td>

                          <td>{formatThousands(p.totalKgCO2e)}kg</td>

                          <td>
                            <div className="flex space-x-2 justify-center">
                              {p.calculationStatus == "EXTERNAL" && (
                                <button
                                  title="Ver documentos del cálculo"
                                  onClick={() => handleDocument(p)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon icon-tabler icon-tabler-files"
                                    width="32"
                                    height="32"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="var(--airco2-grey-color)"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                                    <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                                    <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                                  </svg>
                                </button>
                              )}
                              {p.calculationStatus !== "EXTERNAL" && (
                                <>
                                  <button
                                    title="Ver documentos del cálculo"
                                    onClick={() => handleDocument(p)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon icon-tabler icon-tabler-files"
                                      width="32"
                                      height="32"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="var(--airco2-grey-color)"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                                      <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                                      <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
                                    </svg>
                                  </button>
                                  <button
                                    title="Huella de terceros"
                                    disabled={!p.thirdPartyForm}
                                    onClick={() => navigate(`/footprint/formContacts/${p.id}`, { state: { data: { year: p.year, orgName: p.organization.name, orgId: p.organization.id, status: p.calculationStatus, thirdPartyForm: p.thirdPartyForm, userId: p.userId } } })}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-list-numbers" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                      <path d="M11 6h9" />
                                      <path d="M11 12h9" />
                                      <path d="M12 18h8" />
                                      <path d="M4 16a2 2 0 1 1 4 0c0 .591 -.5 1 -1 1.5l-3 2.5h4" />
                                      <path d="M6 10v-6l-2 2" />
                                    </svg>
                                  </button>

                                  <button
                                    title="Ver cuestionario"
                                    onClick={() => handleEditCalculation(p)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon icon-tabler icon-tabler-report-search"
                                      width="32"
                                      height="32"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="var(--airco2-grey-color)"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
                                      <path d="M18 12v-5a2 2 0 0 0 -2 -2h-2" />
                                      <path d="M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                      <path d="M8 11h4" />
                                      <path d="M8 15h3" />
                                      <path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                                      <path d="M18.5 19.5l2.5 2.5" />
                                    </svg>
                                  </button>
                                  {p.calculationStatus === "FINALIZED" && (
                                    <button
                                      title="Ver detalle del cálculo"
                                      onClick={() =>
                                        handleGetCalculationDetail(p)
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-id"
                                        width="32"
                                        height="32"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="var(--airco2-grey-color)"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M3 4m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z" />
                                        <path d="M9 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" />
                                        <path d="M15 8l2 0" />
                                        <path d="M15 12l2 0" />
                                        <path d="M7 16l10 0" />
                                      </svg>
                                    </button>
                                  )}
                                  {p.calculationStatus === "DRAFT" && (
                                    <button
                                      title="Finalizar cálculo"
                                      onClick={() =>
                                        setModalFinalizarCalculo({
                                          view: true,
                                          idCalculation: p.id,
                                          idOrganization: p.organization.id,
                                        })
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-square-rounded-check"
                                        width="32"
                                        height="32"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="var(--airco2-grey-color)"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M9 12l2 2l4 -4" />
                                        <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                                      </svg>
                                    </button>
                                  )}
                                  {p.calculationStatus === "FINALIZED" && (
                                    <button
                                      title="Poner cálculo en borrador"
                                      onClick={() =>
                                        setModalCalculationDraft({
                                          view: true,
                                          calculation: p,
                                        })
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-rotate-2"
                                        width="32"
                                        height="32"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="var(--airco2-grey-color)"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M15 4.55a8 8 0 0 0 -6 14.9m0 -4.45v5h-5" />
                                        <path d="M18.37 7.16l0 .01" />
                                        <path d="M13 19.94l0 .01" />
                                        <path d="M16.84 18.37l0 .01" />
                                        <path d="M19.37 15.1l0 .01" />
                                        <path d="M19.94 11l0 .01" />
                                      </svg>
                                    </button>
                                  )}

                                  <button
                                    title="Editar cálculo"
                                    onClick={() =>
                                      setModalCalculationUpdateReport({
                                        view: true,
                                        calculation: p,
                                      })
                                    }
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon icon-tabler icon-tabler-info-octagon"
                                      width="32"
                                      height="32"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="var(--airco2-grey-color)"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      />
                                      <path d="M12.802 2.165l5.575 2.389c.48 .206 .863 .589 1.07 1.07l2.388 5.574c.22 .512 .22 1.092 0 1.604l-2.389 5.575c-.206 .48 -.589 .863 -1.07 1.07l-5.574 2.388c-.512 .22 -1.092 .22 -1.604 0l-5.575 -2.389a2.036 2.036 0 0 1 -1.07 -1.07l-2.388 -5.574a2.036 2.036 0 0 1 0 -1.604l2.389 -5.575c.206 -.48 .589 -.863 1.07 -1.07l5.574 -2.388a2.036 2.036 0 0 1 1.604 0z" />
                                      <path d="M12 9h.01" />
                                      <path d="M11 12h1v4h1" />
                                    </svg>
                                  </button>
                                </>
                              )}
                              {p.calculationStatus !== "FINALIZED" && (
                                <>
                                  {spinnerDelete.state &&
                                    spinnerDelete.id === p.id && (
                                      <Spinner
                                        animation="border"
                                        variant="dark"
                                      />
                                    )}
                                  {!spinnerDelete.state && (
                                    <button
                                      title="Borrar cálculo"
                                      onClick={() =>
                                        deleteCalculation(
                                          p.id,
                                          p.organization?.name,
                                          p.year
                                        )
                                      }
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-trash-x"
                                        width="32"
                                        height="32"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="red"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M4 7h16" />
                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                        <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                        <path d="M10 12l4 4m0 -4l-4 4" />
                                      </svg>
                                    </button>
                                  )}
                                </>
                              )}
                            </div>
                            {!p.hasUpdatedFactors &&
                              (p.updatable ? (
                                <button onClick={() => handleRecalculate(p.id)}>
                                  {recalculating.isFetching &&
                                    recalculating.id === p.id ? (
                                    <>
                                      {t("footPrint.recalculate.recalculating")}{" "}
                                      <Spinner
                                        animation="border"
                                        variant="dark"
                                      />
                                    </>
                                  ) : (
                                    <div className="flex items-center text-airco2-secondary">
                                      {t("footPrint.recalculate.recalculate")}
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-refresh-dot"
                                        width="32"
                                        height="32"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="var(--airco2-orange-color)"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      >
                                        <path
                                          stroke="none"
                                          d="M0 0h24v24H0z"
                                          fill="none"
                                        />
                                        <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4" />
                                        <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4" />
                                        <path d="M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                      </svg>
                                    </div>
                                  )}
                                </button>
                              ) : (
                                <div className="text-xs ">
                                  Todavía no han sido publicados los factores
                                  para el año del cálculo.
                                </div>
                              ))}
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </>
      )}
    </>
  );
}
export default FootprintCalculationList;
