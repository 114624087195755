import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Header from '../Header';
import Sidebar from '../Sidebar';
import OrganizacionesComponent from './OganizacionesComponent';


export default function Organizaciones() {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [t, i18n] = useTranslation("global");
    const clasDiv = `flex flex-wrap gap-8 org-justify`;
    let [total, updateTotal] = useState('')
    let [idOrg, updateIdOrg] = useState('');
    const [showInstructions, setShowInstructions] = useState(false);

    const childTotal = (v) => {
        updateTotal(v)
    }

    const childData = (v) => {
        updateIdOrg(v)

    }

    const InstructionsText = () => {
        return <>
            <div className="mb-6">
                <p className="mb-3 ">{t("footPrint.organizationPage.instructionsLine1")}</p>
                <p className="text-xl font-bold"><span dangerouslySetInnerHTML={{ __html: t("footPrint.organizationPage.instructionsLine2") }}></span></p>
                <p className=""><span dangerouslySetInnerHTML={{ __html: t("footPrint.organizationPage.instructionsLine3") }}></span></p>
                <p className="mb-3"><span dangerouslySetInnerHTML={{ __html: t("footPrint.organizationPage.instructionsLine4") }}></span></p>
                <p className="text-xl font-bold"><span dangerouslySetInnerHTML={{ __html: t("footPrint.organizationPage.instructionsLine5") }}></span></p>
                <p className=""><span dangerouslySetInnerHTML={{ __html: t("footPrint.organizationPage.instructionsLine6") }}></span></p>
                <p className="mb-2"><span dangerouslySetInnerHTML={{ __html: t("footPrint.organizationPage.instructionsLine7") }}></span></p>
                <p className=""><span dangerouslySetInnerHTML={{ __html: t("footPrint.organizationPage.instructionsLine8") }}></span></p>
                <p className="mb-3"><span dangerouslySetInnerHTML={{ __html: t("footPrint.organizationPage.instructionsLine9") }}></span></p>
                <p className="text-xl font-bold"><span dangerouslySetInnerHTML={{ __html: t("footPrint.organizationPage.instructionsLine10") }}></span></p>
                <p className=""><span dangerouslySetInnerHTML={{ __html: t("footPrint.organizationPage.instructionsLine11") }}></span></p>
                <p className=""><span dangerouslySetInnerHTML={{ __html: t("footPrint.organizationPage.instructionsLine12") }}></span></p>

            </div >
        </>
    }

    return (
        <div className=" sec-neutro-background flex h-screen overflow-hidden ">
            <Modal show={showInstructions} size={"lg"}>
                <Modal.Header className="sec-green-background">
                    <Modal.Title className="text-white">
                        {t("footPrint.organizationPage.instructions")}
                    </Modal.Title>
                    <div className="flex justify-end">
                        <button onClick={() => setShowInstructions(false)}>
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                        </button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <h2 className="text-2xl text-center text-gray-800 dark:text-gray-100 font-bold mb-2">
                        {t("footPrint.organizationPage.instructionsTitle")}
                    </h2>
                    <InstructionsText></InstructionsText>
                </Modal.Body>
            </Modal>

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        {/* Page header */}
                        <div className="mb-8 flex justify-between">
                            {/* Title */}
                            <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">{t("footPrint.organizationPage.title")}</h1>
                            <div className='flex justify-end gap-2'>
                                {total > 0 &&
                                    <button onClick={() => setShowInstructions(true)} className='flex items-center btn-banner-o mr-4 rounded-lg px-4 py-2' >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-square-rounded mr-2" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 9h.01" />
                                            <path d="M11 12h1v4h1" />
                                            <path d="M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z" />
                                        </svg>
                                        {t("footPrint.organizationPage.instructions")}
                                    </button>}

                            </div>
                        </div>

                        {/* Content */}
                        {total === 0 && <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
                            <div className="max-w-2xl m-auto mt-16">
                                <div className="px-4 text-center">
                                    <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-t from-gray-200 to-gray-100 dark:from-gray-700 dark:to-gray-800 mb-4">

                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-seeding" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-green-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 10a6 6 0 0 0 -6 -6h-3v2a6 6 0 0 0 6 6h3" stroke-dasharray="40" stroke-dashoffset="40">
                                                <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" />
                                            </path>
                                            <path d="M12 14a6 6 0 0 1 6 -6h3v1a6 6 0 0 1 -6 6h-3" stroke-dasharray="40" stroke-dashoffset="40">
                                                <animate attributeName="stroke-dashoffset" from="40" to="0" dur="3s" repeatCount="indefinite" begin="3s" />
                                            </path>
                                            <path d="M12 20l0 -10" stroke-dasharray="10" stroke-dashoffset="10">
                                                <animate attributeName="stroke-dashoffset" from="10" to="0" dur="3s" repeatCount="indefinite" begin="5s" />
                                            </path>
                                        </svg>
                                    </div>
                                    <h2 className="text-2xl text-gray-800 dark:text-gray-100 font-bold mb-2">{t("footPrint.organizationPage.instructionsTitleNoOrgs")}</h2>
                                    <div className="mb-6">
                                        <InstructionsText></InstructionsText>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div className={total === 0 ? "flex justify-center" : ""}>
                            <OrganizacionesComponent
                                clase={clasDiv}
                                numberOrg={'all'}
                                childTotal={childTotal}
                                childData={childData}
                            ></OrganizacionesComponent>
                        </div>
                    </div>
                </main>

            </div>

        </div>
    )
}
