import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IdiomContext } from "../../../../../context/createcontext"
import completedOk from '../../../../../images/undraw_completed_m9ci.svg'
import completedKo from '../../../../../images/perfilko.svg'
import { formatThousands } from '../../../utils/Utils';


export default function FinishedForm({ data, url, isEvent }) {

  const [lng, updateLng] = useContext(IdiomContext)
  const [t, i18n] = useTranslation("global");
  return (
    <div className="sec-neutro-background" style={{ display: 'flex', minHeight: '50vh' }}>
      <div className='flex justify-center sec-neutro-background container py-8 max-w-9xl mx-auto'>
        {(data?.finalized) ?
          <div className=' flex flex-col justify-center'>

            <h1 className=' text-center text-lg md:text-xl font-bold mb-4 mt-1 flex flex-wrap gap-2'>
              <span>{t("fpThird.agradecimiento")}</span>
            </h1>

            <div className='flex justify-center '>
              <img width={160} src={completedOk} />
            </div>

            <div className='flex justify-center flex-col mt-2 '>
              <span className='text-center'>{t("fpThird.resultadoTerceros")}</span>
              <span className='text-center' ><span className='font-bold text-orange text-lg'>
                {formatThousands(data?.totalKgCO2e)}</span> kg CO<sub>2</sub>e</span>
            </div>
            <div className='flex justify-center gap-2'>
              <div className='flex flex-wrap justify-center mt-4 gap-5'>
                <a href="/" className='btn-green font-bold px-4 py-2'>{t("fpThird.btnairco")}</a>
              </div>
              {(url && isEvent) &&
                <div className='flex flex-wrap justify-center mt-4 gap-5'>
                  <a href={url} target='_blank' className='btn btn-banner-o px-4 py-2'>{t("fpThird.eventWebsite")}</a>
                </div>}
            </div>

          </div>
          :
          <div className=' flex flex-col justify-center'>

            <div className=' text-center font-bold mb-4 mt-1 flex flex-wrap gap-2'>
              <p className='text-lg md:text-xl'>{t("fpThird.finalizadoTerceroKo1")}<br /> <span className='text-md md:text-lg'>{t("fpThird.finalizadoTerceroKo2")}</span></p>
            </div>

            <div className='flex justify-center '>
              <img width={160} src={completedKo} />
            </div>

            <div className='flex justify-center gap-2'>
              <div className='flex flex-wrap justify-center mt-4 gap-5'>
                <a href="/" className='btn-green font-bold rounded-lg px-4 py-2'>{t("fpThird.btnairco")}</a>
              </div>
              {(url && isEvent) &&
                <div className='flex flex-wrap justify-center mt-4 gap-5'>
                  <a href={url} target='_blank' className='btn btn-banner-o rounded-lg px-4 py-2'>{t("fpThird.eventWebsite")}</a>
                </div>}
            </div>

          </div>
        }
      </div>
    </div>
  )
}
