import Header from '../../partials/Header';
import Footer from '../../partials/Footer';
import { useEffect, useRef, useState } from "react";
import { Spinner } from 'react-bootstrap';


function Corresponsables() {
    const iframeRef = useRef(null);
    const [iframeHeight, setIframeHeight] = useState("0px"); // Inicialmente 0 para evitar mostrar un scroll
    const [headerHeight, setHeaderHeight] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const header = document.getElementById("header");
        if (header) {
            setHeaderHeight(header.offsetHeight);
        }

        const handleResize = (event) => {
            if (event.data?.altura) {
                setIframeHeight(`${event.data.altura}px`);
                setTimeout(() => setLoading(false), 150); // Espera antes de mostrar el iframe
            }
        };

        window.addEventListener("message", handleResize);
        return () => window.removeEventListener("message", handleResize);
    }, []);

    return (
        <div style={{ overflow: "hidden" }}>
            <Header />
            <div style={{ position: "relative", minHeight: "100vh" }}>
                {loading && (
                    <div
                        className="flex justify-center items-center space-x-2 text-gray-800"
                        style={{ marginTop: `${headerHeight + 50}px` }}
                    >
                        <Spinner animation="border" variant="dark" />
                    </div>
                )}
                <iframe
                    ref={iframeRef}
                    src="/evento-corresponsables.html"
                    style={{
                        width: "100%",
                        height: iframeHeight,
                        minHeight: "100vh",
                        border: "none",
                        opacity: loading ? 0 : 1,
                        visibility: loading ? "hidden" : "visible",
                        marginTop: `${headerHeight}px`,
                        transition: "opacity 0.3s ease-in-out, height 0.5s ease-in-out",
                        overflow: "hidden",
                    }}
                    onLoad={() => setTimeout(() => setLoading(false), 150)} // Pequeño retraso para estabilidad
                />
            </div>
            <Footer />
        </div>
    );
}
export default Corresponsables;