import React from 'react';

export default function StepMenu({ certificationStep, menuSteps }) {
    return (
        <div className='flex flex-col md:flex-row items-center justify-center space-y-4 md:space-y-0 md:space-x-6  mt-4 bg-white rounded-lg p-4'>
            {menuSteps.map((step, index) => {
                return (
                    <div className='flex gap-4 items-center' key={"menu-" + index}>
                        {certificationStep > step.position ?
                            <div className="w-10 h-10 rounded-full sec-green-background text-airco2-general-grey flex items-center justify-center text-xl font-bold" style={{ minWidth: '40px', border: '3px solid var(--airco2-green-color)' }}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-check" width="32" height="32" viewBox="0 0 24 24" strokeWidth="2" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M5 12l5 5l10 -10" />
                                </svg>
                            </div>
                            :
                            <div className="w-10 h-10 rounded-full bg-white text-airco2-general-grey flex items-center justify-center text-xl font-bold" style={{ minWidth: '40px', border: '3px solid var(--airco2-green-color)' }}>{step.position}</div>
                        }
                        <p className="text-left md:text-left text-airco2-general-grey font-semibold">{step.name}</p>
                        {index < menuSteps.length - 1 &&
                            <span className='mr-4 hidden md:block text-airco2-general-grey'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-right" width="32" height="32" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-grey-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M5 12l14 0" />
                                    <path d="M15 16l4 -4" />
                                    <path d="M15 8l4 4" />
                                </svg>
                            </span>
                        }
                    </div>
                )
            })}
        </div >
    )
}