import { useState, useEffect } from "react";
import Sidebar from "../../Sidebar";
import Header from "../../Header";
import FootprintSubscriptionList from "./FootprintSubscriptionList";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import axios from "axios";
import Select from 'react-select';
import UserService from "../../../../services/UserService";
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import { event } from "react-ga";


function FootprintCalculationAdmin() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalSubscription, setDisplayModalSubscription] = useState(false);
  const [spinerSubscription, setSpinerSubscription] = useState(false);
  const [stripePrice, setStripePrice] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [urlPayNewSubscription, setUrlPayNewSubscription] = useState();
  const [newPlan, setNewPlan] = useState();
  const navigate = useNavigate();
  const [clipboardIcon, setClipboardIcon] = useState(
    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clipboard" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
      <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
    </svg>
  );

  const fetchAllUsers = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_ADMIN_USERS}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Authorization': `Bearer ${UserService.getToken()}`
        }
      }).then(response => {
        setUsuarios(response.data);
      });
    } catch (exception) {
      console.error(exception);
    }
  };

  const postCustomSubscription = async (props) => {
    setSelectedOption();
    setSpinerSubscription(true);
    try {
      await axios.post(`${process.env.REACT_APP_CH_CUSTOM_SUBSCRIPTION}?plan=3`, props, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${UserService.getToken()}`,
        },
      }).then(response => {
        putSubscription(response.data.data.id);
      });
    } catch (exception) {
      console.error(exception);
    }
  };

  const postNewSubscription = async (props) => {
    setSelectedOption();
    setSpinerSubscription(true);
    try {
      await axios.post(`${process.env.REACT_APP_CH_ADMIN_SUBSCRIPTION}?plan=${newPlan}`, props, {
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${UserService.getToken()}`,
        },
      }).then(response => {
        setUrlPayNewSubscription(response.data.data);
      });
    } catch (exception) {
      console.error(exception);
      setSpinerSubscription(false);
    }
  };

  const putSubscription = async (props) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_CH_SUBSCRIPTION}?subscriptionId=${props}&stripePriceId=${stripePrice}`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${UserService.getToken()}`

        },
      });
      if (response.ok) {
        const responseData = await response.json();
        setUrlPayNewSubscription(responseData.data);
      }

    } catch (exception) {
      console.error(exception);
    }
    setStripePrice("");
    setSpinerSubscription(false);
  }


  const [plans, setPlans] = useState({
    data: [],
    isFetching: true,
  });

  useEffect(() => {
    const fetchPlans = async () => {
      const url = `${process.env.REACT_APP_CH_PLANS}`;

      try {
        const response = await axios.get(`${url}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // 'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${UserService.getToken()}`,
          },
        });
        setPlans({ data: response.data.data, isFetching: false });
      } catch (exception) {
        console.error(exception);
        setPlans({ data: [], isFetching: false });
      }
    };
    fetchPlans();
  }, []);

  async function copyContent() {
    try {
      await navigator.clipboard.writeText(urlPayNewSubscription);
      setClipboardIcon(
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clipboard-check" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
          <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
          <path d="M9 14l2 2l4 -4" />
        </svg>
      );
    } catch (err) {
      setClipboardIcon(
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clipboard-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="var(--airco2-orange-color)" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
          <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
          <path d="M10 12l4 4m0 -4l-4 4" />
        </svg>
      );
    }
  }


  const showSubscriptionModal = () => {
    fetchAllUsers();
    setDisplayModalSubscription(true);
  }

  const handleSelectChange = (option) => {
    setSelectedOption(option);
  };

  const usuariosEmpresa = usuarios.filter(usuario => usuario.userType === 'Representante empresarial');

  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-2">
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">CarbonManager - Suscripciones</h1>
            </div>

            <div className="mb-8">
              <div className="flex flex-col xxl:flex-row xxl:-mr-px">
                <div>
                  <div className="pr-4 sm:pr-6 lg:pr-8 w-full max-w-9xl mx-auto sec-neutro-background">
                    <div className="pr-4 flex justify-end">
                      <button className="text-right btn-sm text-sm btn-banner-o" onClick={() => showSubscriptionModal()}>
                        Crear suscripción
                      </button>
                    </div>

                    {/* Content */}
                    <div className=" rounded-sm mb-8">
                      <div className="flex flex-col xxl:flex-row xxl:-mr-px p-4">
                        {/* Group 1 */}
                        <div>


                          <Modal show={displayModalSubscription}>
                            <ModalHeader className="sec-green-background">Crear nueva suscripción</ModalHeader>

                            <ModalBody>
                              {urlPayNewSubscription == undefined ?
                                <div>
                                  <div>
                                    <label>Selecciona el usuario:</label>
                                    <Select
                                      name={`usuariosa`}
                                      className='custom-width-md'
                                      options={usuariosEmpresa.map(user => (
                                        {
                                          value: {
                                            "id": user.id,
                                            "email": user.email,
                                            "name": user.name,
                                            "lastName": user.surname
                                          },
                                          label: user.email
                                        }))
                                      }
                                      placeholder={`Selecciona`}
                                      onChange={handleSelectChange}
                                      required>
                                    </Select>
                                  </div>
                                  <div className="flex flex-col">
                                    <label>Selecciona el plan:</label>
                                    <select defaultValue="" id="plans" name="plans" data-tip data-for="plansTip" className="'custom-width-md" type="text" onChange={e => setNewPlan(e.target.value)} required >
                                      <option name="Selecciona" disabled className='' value="">Selecciona un plan</option>
                                      {
                                        plans.data.map((v) => {
                                          return (
                                            <option key={v.id} name={v.name} className='' value={v.id}>{v.name}</option>
                                          );
                                        })
                                      }
                                    </select>

                                  </div>
                                  {newPlan == 3 &&
                                    <div>
                                      <label>ID precio de stripe</label>
                                      <input id="id_stripe_price" className="w-full bg-white" type="text" onChange={(e) => setStripePrice(e.target.value)} required />
                                    </div>
                                  }
                                </div>

                                :
                                <div>
                                  <p>Suscripción creada con éxito 👍</p>
                                  <div className="mt-4">
                                    <span>Enlace de pago de Stripe:</span>
                                    <div className="flex flex-row mt-2">
                                      <input className=" px-1 custom-width-md shadow" value={urlPayNewSubscription} />
                                      <button className="ml-2" onClick={() => copyContent()}>
                                        {clipboardIcon}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              }
                            </ModalBody>
                            <div className="flex justify-center space-x-4 m-2">
                              <button className="btn-sm btn-green" onClick={() => { setDisplayModalSubscription(false), setSelectedOption(), setUrlPayNewSubscription(), setNewPlan() }}>Cerrar</button>
                              {urlPayNewSubscription == undefined ? <button className="btn-sm btn-green" disabled={spinerSubscription || selectedOption == undefined || (newPlan == 3 ? stripePrice == "" : "") || newPlan == undefined} onClick={newPlan == 3 ? () => postCustomSubscription(selectedOption.value) : () => postNewSubscription(selectedOption.value)}>{spinerSubscription ? <Spinner variant="light" animation="border" /> : "Aceptar"}</button> : ""}
                            </div>
                          </Modal>

                          <FootprintSubscriptionList></FootprintSubscriptionList>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
export default FootprintCalculationAdmin;
