import { useState, useEffect } from "react";
import Sidebar from "../../Sidebar";
import Header from "../../Header";
import FootprintUserList from "./FootprintUserList";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import axios from "axios";
import Select from 'react-select';
import UserService from "../../../../services/UserService";
import { useNavigate } from "react-router-dom";
import { Spinner } from 'react-bootstrap';
import { event } from "react-ga";


function FootprintUsersAdmin() {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className=" sec-neutro-background flex h-screen overflow-hidden">

      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="sec-neutro-background relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Page header */}
            <div className="mb-2">
              <h1 className="text-2xl md:text-3xl text-gray-800 font-bold">CarbonManager - Usuarios</h1>
            </div>

            <div className="mb-8">
              <div className="flex flex-col xxl:flex-row xxl:-mr-px">
                <div>
                  <div className="pr-4 sm:pr-6 lg:pr-8 w-full max-w-9xl mx-auto sec-neutro-background">
                    {/* Content */}
                    <div className="mb-8">
                      <div className="flex flex-col xxl:flex-row xxl:-mr-px p-4">
                        {/* Group 1 */}
                        <div>
                          <FootprintUserList></FootprintUserList>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
export default FootprintUsersAdmin;
