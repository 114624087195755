import axios from "axios";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import UserService from "../../../../services/UserService";

function FootprintAlertsList({onEditAlert, updateList}) {

  const [subscriptions, setAlerts] = useState({
    data: { data: [] },
    isFetching: false,
  });


  useEffect(() => {
    const fetchAlerts = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_CH_ALERTS}/all`,
          {
            headers: {
              // "Access-Control-Allow-Origin": "*",
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${UserService.getToken()}`,
            },
          }
        );
        setAlerts({ data: response.data, isFetching: true });
      } catch (exception) {
        console.error(exception);
        setAlerts({ data: { data: [] }, isFetching: true });
      }
    };
    fetchAlerts();
  }, [updateList]);

  const useSortableData = (items, config = null) => {
    const [sortConfig, setSortConfig] = useState(config);
    const sortedItems = useMemo(() => {
      let sortableItems = [...items];

      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          let valueA = a[sortConfig.key];
          let valueB = b[sortConfig.key];

          if (sortConfig.key.includes(".")) {
            let params = sortConfig.key.split(".");
            valueA = a[params[0]]?.[params[1]];
            valueB = b[params[0]]?.[params[1]];
          }

          if (valueA === null || valueA === undefined || valueA === "") {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }

          if (valueB === null || valueB === undefined || valueB === "") {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }

          if (!isNaN(valueA) || moment(valueA).isValid()) {
            if (valueA < valueB) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (valueA > valueB) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          } else {
            if (
              valueA.toString().toLowerCase() < valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (
              valueA.toString().toLowerCase() > valueB.toString().toLowerCase()
            ) {
              return sortConfig.direction === "ascending" ? 1 : -1;
            }
          }

          return 0;
        });
      }
      return sortableItems;
    }, [items, sortConfig]);

    const requestSort = (key) => {
      let direction = "ascending";
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === "ascending"
      ) {
        direction = "descending";
      }
      setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
  };

  const { items, requestSort, sortConfig } = useSortableData(
    subscriptions.data?.data
  );

  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };

  const statusColor = (props) => {
    switch (props) {
      case "INFO":
        return "bg-green-100 text-green-600";
      case "ERROR":
        return "bg-red-100 text-red-600";
    }
  };

  const getIconYesNo = (value) => {
    if (value) {
        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="var(--airco2-green-color)" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="1.5">
            <path d="M5 12l5 5l10 -10"></path>
        </svg>
    } else {
        return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="var(--airco2-orange-color)" strokeLinecap="round" strokeLinejoin="round" width="24" height="24" strokeWidth="2">
            <path d="M18 6l-12 12"></path>
            <path d="M6 6l12 12"></path>
        </svg>
    }
}


  return (
    <>

      {!subscriptions.isFetching && (
        <div className="flex gap-1 justify-center">
            <span>Cargando alertas</span>
            <Spinner animation="border" variant="dark" />
        </div>
      )}

      {subscriptions.isFetching && subscriptions.data.data != undefined && (
        <>
          {subscriptions.data?.data.length == 0 ? <div className="flex justify-center">Cargando alertas&nbsp;<Spinner animation="border" variant="dark" /></div> :
            <div className="container-simple-table mt-2" style={{ height: "800px", overflowY: "scroll" }}>
             <table className="simple-table-sm">
               <thead className="text-center text-white sec-green-background sticky top-0 z-20">
                <tr>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort("id")}
                      className={`${getClassNamesFor("id")} `}
                    >
                      Id
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort("title")}
                      className={`${getClassNamesFor("title")} `}
                    >
                      Titulo
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort("type")}
                      className={`${getClassNamesFor(
                        "type"
                      )} `}
                    >
                      Tipo
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort("message")}
                      className={`${getClassNamesFor("message")} `}
                    >
                      Mensaje
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort("active")}
                      className={`${getClassNamesFor(
                        "active"
                      )} `}
                    >
                      Activa
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort("finishDate")}
                      className={`${getClassNamesFor("finishDate")} `}
                    >
                      Fecha Fin
                    </button>
                  </th>
                  <th>
                    <button
                      type="button"
                      onClick={() => requestSort("appliedUsers")}
                      className={`${getClassNamesFor(
                        "appliedUsers"
                      )} `}
                    >
                      Usuarios
                    </button>
                  </th>
                  <th />

                </tr>
              </thead>
              <tbody className="text-center">
                {items.map((alert, i) => {
                  return (
                    <>
                    <tr key={i}>
                      <td>{alert.id}</td>
                      <td>{alert.title}</td>
                      <td><span className={`text-center rounded-full py-0.5 px-2 ${statusColor(alert.type)}`}>{alert.type}</span></td>
                      <td>{alert.message}</td>
                      <td><div className="flex align-center justify-center">{getIconYesNo(alert.active)}</div></td>
                      <td>{alert.finishDate.split('T')[0] + 'T' + alert.finishDate.split('T')[1].substring(0, 5)}</td>
                      <td className="table-cell-long-string" title={alert.appliedUsers} >{alert.appliedUsers}</td>
                      <td className="p-2">
                        <button type="button" onClick={()=> onEditAlert(alert)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" width="24" height="24" stroke-width="2">
                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1"></path>
                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z"></path>
                                <path d="M16 5l3 3"></path>
                            </svg> 
                        </button>
                      </td>
                    </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>}
        </>
      )}
    </>
  );
}
export default FootprintAlertsList;
