import React, { useContext, useEffect, useState } from 'react';

import axios from 'axios';
import { Button, Modal, Spinner } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Input } from 'reactstrap';
import { IdiomContext } from "../../../context/createcontext";
import UserService from '../../../services/UserService';
import toast from 'react-hot-toast';


export default function OrganizacionesComponent(props) {
    const [lng, updateLng] = useContext(IdiomContext);
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("global");
    const [showDelete, setShowDelete] = useState(false);
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [selectedCardId, setSelectedCardId] = useState(null);
    const [showLogo, setshowLogo] = useState(false)
    const [uploadLogoValue, setuploadLogoValue] = useState("")
    const [uploadLogo, setuploadLogo] = useState(false)
    const [logo, setLogo] = useState(false)
    const clase = props.clase
    const numberOrg = props.numberOrg
    const childData = props.childData
    const childTotal = props.childTotal
    const childSector = props.childSector
    const itemsPerPage = numberOrg
    const dataFromPreviousData = props.previousData;


    const [idUser, setIdUser] = useState(undefined);

    useEffect(() => {
        if (props.idUserAdmin !== undefined && props.idUserAdmin !== null) {
            setIdUser(props.idUserAdmin);
        } else {
            setIdUser({ value: UserService.getUserId(), label: UserService.getUsername() })
        }

        if (id !== null && idUser !== undefined) {
            fetchOrganizations();
        }
    }, [props])

    const [pageIndex, setPageIndex] = useState(0);

    const start = pageIndex * itemsPerPage;
    const end = start + itemsPerPage;

    const handleNextPage = () => {
        if (end < allOrganizations.length) {
            setPageIndex(pageIndex + 1);
        }
    };

    const handlePrevPage = () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1);
        }
    };

    const [typeMessageLogo, setTypeMessageLogo] = useState();

    const [organizations, setOrganizations] = useState({
        data: [],
        isFetching: true
    });





    const fetchOrganizations = async () => {
        try {

            const response = await axios.get(`${process.env.REACT_APP_CH_ORGANIZATIONS}?userId=${idUser.value}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });
            setOrganizations({ data: response.data, isFetching: false });

        } catch (exception) {
            setOrganizations({ data: [], isFetching: false });
            console.error(exception.message)
        }

    };



    const allOrganizations = organizations?.data?.data


    useEffect(() => {

        if (props.lastOrgCreated != undefined && allOrganizations != undefined && pageIndex == 0) {
            let lastOrg = JSON.parse(props.lastOrgCreated);
            handleCardClick({ id: lastOrg.id, organizationCNAE: { id: lastOrg.cnaeId } });
            setPageIndex(totalPage - 1);
        }
    }, [props.lastOrgCreated, totalPage, allOrganizations?.length]);


    const handleshowDelete = (x) => {
        setShowDelete(true);
        setId(x.id);
        setName(x.name)
    }

    const handleClose = () => {
        setShowDelete(false);
        setId('');
        setName('');
    }

    const handleShowLogo = (x) => {
        setId(x.id);
        setshowLogo(true)
        setLogo(false)
    }
    const handleCloseLogo = (x) => {
        setId('');
        setshowLogo(false)
        setuploadLogoValue(false)
        setTypeMessageLogo()
    }

    const handleDelete = async (id) => {
        const loadingToast = toast.loading(t("footPrint.deletingOrg"), {duration: 8000});

        try {
            const response = await axios.delete(`${process.env.REACT_APP_CH_ORGANIZATION}/${id}`, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    // 'Access-Control-Allow-Origin': '*',
                    'Authorization': `Bearer ${UserService.getToken()}`
                }
            });

            setOrganizations(prevState => {
                const newData = prevState.data.data.filter(org => org.id !== id);
                return { ...prevState, data: { ...prevState.data, data: newData } };
            });

            setShowDelete(false);
            toast.success(t("footPrint.delete-ok"), { id: loadingToast });
        } catch (error) {
            console.error('Error al eliminar:', error);
            toast.error(t("footPrint.delete-ko"), { id: loadingToast });

        }
    };

    const visibleOrganizations = allOrganizations?.slice(start, end);

    const handleCardClick = (card) => {
        setSelectedCardId(card.id);
        handlerUpdate(card.id, card?.organizationCNAE?.id)
    };

    const handlerUpdate = (id, cnae) => {
        childData(id)
        childSector(cnae)


    }


    const saveOnLocalStorage = () => {
        if (
            dataFromPreviousData != undefined &&
            (dataFromPreviousData.año != "" ||
                dataFromPreviousData.superficie != "" ||
                dataFromPreviousData.facturacion != "" ||
                dataFromPreviousData.leanGrean != "" ||
                dataFromPreviousData.empleados != "")
        ) {
            let now = new Date();
            let expirationTime = now.getTime() + 1 * 60 * 60 * 1000;
            let item = {
                value: dataFromPreviousData,
                expiry: expirationTime,
            };
            let dataString = JSON.stringify(item);

            localStorage.setItem("previousData", dataString);
        }
    };

    childTotal(allOrganizations?.length)
    const handleFileChange = (e) => {
        setTypeMessageLogo();
        const fileUpload = e.target.files[0];
        const fileName = fileUpload.name;
        let fileOk = true;
        let errorMsg = "";
        if (fileUpload) {

            if (/\s/.test(fileName) || /[^\w.-]/.test(fileName)) {
                fileOk = false;
                errorMsg = t("marketplace.errImgNombre")
            }

            const extension = fileName.split(".").pop();
            if (extension !== extension.toLowerCase()) {
                fileOk = false;
                errorMsg = t("marketplace.errImgExtensionMayusculas");
            }

            const fileExtension = fileName.split('.').pop().toLowerCase();
            const allowedExtensions = ['jpg', 'jpeg', 'png'];
            if (!allowedExtensions.includes(fileExtension)) {
                fileOk = false;
                errorMsg = t("footPrint.logo-extension");
            }

            if (fileOk) {
                setuploadLogoValue(e.target.files)
            } else {
                const errorToast = toast.error(errorMsg, {duration: 8000});
                setTypeMessageLogo("warning")
                console.error('Error: ' + errorMsg);
                e.target.value = null;
                setuploadLogoValue([])
            }

        }

    };
    const handleuploadLogo = async (e) => {
        setuploadLogo(true)
        const archivos = uploadLogoValue;
        const formData = new FormData();
        formData.append('files', archivos[0]);
        const url = `${process.env.REACT_APP_CH_ORGANIZATION}/${id}/img`
        const loadingToast = toast.loading(t("footPrint.uploadingLogo"), {duration: 8000});

        try {

            const response = await axios.post(`${url}`, formData, {
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${UserService.getToken()}`
                }
            }).then(response => {
                if (response.status === 200) {
                    toast.success(t("footPrint.logoUpload"), { id: loadingToast });
                    setTypeMessageLogo("success");
                    setLogo(true)
                    fetchOrganizations()

                } else {
                    console.error(error)
                    toast.error(t("footPrint.logo-ko"), { id: loadingToast });
                    setTypeMessageLogo("error");
                }


            })
            setuploadLogo(false);
        } catch (error) {
            console.error('Error al realizar la petición:', error);
            setuploadLogo(false);
            toast.error(t("footPrint.logo-ko"), { id: loadingToast });
        }

    };


    const renderTooltipLogo = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            {t("footPrint.addLogo")}
        </Tooltip>
    );


    const totalPage = allOrganizations?.length / numberOrg < 1 ? 1 : Math.ceil(allOrganizations?.length / numberOrg)
    return (

        <div>

            {(numberOrg != 'all') &&
                <div className='flex justify-center items-center mb-3'>
                    <span className='cursor-pointer' onClick={handlePrevPage}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-chevron-left" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M13 15l-3 -3l3 -3" />
                            <path d="M21 12a9 9 0 1 0 -18 0a9 9 0 0 0 18 0z" />
                        </svg>

                    </span>
                    {(organizations.isFetching === false) &&
                        <span className='mx-3 font-semibold'>{pageIndex + 1} / {totalPage}</span>
                    }


                    <span className='cursor-pointer' onClick={handleNextPage}>

                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-chevron-right" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M11 9l3 3l-3 3" />
                            <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0z" />
                        </svg>
                    </span>

                </div>



            }
            {(organizations.isFetching === false && allOrganizations?.length === 0 && numberOrg != 'all') &&


                <div className={` p-4 justify-center items-center gap-4`}>
                    <p>
                        {t("footPrint.noOrganizaciones")}
                    </p>
                </div>



            }
            <div className={clase}>


                <Modal
                    show={showDelete}
                    style={{
                        width: '70vw',
                        top: '30vh',
                        left: '15vw'
                    }}
                    className="flex text-center"
                >

                    <div className="flex m-2 justify-end">
                        <button onClick={() => handleClose()}>
                            <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                        </button>
                    </div>

                    <Modal.Body className=""  >
                        {t("footPrint.borrarOrg")} <span className='italic font-bold'>{name}</span>  ?
                    </Modal.Body>

                    <div className="flex mb-3 mt-3 justify-center flex-wrap gap-3">
                        <button className=" btn sec-green-background text-white ml-3" variant="secondary" onClick={() => handleDelete(id)}>
                            {t("footPrint.eliminar")}
                        </button>
                        <Button className=" btn sec-green-background text-white ml-3" variant="secondary" onClick={() => handleClose()}>
                            {t("footPrint.cerrar")}
                        </Button>
                    </div>

                </Modal>

                {/* modal para el logo  */}
                <Modal show={showLogo} animation={false} >
                    <Modal.Header className="sec-green-background">
                        <Modal.Title className="text-white">{t("footPrint.logo")}</Modal.Title>
                        <div className="flex justify-end">

                            <button onClick={() => handleCloseLogo()}>
                                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" className="css-tj5bde-Svg"><path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path></svg>
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body className='flex flex-wrap flex-col'>

                        <div className="mt-2 flex gap-2">
                            <Input className='mt-1 px-2 text-sm border' accept="image/png, image/jpeg,image/jpg" type="file" id="subir_archivo" onChange={(e) => handleFileChange(e)}  ></Input>
                            <button onClick={(e) => handleuploadLogo(e)}  >
                                {uploadLogo === false ?
                                    (uploadLogoValue == "" || uploadLogoValue == undefined || typeMessageLogo == 'warning' || typeMessageLogo == 'success' ? "" :
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-cloud-uploadLogo" width="26" height="26" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#00bfd8" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1" />
                                            <polyline points="9 15 12 12 15 15" />
                                            <line x1="12" y1="12" x2="12" y2="21" />
                                        </svg>

                                    )
                                    :
                                    <Spinner animation="border" variant="dark" />
                                }
                            </button>
                        </div>
                        <p className='mt-1 text-xs italic pl-1'>JPG,JPEG,PNG</p>

                    </Modal.Body>

                    <Modal.Footer>
                        <div className='flex w-full justify-end items-center'>

                            <button onClick={() => handleCloseLogo()} className='sec-green-background text-white px-3 py-1 ' style={{ borderRadius: '0.25rem' }}>
                                {t("footPrint.cerrarCerrar")}
                            </button>
                        </div>
                    </Modal.Footer>

                </Modal>


                {(organizations.isFetching) &&
                    <div className={`box-organizations p-4 flex flex-col justify-center items-center gap-4`}>
                        <Spinner animation='border'> </Spinner>
                        {t("footPrint.cargando")}
                    </div>
                }

                {(allOrganizations?.length > 0) &&

                    (numberOrg === 'all' ? allOrganizations : visibleOrganizations).map((x, y) =>
                        <div
                            onClick={() => numberOrg !== 'all' ? handleCardClick(x) : ""}
                            style={{
                                border: selectedCardId === x.id ? '4px solid var(--airco2-green-color)' : 'none',

                            }}

                            key={x.id} className={`box-organizations p-3 relative`}  >

                            <div>
                                <img src={x.urlLogo} style={{
                                    position: 'absolute',
                                    opacity: '0.05',
                                    width: '75%',
                                    height: 'auto',

                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'

                                }}></img>


                                <div className='name-cif mt-2 mb-2' style={{ minHeight: '80px' }}>
                                    <p className='block text-lg font-bold text-airco2-principal '>{x.name}  </p>
                                    <p className='block mt-1 text-xs font-medium text-gray-800'>{x.cif}  </p>
                                </div>
                                <div className='type-cnae mt-2 mb-2' style={{ minHeight: '80px' }}>
                                    <p className='block mt-1 text-xs font-medium text-gray-800'>{x.organizationType?.name}  </p>
                                    <p className='block mt-1 text-xs font-medium text-gray-800'>{x.organizationCNAE?.name}  </p>
                                </div>
                                <div className='sede&Actions flex mt-2 items-center justify-between'>
                                    <span className='block text-sm font-medium text-gray-800'><span>{x.organizationBuildings?.length}</span> {t("footPrint.sedes")}  </span>
                                    <span className='actions flex gap-3'>

                                        <Link to={`/footprint/organization/${x.id}`} >
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-pencil  transform hover:scale-105" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#878787" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                                                <path d="M13.5 6.5l4 4" />
                                            </svg>
                                        </Link>

                                        <button onClick={() => handleshowDelete(x)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash hover:scale-105" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#878787" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M4 7l16 0" />
                                                <path d="M10 11l0 6" />
                                                <path d="M14 11l0 6" />
                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                            </svg>
                                        </button>


                                        {x.urlLogo ?
                                            ''
                                            :
                                            <button onClick={() => handleShowLogo(x)}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    delay={{ show: 250, hide: 400 }}
                                                    overlay={renderTooltipLogo}
                                                    className="flex flex-row"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-photo-off" width="25" height="25" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M15 8h.01" />
                                                        <path d="M7 3h11a3 3 0 0 1 3 3v11m-.856 3.099a2.991 2.991 0 0 1 -2.144 .901h-12a3 3 0 0 1 -3 -3v-12c0 -.845 .349 -1.608 .91 -2.153" />
                                                        <path d="M3 16l5 -5c.928 -.893 2.072 -.893 3 0l5 5" />
                                                        <path d="M16.33 12.338c.574 -.054 1.155 .166 1.67 .662l3 3" />
                                                        <path d="M3 3l18 18" />
                                                    </svg>
                                                </OverlayTrigger>
                                            </button>
                                        }


                                    </span>
                                </div>


                            </div>

                        </div>
                    )

                }


                <div className={`box-organizations p-4 flex flex-col justify-center items-center gap-4 bac hover:bg-gray-200 cursor-pointer	`}
                    onClick={() => {
                        saveOnLocalStorage();
                        navigate(`/footprint/organization${dataFromPreviousData != undefined ? "?from=previousdata" : ""}`, {
                            state: { idUser: idUser }
                        });
                    }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-cicle-plus hover:scale-105" width="65" height="65" viewBox="0 0 24 24" strokeWidth="1" stroke="#334155" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                        <path d="M9 12l6 0" />
                        <path d="M12 9l0 6" />
                    </svg>
                    <p className='font-xl font-bold text-center text-gray-800'>{t("footPrint.crearOrganizacion")}</p>
                </div>

            </div>

        </div>


    )
}